// Angular Files
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnDestroy, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Angular Material Files
import { MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

// Other External Files
import { Subscription } from 'rxjs';

// Teller Online Files
import { ItemPaymentOptionDto } from 'apps/public-portal/src/app/core/api/PublicPortalApiClients';
import { SearchResult } from 'apps/public-portal/src/app/core/services';
import { SelectPaymentComponent } from 'apps/public-portal/src/app/shared/components/select-payment/select-payment.component';
import { SelectPaymentModule } from 'apps/public-portal/src/app/shared/components/select-payment/select-payment.module';

// Teller Online Library files
import { TellerOnlineIconsModule } from 'teller-online-libraries/icons';
import { TellerOnlineAppService } from 'teller-online-libraries/core';

@Component({
    selector: 'app-search-result',
    templateUrl: './search-result.component.html',
    styleUrls: ['./search-result.component.scss'],
    host: {
        class: 'search-result'
    }
})
export class SearchResultComponent implements OnInit, OnDestroy {

    @Input() item: SearchResult;
    @Input() conflictingOptions = null;

    @Output() addToCart = new EventEmitter<any>();
    @Output() updateCart = new EventEmitter<any>();
    @Output() removeFromCart = new EventEmitter<any>();
    @Output() loadMore = new EventEmitter<any>();

    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger; // For programmatic control of mat menu for SelectPaymentOptionComponent
    public selectPaymentMenuOpen: boolean = false;
    public itemIsPayable: boolean;
    private _selectPaymentSheet: MatBottomSheetRef<SelectPaymentComponent> | null = null;

    // Subscriptions
    private _breakpointSubscription: Subscription;

    constructor(
        private bottomSheet: MatBottomSheet,
        public appService: TellerOnlineAppService,
    ) { }

    ngOnInit(): void {
        // If the item has any negative payment options, it is not payable.
        // Note: If the item has no payment options it is not payable.
        this.itemIsPayable = this.item.paymentOptions?.every(p => p.amount >= 0 || p.maxAmount >= 0) ?? false;

        this._breakpointSubscription = this.appService.breakpoints$.subscribe(() => {
            if (this._isSelectPaymentOpen(!this.appService.isNarrow)) {
                //open the new panel
                this.openSelectPayment();
                // then close the old panel
                this._closeSelectPayment(!this.appService.isNarrow);
            }
        });
    }

    ngOnDestroy() {
        if(this._breakpointSubscription) this._breakpointSubscription.unsubscribe();
    }

    on_addToCart(e) {
        this.addToCart.emit(e);
    }

    on_updateCartItem(e) {
        this.updateCart.emit(e);
    }

    onClick_removeFromCart(e) {
        this.removeFromCart.emit(e);
    }

    public checkPaymentOptionNeedsPrompt(paymentOptions: ItemPaymentOptionDto[]): boolean {
        let status = true;

        if (paymentOptions?.length === 1) {
            status = paymentOptions[0].isCustomAmount;
        }

        return status;
    }

    onClick_loadMore() {
        this.loadMore.emit();
    }

    public get itemHasSinglePaymentOptionSelected() {
        return this.item.amountInCart && ((this.item.selectedPaymentOption && !this.item.selectedPaymentOptions) || this.item.selectedPaymentOptions?.length == 1)
    }

    /** Close either the mat-menu OR bottom sheet (depending on current width) containing SelectPaymentComponent */
    private _closeSelectPayment(narrow: boolean | null = null) {
        if ((this.appService.isNarrow && narrow === null) || narrow) {
            this.bottomSheet.dismiss();
            this._selectPaymentSheet = null;
        } else if((this.appService.isWide && narrow === null) || !narrow){
            this.trigger.closeMenu();
        }
    }

    /** Open either the mat-menu OR bottom sheet (depending on current width) containing SelectPaymentComponent
     * and await a response from the bottom sheet when on a narrow device.
     */
    public async openSelectPayment() {
        if (this.appService.isNarrow) {
            this._selectPaymentSheet = this.bottomSheet.open(SelectPaymentComponent, {
                panelClass: 'menu-sheet',
                data: this.item
            });

            /** Wait for the dismissal of the bottom sheet. This is necessary because we can't bind
             * the outupts of the SelectPaymentComponent as we would normally, but it's set up to pass
             * the relevant data through the afterDismissed event.
             */
            let data = await this._selectPaymentSheet.afterDismissed().toPromise()

            // Determine which type of event it was, if any at all.
            if (data?.paymentOption != null) {
                if (data.action === 'add')
                    this.on_addToCart([data.item, data.paymentOption, data.customAmount]);
                else if (data.action === 'update')
                    this.on_updateCartItem([data.item, data.paymentOption, data.customAmount]);
            }
        } else {
            this.trigger.openMenu();
            this.trigger.menuClosed.subscribe(() => {
                this.selectPaymentMenuOpen = false;
            });
            this.selectPaymentMenuOpen = true;
        }
    }

    /** Return the current state of the SelectPaymentComponent */
    private _isSelectPaymentOpen(narrow: boolean | null = null) {
        let open = false;
        if ((this.appService.isNarrow && narrow === null) || narrow) {
            open = this._selectPaymentSheet != null;
        } else if((this.appService.isWide && narrow === null) || !narrow) {
            open = this.trigger?.menuOpen;
        }
        return open;
    }
}

@NgModule({
    declarations: [ SearchResultComponent ],
    imports: [
        CommonModule,
        SelectPaymentModule,
        MatListModule,
        MatMenuModule,
        MatIconModule,
        MatToolbarModule,
        MatTooltipModule,
        MatButtonModule,
        TellerOnlineIconsModule
    ],
    exports: [ SearchResultComponent ]

})

export class SearchResultModule { }
