// Angular Files
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

// Angular Material Files

// Other External Files

// Component Files

// Teller Online Files

// Teller Online Library Files
import { 
    PaymentMethodModel, 
    TellerOnlineAppService, 
    TellerOnlinePaymentMethodsService 
} from "teller-online-libraries/core";

@Component({
    selector: 'app-payment-method',
    templateUrl: './payment-method.component.html',
    styleUrls: ['./payment-method.component.scss'],
    host: {
        class: 'payment-method-view'
    }
})
export class TellerOnlinePaymentMethodComponent {
    // Public variables
    @Input() public readOnly: boolean = false;
    @Input() public paymentMethod: PaymentMethodModel;
    @Input() public isDefault: boolean;
    @Input() public emailIsVerified: boolean;
    @Output() remove = new EventEmitter<any>();
    @Output() default = new EventEmitter<any>();


    // Private variables

    // Subscriptions

    constructor(
        public appService: TellerOnlineAppService,
        public paymentMethodsService: TellerOnlinePaymentMethodsService
    ) { }

    public onClick_removeMethod(e, paymentMethod) {
        this.remove.emit({event: e, paymentMethod: paymentMethod});
    }

    public onChange_setDefault(paymentMethod) {
        this.default.emit(paymentMethod);
    }
}
