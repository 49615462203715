<h2 mat-dialog-title id="aria_dialog_title" cdkFocusInitial tabindex="-1">{{data.title}}</h2>
<form #dialogForm="ngForm">
    <mat-dialog-content>
        <div [innerHtml]="data.content"></div>
        <ng-container *ngFor="let field of data?.form?.fields">
            <mat-form-field floatLabel="always" *ngIf="field.type ==='textarea'">
                <mat-label> {{ field.label }} </mat-label>
                <textarea matInput
                    [(ngModel)]="formData[field.name]" 
                    #fieldModel="ngModel"  
                    [name]="field.label" 
                    [placeholder]="field.placeholder" 
                    trim-whitespace
                    [required]="field.required"></textarea>
                <mat-error>{{ validationService.getFieldErrorMessage(field.label, fieldModel) }}</mat-error>
            </mat-form-field>
        </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="data.actions?.length > 0">
        <ng-template ngFor let-action [ngForOf]="data.actions">
            <button *ngIf="action.solidBackground" mat-flat-button [color]="action.color" (click)="action.click ? action.click : onClick_actionClose(action.close, dialogForm)">{{action.text}}</button>
            <button *ngIf="!action.solidBackground" mat-button [color]="action.color" (click)="action.click ? action.click : onClick_actionClose(action.close, dialogForm)">{{action.text}}</button>
        </ng-template>
    </mat-dialog-actions>
</form>