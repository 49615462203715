// Angular Files
import { Injectable } from '@angular/core';

// Other External Files
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TellerOnlineNotificationBannerService {
    // Public Variables
    public banners: TellerOnlineNotificationBanner[] = [];

    // Private Variables
    private _enabled: boolean = false;

    private _resolveClicked = new BehaviorSubject<boolean>(false);
    public resolveClicked$ = this._resolveClicked.asObservable();


    public get enabled() {
        return this._enabled;
    }

    constructor() {
    }

    public addBanner(banner: TellerOnlineNotificationBanner) {
        // add the banner but only if it's not already in the list
        if(!this.banners.find(b => b.tag == banner.tag)) this.banners.push(banner);

        this._enabled = true;
    }

    public updateBanner(tag: string, options: any) {
        if(typeof options == "object") {
            let banner = this.banners.find(b => b.tag == tag);
            
            if(banner) {
                for (var property in options) {
                    if (banner.hasOwnProperty(property)){
                        if(property) {
                            for (var subprop in options[property]) {
                                (<any>banner)[property][subprop] = (<any>options)[property][subprop];
                            }
                        } else {
                            (<any>banner)[property] = (<any>options)[property];
                        }
                    }
                }
            }
        }
    }

    public dismissBanner(tag: string) {
        this._destroyBanner(tag);
    }

    public async dismissAuthenticationBanners() {
        let bannerTags = this.banners.filter(b => b.requiresAuthentication).map(b => b.tag);
        bannerTags.forEach(t => this.dismissBanner(t));
    }

    // A method that can be used to indicate the resolve button was clicked in order to respond to it from within a specific page
    public clickResolve = () => {
        this._resolveClicked.next(true);
    }

    private _destroyBanner(tag: string) {
        let index = this.banners.findIndex(b => b.tag == tag);

        if(index != -1) {
            // fade out the banner
            this.banners[index].bannerClass += " notification-banner--dismissed";

            // wait 1.3s (must be larger than the transition time in the scss file) 
            // before removing the banner from the array in order to display the next one
            setTimeout(() => {
                this.banners.splice(index, 1);
                if(this.banners.length == 0) this._enabled = false;
            }, 1300);
            
        }
    }
}

export class TellerOnlineNotificationBannerAction {
    text: string;
    click?: Function;
    href?: string;
    hrefState?: any;
}

export class TellerOnlineNotificationBanner {
    message: string; 
    tag: string;
    resolveAction: TellerOnlineNotificationBannerAction;
    dismissAction?: TellerOnlineNotificationBannerAction;
    bannerClass?: string;
    svgIcon?: string;
    svgIconClass?: string; // use to apply specific colors for example
    icon?: string;
    iconColor?: string;
    requiresAuthentication?: boolean = false;
}