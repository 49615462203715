// Angular Files
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { Component, NgModule, Input, Optional, Self, Inject, ElementRef, OnDestroy } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';

// Angular Material Files
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// Other External Files
import { Subject } from 'rxjs';

@Component({
    selector: 'app-payment-integration-form-field',
    templateUrl: './payment-integration-form-field.component.html',
    styleUrls: ['./payment-integration-form-field.component.scss'],
    host: {
        class: 'payment-integration-form-field'
    },
    providers: [{provide: MatFormFieldControl, useExisting: PaymentIntegrationFormFieldComponent}]
})
export class PaymentIntegrationFormFieldComponent implements MatFormFieldControl<PaymentIntegrationFieldValue>, ControlValueAccessor, OnDestroy {
    @Input('id') id: string;
    @Input('loaded') loaded: boolean = false;
    @Input('field-min-size') fieldMinSize: string;
    @Input('iframe') iframe: boolean = false;

    @Input('aria-describedby') userAriaDescribedBy: string;

    @Input()
    get placeholder(): string {
        return this._placeholder;
    }
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next();
    }
    private _placeholder: string;

    @Input()
    get required(): boolean {
        return this._required;
    }
    set required(value: BooleanInput) {
        this._required = coerceBooleanProperty(value);
        this.stateChanges.next();
    }
    private _required = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }
    private _disabled = false;
        
    @Input()
    get value(): PaymentIntegrationFieldValue | null {
        return new PaymentIntegrationFieldValue(this._value, this._errorMessage, this._name, this._label);
    }
    set value(fieldValue: PaymentIntegrationFieldValue | null) {
        const paymentIntegrationFieldValue = fieldValue || new PaymentIntegrationFieldValue(null, '');
        this._value = paymentIntegrationFieldValue.value;
        this._errorMessage = paymentIntegrationFieldValue.error;
        if(paymentIntegrationFieldValue.name) this._name = paymentIntegrationFieldValue.name;
        if(paymentIntegrationFieldValue.label) this._label = paymentIntegrationFieldValue.label;
        this.stateChanges.next();
    }
    private _value: string;

    get name() {
        return this._name;
    }
    set name(value: string) {
        this._name = value;
    }
    private _name: string;

    get label() {
        return this._label;
    }
    set label(value: string) {
        this._label = value;
    }
    private _label: string;
    
    get errorState(): boolean {
        return !!this._errorMessage;
    }
    private _errorMessage: string;
    
    get empty() {
        return !this.value;
    }
    
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }
    
    public stateChanges = new Subject<void>();    
    public focused = false;
    public touched = false;
    public onChange = (_: any) => {};
    public onTouched = () => {};
    
    constructor(
        private _elementRef: ElementRef<HTMLElement>,
        @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
        @Optional() @Self() public ngControl: NgControl) { 
        
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnDestroy() {
        this.stateChanges.complete();
    }

    writeValue(value: PaymentIntegrationFieldValue | null): void {
        this.value = value;
    }
    
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    setDescribedByIds(ids: string[]) {
    }
    
    onContainerClick() {
    }
    _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
        this.onChange(this.value);
    }
}
@NgModule({
    imports: [
        CommonModule,
        MatProgressSpinnerModule
    ],
    declarations: [ PaymentIntegrationFormFieldComponent ],
    exports: [ PaymentIntegrationFormFieldComponent ]
})
export class PaymentIntegrationFormFieldModule { }


export class PaymentIntegrationFieldValue {
    constructor(public value: string, public error: string, public name?: string, public label?: string) {}
}
