// Other External Files
import { BehaviorSubject, Observable } from "rxjs";

// Payment Integration Files
import { PaymentMethodTypeEnum, } from "apps/public-portal/src/app/payment-integrations/base/models";

// Teller Online Files
import { PaymentConfigDto } from "apps/public-portal/src/app/core/api/PaymentIntegrationApiClients";
import { ConvenienceFeeConfigModel } from "apps/public-portal/src/app/payment-integrations";

export class PaymentProcessorConfig {
    public convenienceFee: ConvenienceFeeConfigModel = new ConvenienceFeeConfigModel();

    /** Whether or not to display credit as a valid payment option */
    public isCreditEnabled: boolean = true;

    /** Whether or not to display e-check as a valid payment option */
    public isECheckEnabled: boolean = true;

    /** Convenience fee override used when paying by credit card */
    public creditConvenienceFee: ConvenienceFeeConfigModel = null;

    /** Convenience fee overrides used when paying by echeck */
    public eCheckConvenienceFee: ConvenienceFeeConfigModel = null;

    /** Indicative of which convenience fee type is currently being displayed to the user */
    public usingConvenienceFeeType: PaymentMethodTypeEnum;

    /** If it exists in the configuration need to be displayed and acknowledged before making echeck payments  */
    public eCheckConfirmationMessage: string;

    /** If it exists in the configuration need to be displayed and acknowledged before saving echeck payments  */
    public eCheckDisclaimer: string;

    /** Contains any credit processor specific configuration. */
    public creditAdditionalData: { [key: string]: any }

    /** Contains any E-Check processor specific configuration */
    public eCheckAdditionalData: { [key: string]: any }

    /** Contains any credit processor specific test triggers. */
    public creditTestTriggerData: { [key: string]: string }

    /** Contains any E-Check processor specific test triggers */
    public eCheckTestTriggerData: { [key: string]: string }

    /** Default convenience fee to use if either credit or echeck is missing */
    private _defaultConvenienceFee: ConvenienceFeeConfigModel = null;

    /** If the configuration was loaded correctly with valid configuration. */
    private _configLoaded: boolean = false;

    protected _convenienceFeeChanged: BehaviorSubject<ConvenienceFeeConfigModel> = new BehaviorSubject(null);
    public convenienceFeeChanged$: Observable<ConvenienceFeeConfigModel> = this._convenienceFeeChanged.asObservable();

    public get convenienceFeeType() {
        return this.usingConvenienceFeeType;
    }

    /** Switch the convenience fee based on the current payment method selected
     * If there is no config to switch to, will use the default config
     */
    public useConvenienceFee(paymentMethodType: PaymentMethodTypeEnum) {
        this.usingConvenienceFeeType = paymentMethodType;

        if (this._configLoaded) {
            // Set the fees to defaults to start, to be overridden later
            this.convenienceFee.enabled = this._defaultConvenienceFee.enabled;
            this.convenienceFee.format = this._defaultConvenienceFee.format;
            this.convenienceFee.rate = this._defaultConvenienceFee.rate;
            this.convenienceFee.note = this._defaultConvenienceFee.note;
            this.convenienceFee.label = this._defaultConvenienceFee.label;

            switch (paymentMethodType) {
                case PaymentMethodTypeEnum.CreditCard:
                    if (this.creditConvenienceFee) {
                        this.convenienceFee.enabled = this.creditConvenienceFee.enabled;
                        // override the note as long as it's not undefined
                        //  it still show if enabled = false so need a way to explicitly say "no note" which can be setting it to null
                        if (typeof (this.creditConvenienceFee.note) != undefined) this.convenienceFee.note = this.creditConvenienceFee.note;
                        // only override these values if they are truthy
                        if (this.creditConvenienceFee.format) this.convenienceFee.format = this.creditConvenienceFee.format;
                        if (this.creditConvenienceFee.rate) this.convenienceFee.rate = this.creditConvenienceFee.rate;
                        if (this.creditConvenienceFee.label) this.convenienceFee.label = this.creditConvenienceFee.label;
                    }
                    break;
                case PaymentMethodTypeEnum.ECheck:
                    if (this.eCheckConvenienceFee) {
                        this.convenienceFee.enabled = this.eCheckConvenienceFee.enabled;
                        // override the note as long as it's not undefined
                        //  it still show if enabled = false so need a way to explicitly say "no note" which can be setting it to null
                        if (typeof (this.eCheckConvenienceFee.note) != undefined) this.convenienceFee.note = this.eCheckConvenienceFee.note;
                        // only override these values if they are truthy
                        if (this.eCheckConvenienceFee.format) this.convenienceFee.format = this.eCheckConvenienceFee.format;
                        if (this.eCheckConvenienceFee.rate) this.convenienceFee.rate = this.eCheckConvenienceFee.rate;
                        if (this.eCheckConvenienceFee.label) this.convenienceFee.label = this.eCheckConvenienceFee.label;
                    }
                    break;
            }

            this._convenienceFeeChanged.next(this.convenienceFee);
        }
    }

    //#region helpers

    /** Map the default config variables retrieved from the backend getConfig call */
    public setupConfig(dto: PaymentConfigDto) {

        // grab the default convenience config values
        this._defaultConvenienceFee = new ConvenienceFeeConfigModel();
        this._defaultConvenienceFee.fromDto(dto);

        // grab the credit config values
        if (dto.creditConfig) {
            this.isCreditEnabled = dto.creditConfig.enabled;
            this.creditConvenienceFee = new ConvenienceFeeConfigModel();
            this.creditConvenienceFee.fromDto(dto.creditConfig);
            this.creditAdditionalData = dto.creditConfig.additionalData;
            this.creditTestTriggerData = dto.creditConfig.testTriggers;
        }

        // grab the e-check config values
        if (dto.eCheckConfig) {
            this.isECheckEnabled = dto.eCheckConfig.enabled;
            this.eCheckConvenienceFee = new ConvenienceFeeConfigModel();
            this.eCheckConvenienceFee.fromDto(dto.eCheckConfig);
            this.eCheckConfirmationMessage = dto.eCheckConfig.eCheckConfirmation;
            this.eCheckDisclaimer = dto.eCheckConfig.eCheckDisclaimer;
            this.eCheckAdditionalData = dto.eCheckConfig.additionalData;
            this.eCheckTestTriggerData = dto.eCheckConfig.testTriggers;
        }

        this.useConvenienceFee(this.usingConvenienceFeeType);
        this._configLoaded = true;
    }

    //#endregion
}

