// Angular Files
import { CommonModule } from '@angular/common';
import { Component, NgModule, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

// Angular Material Files
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule, MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

// Teller Online Library Files
import { TellerOnlineIconsModule } from "teller-online-libraries/icons";

@Component({
    selector: 'teller-online-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    host: {
        class: 'notification'
    }
})
export class TellerOnlineNotificationComponent {
    // Declare @Input variables

    // Declare @Output variables

    // Public variables

    // Private variables

    // Subscriptions

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        public snackBarRef: MatSnackBarRef<TellerOnlineNotificationComponent>
    ) {}
}
@NgModule({
    imports: [
        CommonModule,
        TellerOnlineIconsModule,
        MatIconModule,
        MatButtonModule,
        MatSnackBarModule
    ],
    declarations: [ TellerOnlineNotificationComponent ],
    exports: [ TellerOnlineNotificationComponent ]
})
export class TellerOnlineNotificationModule { }
