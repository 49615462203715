// Angular Files
import { Injectable, ErrorHandler, NgZone } from '@angular/core';
import { Router } from '@angular/router';

// Services
import { TellerOnlineAppService } from './app.service';

// Teller Online Shared Files
import { TellerOnlineDialogAction, TellerOnlineMessageService } from 'teller-online-libraries/shared';

// Teller Online Core Files
import { IErrorDto, ErrorLogApiClient } from '../api/CoreWebApi';

@Injectable({
    providedIn: 'root'
})
export class TellerOnlineErrorHandlerService implements ErrorHandler {
    private _environment;

    constructor(
        private router: Router,
        private errorLogApiClient: ErrorLogApiClient,
        private messageService: TellerOnlineMessageService,
        private appService: TellerOnlineAppService,
        private ngZone: NgZone
    ) {}

    init(environment, baseUrl: string) {
        this._environment = environment;
        ErrorLogApiClient.init(baseUrl);
    }

    handleError(error, showAlert: boolean = true) {
        // Turn off any loaders because we got an error
        this.appService.finishLoading();

        var errorDto: IErrorDto;
        var showErrorDialog: boolean = this.appService.showErrorDialogs;
        if (error.errorDef) {
            errorDto = error as IErrorDto;
        } else if (error.rejection && error.rejection.errorDef) {
            errorDto = error.rejection as IErrorDto;
        //If the backend returned a 401 error it's because the user isn't authorized to be here so redirect them to the sign-in page
        } else if (error.rejection && error.rejection.status === 401) {
            // Using ngZone in order to over come "navigation triggered outside angular zone" issue
            // on navigating.
            this.ngZone.run(() => this.router.navigate(['/sign-in']));
            showErrorDialog = false;
        }

        var message: string;
        if (errorDto) {
            // This is an error from our server error handler.
            var messageFromServer = errorDto.errorMessage.replace(/\n/g, '<br/>');
            message = `${messageFromServer}<br/><br/>(Error Number ${errorDto.errorNumber})`;
            if (errorDto.errorDetails) {
                message += `</br></br>Details: ${errorDto.errorDetails}`;
            }
        } else {
            // This is an unknown error.
            // Generate a random number to correlate the error log with what the user sees.
            // Use prefix "C" for Client error.
            var randomNumber = Math.floor(Math.random() * 100000);
            var errorNumber = `C-${randomNumber}`;

            message = `An error occurred.<br/><br/>(Error Number ${errorNumber})`;
            if (this._environment && !this._environment.production) {
                message += `</br></br>Details: ${error}<br/>${error.stack}`;
            }

            // Log this error to the server.
            try {
                // however, only log it if it didn't originate from the server
                // (e.g. a 500 will throw an unhelpful client side error that doesn't need to also be logged to the server)
                if (!error.message.includes("An unexpected server error occurred")) {
                    this.errorLogApiClient.logError(`${error.toString()}, Stack: ${error.stack}`).subscribe(_ => {
                    }, error2 => {
                        // Logging to the server failed. Console.log and otherwise do nothing.
                        console.log('Error occurred trying to log the error to the server: ' + error2);
                    });
                }
            } catch (exception) {
                console.log('Error occurred trying to log the error to the server: ' + exception);
            }
        }

        if(showAlert && errorDto && errorDto.errorMessage){
            if(errorDto.prompt) {
                this.messageService.alert(errorDto.errorMessage, 'Error');
            } else {
                this.messageService.notification(errorDto.errorMessage, 'error', 10000);
            }
        }

        if (showAlert && showErrorDialog){
            this.messageService.custom({
                title: 'Error',
                content: message,
                actions: [new TellerOnlineDialogAction({
                    text: 'OK',
                    close: false,
                })],
            });
        }

        if(this.appService.debug) {
            throw error;
        }
    }
}
