// Angular Files
import {
    Component,
    EventEmitter,
    Inject,
    NgModule,
    OnInit,
    Output
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, NgForm } from "@angular/forms";

// Angular Material Files
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";

//Other External Files
import { parsePhoneNumber, PhoneNumber } from "libphonenumber-js";
import { NgxMaskModule } from 'ngx-mask';

// Teller Online Files
import { COUNTRIES, Country } from "apps/public-portal/src/app/shared/constants/countries";
import { UserProfileModel, UserService } from "apps/public-portal/src/app/core/services";

// Teller Online Library Files
import { TellerOnlineSiteMetadataService } from 'teller-online-libraries/core';
import { TellerOnlineValidationService } from "teller-online-libraries/shared";

@Component({
    selector: "app-change-phone-modal",
    templateUrl: "./change-phone-modal.component.html",
    styleUrls: ["./change-phone-modal.component.scss"],
    host: {
        class: 'change-phone-modal'
    }
})
export class ChangePhoneModalComponent implements OnInit {
    @Output() setPhone = new EventEmitter();

    public userProfile: UserProfileModel = new UserProfileModel();
    public changePhoneFormModel = {
        number: "",
        country: Country.GetByCode(this.siteMetadataService.appConfiguration.defaultCountry)
    };
    public currentMask = this.validationService.northAmericaPhoneMask;
    public countriesCodes: Country[] = COUNTRIES;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ChangePhoneOptions,
        public dialogRef: MatDialogRef<ChangePhoneModalComponent>,
        public validationService: TellerOnlineValidationService,
        public userService: UserService,
        private siteMetadataService: TellerOnlineSiteMetadataService
    ) {}

    async ngOnInit() {
        if (this.data.currentPhone?.length > 0) {
            const phoneNumber: PhoneNumber = parsePhoneNumber(this.data.currentPhone);
            this.changePhoneFormModel.number = phoneNumber.nationalNumber as string;
            this.countriesCodes.forEach((item: Country) => {
                if (item.code === phoneNumber.country) {
                    this.changePhoneFormModel.country = item;
                }
            });
        }

        await this.userService.getUserProfile();

        this.currentMask = this.validationService.getPhoneNumberMask(this.data.otherCountry);
    }

    onSubmit_changePhone(form: NgForm) {
        if(!this.validationService.runValidation(form))
            return;

        if (this.changePhoneFormModel.number) {
            this.userProfile.phoneNumber = "+" + (this.changePhoneFormModel.country.dial_code + this.changePhoneFormModel.number);
        } else {
            this.userProfile.phoneNumber = "";
        }
        this.setPhone.next({
            number: this.userProfile.phoneNumber,
            country: this.changePhoneFormModel.country
        });

        this.dialogRef.close(true);
    }

    onSelectionChange_determinePhoneMask() {
        this.data.otherCountry = this.validationService.checkPhoneNumberNorthAmerica(this.changePhoneFormModel.country.dial_code);
        this.currentMask = this.validationService.getPhoneNumberMask(this.data.otherCountry);
    }

    public formatCountryCode(country: Country){
        return country ? `${country.name} +(${country.dial_code})` : '';
    }
}

@NgModule({
    imports: [
        CommonModule,
        MatSelectModule,
        MatFormFieldModule,
        MatButtonModule,
        MatDialogModule,
        MatInputModule,
        NgxMaskModule.forRoot(),
        FormsModule
    ],
    declarations: [ChangePhoneModalComponent],
    exports: [ChangePhoneModalComponent],
})
export class ChangePhoneModalModule {}

class ChangePhoneOptions {
    currentPhone?: string = "";
    otherCountry: boolean = false;
    dialCode: string;
}
