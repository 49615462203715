import { PaymentMethodTypeEnum, PaymentMethodTypeEnumConvertor } from "./PaymentMethodTypeEnum";
import { ECheckAccountTypeEnum } from "./ECheckAccountTypeEnum";
import { BillingDetails } from "./BillingDetails";
import { PaymentMethodModel } from "apps/public-portal/src/app/core/services";
import { ECheckAccountOwnerTypeEnum } from "./ECheckAccountOwnerTypeEnum";
import { StateOrProvince, Country } from 'apps/public-portal/src/app/shared/constants';

export class PaymentMethodData {
    paymentMethodId?: number;
    /** Used for checking in the view and easy conversion between Dto and Models */
    type: PaymentMethodTypeEnum;
    /** Used for sending to the backend/vice versa, mostly used on updating a payment method */
    paymentMethodType: PaymentMethodTypeEnum;
    last4Digits: string;
    cardNumber: string;
    cardType: string;
    cardExpiry: string;
    CVV: string;
    echeckAccountType: ECheckAccountTypeEnum;
    echeckAccountOwnerType: ECheckAccountOwnerTypeEnum;
    echeckAccountNumber: string;
    echeckRoutingNumber: string;
    billingInfo: BillingDetails = new BillingDetails();
    rememberPaymentMethod: boolean;

    constructor(data?: PaymentMethodData) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    fromPaymentMethodModel(model: PaymentMethodModel) {
        this.paymentMethodId = model.paymentMethodId;
        this.last4Digits = model.last4Digits;
        this.cardType = model.cardType;
        this.cardExpiry = model.cardExpiry;
        this.echeckAccountNumber = model.eCheckAccountNumber;
        this.echeckAccountType = ECheckAccountTypeEnum[model.eCheckAccountType];
        this.echeckAccountOwnerType = ECheckAccountOwnerTypeEnum[model.eCheckAccountOwnerType];
        this.echeckRoutingNumber = model.eCheckRoutingNumber;
        this.paymentMethodType = PaymentMethodTypeEnumConvertor.fromDto(model.paymentMethodType);
        this.type = this.paymentMethodType;

        this.billingInfo = new BillingDetails();
        this.billingInfo.addressLine1 = model.addressLine1;
        this.billingInfo.addressLine2 = model.addressLine2;
        this.billingInfo.addressCity = model.addressCity;
        this.billingInfo.fullName = model.fullName;
        this.billingInfo.addressZip = model.addressZip;
        this.billingInfo.phone = model.phone;

        this.billingInfo.addressCountry = model.addressCountry?.length <= 2
            ? Country.GetByCode(model.addressCountry) ?? new Country()
            : Country.GetByName(model.addressCountry) ?? new Country();

        if (model.addressState?.length <= 2) {
            if (Country.HasRegionList(this.billingInfo.addressCountry.code)) {
                this.billingInfo.addressState = StateOrProvince.GetByCode(model.addressState)
                    ?? new StateOrProvince(model.addressState, model.addressState, this.billingInfo.addressCountry.code);
            } else {
                this.billingInfo.addressRegion = model.addressState;
            }
        } else {
            if (Country.HasRegionList(this.billingInfo.addressCountry.code)) {
                this.billingInfo.addressState = StateOrProvince.GetByName(model.addressState)
                    ?? new StateOrProvince(model.addressState, model.addressState, this.billingInfo.addressCountry.code);
            } else {
                this.billingInfo.addressRegion = model.addressState;
            }
        }
    }
}
