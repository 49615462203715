// Angular Files
import { DOCUMENT } from '@angular/common';
import {
    Component,
    HostBinding,
    OnInit,
    OnDestroy,
    Inject,
    ViewChild,
    AfterViewInit,
    ChangeDetectorRef,
    ViewContainerRef
} from '@angular/core';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
    RouterEvent,
    RoutesRecognized
} from '@angular/router';

// Angular Material Files
import { MatSidenav } from '@angular/material/sidenav';

// Other External Files
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

// Payment Integration Files
import { PaymentMethodTypeEnum, PaymentProcessorProvider } from './payment-integrations';

// Teller Online Files
import {
    CartService,
    UserService,
    AuthService,
    ModalService,
    InboundRedirectService
} from './core/services';

// Teller Online Library Files
import { TellerOnlineIconsRegistryService } from 'teller-online-libraries/icons';
import {
    TellerOnlineSiteMetadataService,
    TellerOnlineAppService,
    TellerOnlineNotificationBannerService,
    TellerOnlineNotificationBannerAction,
    CartStatusEnum
} from 'teller-online-libraries/core';
import {
    TellerOnlineMessageService,
    TellerOnlineWindowService
} from 'teller-online-libraries/shared';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('cart') cart: MatSidenav;
    @ViewChild('help') help: MatSidenav;
    @ViewChild('mobilemenu') mobilemenu: MatSidenav;

    @ViewChild('modal') modal: MatSidenav;
    @ViewChild('modalcontent', {read: ViewContainerRef}) modalViewContainerRef: ViewContainerRef;

    @HostBinding('class') get getClass() {
        try {
            let classList = "";
            if(this._fullPageModalActive) classList += " app-root--full-page-modal-active";
            return classList;
        } finally {
            //prevent changed after checked issue by detecting changes after changing this
            this.cdr.detectChanges();
        }
    }

    // Public Variables
    public cartItemCount: number = 0;
    public userMustVerifyEmail: boolean = false;
    public showVerifyLink: boolean = true;

    // Private Variables
    private _navigationState;
    private _cartVersion: number = 0;
    /** tracks if the full page modal is currently open to apply a class */
    private _fullPageModalActive: boolean;
    /** The action to perform when the verify email button is clicked from the banner */
    private _VERIFY_EMAIL_ACTION: TellerOnlineNotificationBannerAction = {
        text: "Verify Now",
        href: "/profile/personal-info",
        hrefState: {showEmailConf: true}
    }

    // Subscriptions
    private _cartSubscription: Subscription;
    private _routerEventSubscription: Subscription;
    private _userProfileSubscription: Subscription;
    private _modalToggledSubscription: Subscription;
    private _itemAddedSubscription: Subscription;
    private _windowOfflineSubscription: Subscription;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private cdr: ChangeDetectorRef,
        private messageService: TellerOnlineMessageService,
        // we need to inject it so that it will be constructed, even though it's "not being used"
        private iconRegistry: TellerOnlineIconsRegistryService,
        public notificationBannerService: TellerOnlineNotificationBannerService,
        public modalService: ModalService,
        public paymentProvider: PaymentProcessorProvider,
        public cartService: CartService,
        public appService: TellerOnlineAppService,
        public router: Router,
        public siteMetadataService: TellerOnlineSiteMetadataService,
        public userService: UserService,
        public authService: AuthService,
        public windowService: TellerOnlineWindowService,
        public inboundRedirectService: InboundRedirectService
    ) {
        // Navigation between pages
        this._routerEventSubscription = this.router.events.subscribe((event: RouterEvent) => {
            this.handleRouterEventSubscription(event);
        });

        //Listen for if the user loses network connection
        windowService.addEventListener('offline', event => {
            this.notificationBannerService.addBanner({
                tag: 'offline',
                message: "Error contacting the server, please check your network connection.",
                bannerClass: "notification-banner--caution",
                svgIcon: "attention",
                svgIconClass: "teller-online-icon--caution",
                resolveAction: {
                    text: ""
                }
            })
        });

        windowService.addEventListener('online', event => {
            this.notificationBannerService.dismissBanner("offline");
        });

        this.loadTenantStyles(siteMetadataService.tenant);
    }

    ngOnInit() {
        this._userProfileSubscription = this.userService.currentUser$.subscribe(user => {
            this.userMustVerifyEmail = (user && !user?.emailVerified) ?? false;
            if(this.userMustVerifyEmail) {
                this.notificationBannerService.addBanner({
                    tag: 'verify-email',
                    message: "Please check your email and enter the confirmation code to verify your email address.",
                    resolveAction: {
                        text: this._VERIFY_EMAIL_ACTION.text,
                        href: this._VERIFY_EMAIL_ACTION.href,
                        hrefState: this._VERIFY_EMAIL_ACTION.hrefState
                    },
                    bannerClass: "notification-banner--caution",
                    svgIcon: "attention",
                    svgIconClass: "teller-online-icon--caution",
                    requiresAuthentication: true
                });
            } else {
                this.notificationBannerService.dismissBanner("verify-email");
            }
        });

        this._modalToggledSubscription = this.modalService.modalToggled$.subscribe((open: boolean) => {
            this._fullPageModalActive = open;
        });

        this._itemAddedSubscription = this.cartService.itemAdded$.subscribe((added: boolean) => {
            if(added && !this.cart.opened &&  this.appService.isWide) this.cart.open();
        });

        if (this.authService.isSignedIn) {
            this.userService.getUserProfile();
        }

        // Listen for errors triggered within the payment processors
        this.paymentProvider.activeCreditProcessor.errorEvent$.subscribe(error => {
            // If the payment processor throws an error, make sure we remove any loading state on the app
            this.appService.finishLoading();
        });

        this.paymentProvider.activeECheckProcessor.errorEvent$.subscribe(error => {
            // If the payment processor throws an error, make sure we remove any loading state on the app
            this.appService.finishLoading();
        });

        this.appService.finishLoading();
    }

    ngAfterViewInit() {
        // grab the references to the modal in the html so that it can be updated elsewhere
        this.modalService.setModal(this.modal, this.modalViewContainerRef);

        this._cartSubscription = this.cartService.cart$.pipe(delay(0)).subscribe((cart => {
            this.appService.consoleLog(this, cart, 'Event order: ' + cart.version);

            // If this is an older version of the cart, we don't want it
            if (cart?.version && cart.version < this._cartVersion)
                return;

            // Update the cart version
            this._cartVersion = cart?.version;

            // Ensure the cart has finished loading before doing anything
            if (!this.cartService.loadingCart) {
                if (cart && cart.cartId) {
                    // Total count of items in the cart
                    this.cartItemCount = cart.items?.length;
                } else {
                    this.cartItemCount = 0;
                }
            } else {
                // This is for if we are loading the cart later and need to reset the cartItemCount - it should always be 0 if it's loading
                this.cartItemCount = 0;
            }
        }));
    }

    ngOnDestroy() {
        if (this._cartSubscription) this._cartSubscription.unsubscribe();
        if (this._routerEventSubscription) this._routerEventSubscription.unsubscribe();
        if (this._userProfileSubscription) this._userProfileSubscription.unsubscribe();
        if (this._modalToggledSubscription) this._modalToggledSubscription.unsubscribe();
        if (this._itemAddedSubscription) this._itemAddedSubscription.unsubscribe();
    }

    loadTenantStyles(tenant: string) {
        const head = this.document.getElementsByTagName('head')[0];
        let tenantStylesLink = this.document.getElementById('tenant-styles') as HTMLLinkElement;

        // Tenants should be a single lowercase word
        if (!tenant.match(/^[a-z]+$/)) {
            return;
        }

        if (tenant) {
            const styleName = `assets/${tenant}/styles.css`;

            if (tenantStylesLink) {
                tenantStylesLink.href = styleName;
            } else {
                const style = this.document.createElement('link');
                style.id = 'tenant-styles';
                style.rel = 'stylesheet';
                style.href = `${styleName}`;

                head.appendChild(style);
            }
        } else if (tenantStylesLink) {
            tenantStylesLink.remove();
        }
    }

    public toggleCart(manual = false) {
        this.cart.toggle();
    }

    public toggleMobileMenu() {
        this.mobilemenu.toggle();
    }

    public async toggleHelp() {
        this.help.toggle();
    }

    public async helpOpening() {
        this.cart.close();
        this.mobilemenu.close();
    }

    public async cartOpening() {
        this.mobilemenu.close();
        this.help.close();

        // when the cart is opened on desktop, focus the first heading
        // (becuase focus does not get trapped automatically but if a user just opened something, we want to put focus in there)
        if(!this.appService.isMobile) {
            setTimeout(() => {
                let element: HTMLElement = this.document.querySelector('.cart .sidebar-header h2');
                element?.focus();
            },2);
        }
    }

    public async menuOpening() {
        this.cart.close();
        this.help.close();
    }

    handleRouterEventSubscription(event: RouterEvent) {
        const navigationExtras = this.router.getCurrentNavigation()?.extras;
        this._navigationState = navigationExtras?.state;
        this.appService.consoleLog(this, this._navigationState);
        this.appService.routerState = this._navigationState;

        switch (true) {
            case event instanceof RoutesRecognized:
                // Set the routeTitle to be used later when setDocumentTitle is called

                //@ts-ignore
                let routeTitle = event.state?.root.firstChild.data?.title;

                // Profile has it's own routing module, so when on child pages of profile,
                // use the titles from that routing component instead.
                if (event.url.startsWith('/profile')) {
                    //@ts-ignore
                    routeTitle = event.state?.root.firstChild.firstChild.firstChild.data?.title;
                }

                this.appService.setRouteTitle(routeTitle);
                break;
            case event instanceof NavigationStart:
                // Ensure all of the sidebars are closed if they should be by default
                if (this.help?.opened && (this.appService.isMobile || event.url.includes("help"))) this.toggleHelp();
                if (this.mobilemenu?.opened) this.toggleMobileMenu();
                if (this.cart?.opened && this.appService.isMobile) this.toggleCart();

                let loadingMessage = "Loading...";
                switch (true) {
                    case event.url.startsWith("/checkout/success"):
                        loadingMessage = "Retrieving Receipt...";
                        break;
                    case event.url.startsWith("/checkout/processing"):
                        loadingMessage = "Checking Payment...";
                        break;
                }

                this.appService.triggerLoading(loadingMessage);

                // Navigation to the checkout page in the TOL context is a two-step process ( -> /checkout?paymentMethodType=<methodType> -> /checkout), 
                // so, to avoid unnecessary API calls, we need to skip status update on the first step 
                const isTOLInitialCheckOut = event.url.startsWith("/checkout") && !!navigationExtras?.queryParams?.paymentMethodType;
                // When we're on the checkout page, update the cart's status to be CheckingOut
                if(event.url.startsWith("/checkout") &&
                    !event.url.includes("redirect") &&
                    !event.url.includes("processing") &&
                    !event.url.includes("success") &&
                    !isTOLInitialCheckOut) {
                    this.cartService.updateStatus(CartStatusEnum.CheckingOut);
                }

                // Refresh cart asynchronously to improve sync between backend and frontend.
                // This shouldn't happen on 
                // - checkout-processing
                // - while TOL context is loading. There are a few navigations that happen there (/TOL -> /checkout?paymentMethodType=<methodType> -> /checkout),
                //   we need to refresh the cart only on the last step
                // These are go-between pages that don't need the regular functionality.
                // Refreshing cart there is redundant or can lead to issues with updating the status of the cart.
                // All other situations are covered by the suppressCartRefresh state variable.
                if (this.cartService.cartGuid && 
                    !event.url.startsWith('/checkout/processing') &&
                    !event.url.startsWith('/TOL') &&
                    !isTOLInitialCheckOut &&
                    !this._navigationState?.suppressCartRefresh) {
                    this.cartService.refreshCart(this.cartService.cartGuid, undefined, false);
                }

                // if we're on the receipt page, close the cart because it's not relevant on this page
                if(event.url.startsWith("/checkout/success")) {
                    if(this.cart.opened) this.toggleCart();
                }
                break;
            case event instanceof NavigationCancel:
            case event instanceof NavigationError:
                this.appService.finishLoading();
                this.cdr.detectChanges();
                break;
            case event instanceof NavigationEnd:
                let loadedMessage = "Loaded";

                // Update the title attribute for the page
                this.appService.setDocumentTitle();

                switch (true) {
                    case event.url.startsWith("/checkout/success"):
                        loadedMessage = "Retrieved receipt";
                        break;
                    case event.url.startsWith("/checkout/processing"):
                        loadedMessage = "";
                        break;
                }

                this.appService.finishLoading(loadedMessage);

                //@ts-ignore
                this.appService.currentUrl = event.urlAfterRedirects;

                // If there is a cartError in the state parameter, display it to the user
                if (this._navigationState && this._navigationState.cartError) {
                    this.appService.consoleLog(this, this._navigationState.cartError);
                    setTimeout(() => {
                        this.messageService.notification(this._navigationState.cartError, 'error', 6000);
                    }, 10);
                }

                // When the user is not on the checkout page their payment method is unknown, so reset
                // the payment method type to undetermined in order to use the default convenience fee config
                if (this.router.url != '/checkout' && this.paymentProvider.defaultConfig.convenienceFeeType != PaymentMethodTypeEnum.Undetermined) {
                    this.paymentProvider.useConvenienceFee(PaymentMethodTypeEnum.Undetermined)
                }

                // If the notification banner service is enabled, we likely have a verify email banner
                // So determine if we need to change the action of the verify email button based on what page we're on
                if(this.notificationBannerService.enabled) {
                    let verifyResolveAction;

                    if(!event.url.endsWith('/profile') && !event.url.startsWith('/profile/personal-info')) {
                        // ensure we have an anchor tag, no click handler
                        verifyResolveAction = {
                            href: this._VERIFY_EMAIL_ACTION.href,
                            hrefState: this._VERIFY_EMAIL_ACTION.hrefState,
                            click: null
                        }
                    } else {
                        // ensure we have a click handler, but not anchor tag
                        verifyResolveAction = {
                            href: null,
                            hrefState: null,
                            click: this.notificationBannerService.clickResolve
                        }
                    }

                    this.notificationBannerService.updateBanner('verify-email', {
                        resolveAction: verifyResolveAction
                    });
                }

                // push out when we focus to ensure the html has actually rendered
                setTimeout(() => {
                    const mainFocus: HTMLElement = this.document.querySelector('#main-content-focus');

                    if (mainFocus) {
                        mainFocus.focus();
                    }
                }, 0);

                this.cdr.detectChanges();

                if (this.inboundRedirectService.isInboundRedirect && !this.inboundRedirectService.requestTriggered) {
                    // Make sure this event happens only once per page load
                    this.inboundRedirectService.reinitializeRequest();
                }

                break;
        }
    }
}
