// Angular Files
import { Injectable } from '@angular/core';

// Teller Online Core Files
import {
    SiteCustomizationDto,
    AppConfigurationDto,
    SiteMetadataDto,
    MenuItemDto,
    PortalFeatureDto,
    SiteMetadataApiClient
} from '../api/CoreWebApi';

@Injectable({
    providedIn: 'root'
})
export class TellerOnlineSiteMetadataService {
    private _metadata: SiteMetadataDto;
    loaded: boolean;

    static features: {
        authenticatedUsers: PortalFeatureDto,
        tellerOnlineLite: PortalFeatureDto
    };

    constructor(private siteMetadataApiClient: SiteMetadataApiClient) {}

    init(baseUrl: string) {
        SiteMetadataApiClient.init(baseUrl);
    }

    public async load() {
        this._metadata = await this.siteMetadataApiClient.getSiteMetadata().toPromise();
        this.loaded = true;
    }

    public get appConfiguration(): AppConfigurationDto {
        if (!this._metadata) {
            throw new Error("Site metadata is not loaded!");
        }

        return this._metadata.appConfiguration;
    }

    public get portalFeatures(): {authenticatedUsers: PortalFeatureDto, tellerOnlineLite: PortalFeatureDto} {
        if (!this._metadata) {
            throw new Error("Site metadata is not loaded!");
        }

        if(!TellerOnlineSiteMetadataService.features) {
            let features = {
                authenticatedUsers: null,
                tellerOnlineLite: null
            };

            // Change from an array to an key value list so the items can be referenced by name rather than index
            this._metadata.portalFeatures.forEach(feature => {
                features[feature.name.replace(feature.name[0], feature.name[0].toLowerCase())] = feature;
            });

            TellerOnlineSiteMetadataService.features = features;
        }

        return TellerOnlineSiteMetadataService.features;
    }

    public get customization(): SiteCustomizationDto {
        if (!this._metadata) {
            // throw new Error("Site metadata is not loaded!");
            return null;
        }

        return this._metadata.siteCustomization;
    }

    public get menuItems(): MenuItemDto[] {
        if (!this._metadata) {
            // throw new Error("Site metadata is not loaded!");
            return null;
        }

        return this._metadata.menuItems;
    }

    public get authenticationEnabled(): boolean {
        return this.portalFeatures.authenticatedUsers.enabled;
    }

    public get tellerOnlineLiteEnabled(): boolean {
        return this.portalFeatures.tellerOnlineLite?.enabled ?? false;
    }

    public get tenant(): string {
        return this._metadata.tenantName.toLowerCase() ?? null;
    }
}
