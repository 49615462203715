// Angular Files
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Material Files
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';


// Other External Files
import { EllipsisModule } from 'ngx-ellipsis';

// Payment Integration Files
import {
    BasePaymentProcessorIntegrationsModule
} from 'apps/public-portal/src/app/payment-integrations/base';

// Component Files
import { DatacapComponent } from "./component";

// Teller Online Library Files
import { TellerOnlineIconsModule } from "teller-online-libraries/icons";
import { MatGridListModule } from "@angular/material/grid-list";


@NgModule({
    imports: [
        CommonModule,
        TellerOnlineIconsModule,
        BasePaymentProcessorIntegrationsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        MatGridListModule,
        EllipsisModule
    ],
    declarations: [DatacapComponent],
    exports: [DatacapComponent],
})
export class DatacapModule { }
