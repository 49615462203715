// Angular Files
import { Injectable, Injector } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// Payment Integration Files
import { PaymentProcessorProvider, PaymentProcessorService } from 'apps/public-portal/src/app/payment-integrations';

// Teller Online Files
import { ServicesModule } from 'apps/public-portal/src/app/shared/services/services.module';

// Teller Online Library Files
import { TellerOnlineIconsService } from 'teller-online-libraries/icons';
import { TellerOnlineSiteMetadataService } from 'teller-online-libraries/core';

@Injectable({
    providedIn: ServicesModule
})
export class HelpService {
    public articles: HelpArticle[];

    constructor(
        sanitizer: DomSanitizer,
        injector: Injector,
        iconService: TellerOnlineIconsService
    ) {
        // Because the help service is used in _____ it can't load in other services, so we inject them afterwards to avoid circular dependencies
        let paymentProvider = injector.get(PaymentProcessorProvider);
        let siteMetadataService = injector.get(TellerOnlineSiteMetadataService);
        let authenticatedUsersEnabled = siteMetadataService.authenticationEnabled;

        this.articles = HELP_ARTICLES;
        this.articles.forEach(article => {
            // TODO PROD-276: Re-evaluate if we can no longer assume Credit and E-Check are using the same processor
            article.applyInlineConditions(paymentProvider.activeCreditProcessor, authenticatedUsersEnabled);
            article.replaceIcons(iconService);
            article.trustedBody = sanitizer.bypassSecurityTrustHtml(article.body);
        });
    }
}
export class HelpArticle {
    constructor(properties) {
        this.tag = properties.tag;
        this.conditions = properties.conditions;
        this.bookmark = properties.bookmark;
        this.title = properties.title;
        this.body = properties.body;
        this.order = properties.order;
    }
    tag: HelpTag;
    bookmark: string;
    conditions?: HelpConditional[];
    title: string;
    body: string;
    trustedBody: SafeHtml;
    order: number;
    applyInlineConditions = (processorService: PaymentProcessorService, authenticatedUsersEnabled: boolean) => {
        let regex = /\|condition:(?<condition>.*?)\|/;
        let match;
        while (match = regex.exec(this.body)) {
            let condition = match.groups.condition;

            // default to not remove the text (text will remain, condition will go)
            let remove: boolean = false;

            // Check if we need to remove the text as well
            switch(condition) {
                case HelpConditional.NotRedirect:
                    remove = processorService.redirect;
                    break;
                case HelpConditional.Redirect:
                    remove = !processorService.redirect;
                    break;
                case HelpConditional.Users:
                    remove = !authenticatedUsersEnabled;
                    break;
            }
            // remove the whole block of text
            if(remove) {
                let replace: RegExp = new RegExp(`\\|condition:${condition}\\|.*?\\|endcondition:${condition}\\|`, "g");
                this.body = this.body.replace(replace, "");
            //remove just the conditional, leave the block of text
            } else {
                this.body = this.body.replace(`|condition:${condition}|`, "").replace(`|endcondition:${condition}|`, "")
            }
        }
    }
    replaceIcons = (iconService: TellerOnlineIconsService) => {
        let regex = /[^https]:(?<icon>.*?):[^\/]/;
        let match;
        while (match = regex.exec(this.body)) {
            let icon = match.groups.icon;
            this.body = this.body.replace(`:${icon}:`, iconService.loadIcon(icon));
        }
    }
    match = (matchValue: string, tagMatch: boolean = false) => {
        return tagMatch ? this.tag == matchValue
        : this.body.includes(matchValue)
        || this.title.includes(matchValue)
        || this.tag.includes(matchValue);
    }
}

export enum HelpTag {
    Checkout = "checkout",
    Cart = "cart",
    ManageProfile = "manage-profile",
    Authentication = "authentication",
    Search = "search",
    PaymentMethods = "payment-methods",
    PaymentHistory = "payment-history"
}

export enum HelpConditional {
    Users = "Users",
    Redirect = "Redirect",
    NotRedirect = "NotRedirect"
}

export const HELP_ARTICLES: HelpArticle[] = [
    new HelpArticle({
        tag: HelpTag.Cart,
        order: 0,
        bookmark: "where-is-the-cart",
        title: "Where is the cart?",
        body: "<p>The cart can be opened at any time by clicking the (:cart:) icon in the bottom right corner of the screen. While the help menu is open, the cart icon is not visible.</p>"
    }),
    new HelpArticle({
        tag: HelpTag.Search,
        order: 1,
        bookmark: "where-do-i-start",
        title: "Where do I start?",
        body: "<p>To get started, from the home page, select one of the large white blocks below the banner. This will take you to a search page. See <a href='help#how-do-i-search'>How do I search?</a> for more details.</p>"
    }),
    new HelpArticle({
        tag: HelpTag.Search,
        order: 2,
        bookmark: "how-do-i-search",
        title: "How do I search?",
        body: "<p>Select one of the search options from the list. Then enter any required (*) information and click <b>Search</b> :right-arrow:.<p>"
    }),
    new HelpArticle({
        tag: HelpTag.Search,
        order: 3,
        bookmark: "no-results-found",
        title: "Why does it say no results were found?",
        body: "<p>First, confirm you are entering the correct information into the correct fields. If you aren't sure where to find the information, please contact us.</p>"+
              "<p>If you are sure the information is correct, it is possible it may need to be paid for in person, please contact us."
    }),
    new HelpArticle({
        tag: HelpTag.Search,
        order: 4,
        bookmark: "how-do-i-know-correct-item",
        title: "How do I know I'm paying for the correct thing?",
        body: "<p>Clicking on the name of the item in your cart will take you to a details page where you can confirm the item is the one you intended to pay for.</p>"
    }),
    new HelpArticle({
        tag: HelpTag.Search,
        order: 5,
        bookmark: "what-is-item-details",
        title: "What is the item details page?",
        body: "The item details page allows you to: <ul>"+
              "<li>Review the details of an item prior to payment.</li>"+
              "<li>Select a different payment option by clicking \"Change\" next to the currently selected payment option.</li>"+
              "</ul>"
    }),
    new HelpArticle({
        tag: HelpTag.Search,
        order: 6,
        bookmark: "where-are-payment-options",
        title: "What are payment options?",
        body: "<p>Payment options are the different amounts that you can pay towards an account.</p>"
    }),
    //TODO: Review the content of these articles (here and below)
    new HelpArticle({
        tag: HelpTag.Cart,
        order: 6,
        bookmark: "cart-warning",
        title: "What is this warning?",
        body: "<p>When the price of an item you have in your cart has changed since you added it you will see a warning.</p>"+
              "<p>This is most often just a message informing you that there was a change.</p>"+
              "<p>Occasionally, you may be required to take action if the payment option you previously chose is no longer available.</p>"
    }),
    new HelpArticle({
        tag: HelpTag.Checkout,
        order: 7,
        bookmark: "zip-code-does-not-match",
        title: "Why do I keep getting \"Zip code does not match billing address\"?",
        body: "<p>If you are receiving this message at checkout, the payment processor is indicating the zip code you have entered does not match the address that your card issuer has on file.</p>"+
              "<p>We recommend you double check the value you are entering is correct and confirm that your card issuer has the correct address.</p>"
    }),
    new HelpArticle({
        tag: HelpTag.Checkout,
        order: 8,
        conditions: [HelpConditional.NotRedirect],
        bookmark: "credit-card-rejected",
        title: "Why is my credit card being rejected?",
        body: "There are multiple reasons your credit card could be rejected. Please confirm the following:"+
              "<ul>"+
              "<li>You are entering the name that appears on the credit card.</li>"+
              "<li>You are entering the correct credit card number, expiry date (in the format MM/YY e.g. 08/22), and CVV.</li>"+
              "<li>You are entering the correct zip code that your credit card issuer has on file for your credit card.</li>"+
              "</ul>"+
              "<p>*If your credit card number is correct, you should see a small icon indicating the card type (Visa, MasterCard, Discover, Amex) next to the number.</p>"
    }), // TODO: This description fits celero but may not fit others
    new HelpArticle({
        tag: HelpTag.Authentication,
        order: 9,
        conditions: [HelpConditional.Users],
        bookmark: "how-do-i-create-account",
        title: "How do I create an account?",
        body: "<p>To create an account, click the :sign-up: <b>Sign Up</b> button, or use the Sign Up link provided in the email receipt of a previous payment.</p>"+
              "<p>Once you submit the Sign Up form, a verification code will be sent to the email you provided. Enter the code to confirm your account.</p>"
    }),
    new HelpArticle({
        tag: HelpTag.ManageProfile,
        order: 10,
        conditions: [HelpConditional.Users],
        bookmark: "manage-account",
        title: "How can I manage my account settings?",
        body: "<p>Click on your username :user: (or the menu :menu:button on mobile) to access account settings.</p>"+
              "From the drop down, you can:"+
              "<ul>"+
              "<li>Update your personal information</li>"+
              "<li>Manage notification settings</li>"+
              "<li>Change your password</li>"+
              "<li>Manage saved payment methods</li>"+
              "<li>View your payment history</li>"+
              "<li>Sign Out</li>"+
              "</ul>"
    }),
    new HelpArticle({
        tag: HelpTag.Cart,
        order: 11,
        bookmark: "how-remove-item-from-cart",
        title: "How do I remove something from my cart?",
        body: "You can remove an item from your cart by clicking the :remove: button. this button can be found"+
              "<ul>"+
              "<li>At the bottom of the item card in the search results.</li>"+
              "<li>At the bottom of the item card on the item details page.</li>"+
              "<li>In you cart next to the item that you want to remove.</li>"+
              "</ul>"
    }),
    new HelpArticle({
        tag: HelpTag.Checkout,
        order: 12,
        bookmark: "how-do-i-pay",
        title: "How do I pay?",
        body: "<p>Open your cart if it's not currently open by clicking on the cart button (:cart:) in the bottom right.</p> "+
              "|condition:"+HelpConditional.Redirect+"|<p>At the bottom of the cart panel there is a field for you to provide an email, which is required so a receipt can be emailed to you.</p>"+
              "<p>After you have input an email address, you can click \"Checkout\" below the email field in order to proceed.</p>|endcondition:"+HelpConditional.Redirect+"|"+
              "|condition:"+HelpConditional.NotRedirect+"|<p>At the bottom of the cart panel there is a \"Checkout\" button which will take you to the checkout page.</p>"+
              "<p>Once there, you can choose how you would like to pay (Credit Card or E-Check), enter your payment details, and click the <b>Pay</b> button at the bottom.</p>|endcondition:"+HelpConditional.NotRedirect+"|"
    }),
    new HelpArticle({
        tag: HelpTag.PaymentMethods,
        order: 13,
        conditions: [HelpConditional.NotRedirect],
        bookmark: "what-are-saved-payment-methods",
        title: "What are saved payment methods?",
        body: "<p>Saved payment methods are a way of storing a payment method (Credit Card, etc..) for later use.</p>"+
              "<p>At checkout, you can select a saved payment method and click the <b>Pay</b> button.</p>"
    }),
    new HelpArticle({
        tag: HelpTag.PaymentMethods,
        order: 14,
        bookmark: "how-to-add-saved-payment-method",
        title: "How do I add a saved payment method?",
        body: "There are 2 ways to add a saved payment method."+
              "<ul>"+
              "<li>You can use the <b>Save Payment Method</b> checbox at checkout.</li>"+
              "<li>Navigate to the Payment Methods page and click the :plus: button in the bottom right corner.</li>"+
              "</ul>"
    }),
    new HelpArticle({
        tag: HelpTag.PaymentMethods,
        order: 15,
        bookmark: "how-to-update-saved-payment-method",
        title: "How do I update a saved payment method?",
        body: "In the Payment methods page, you can view your existing payment methods. To update a method, click the :select-payment: <b>Edit</b> button."
    }),
    new HelpArticle({
        tag: HelpTag.PaymentHistory,
        order: 16,
        bookmark: "view-previous-payments",
        title: "How can I view receipts from previous payments?",
        body: "<p>In the Payment History page, there is a list of all of your previous transactions. "+
              "Click on a transaction to show further details.</p> <p>Click on the <b>Download Receipt</b> :download-receipt:"+
              "button in the popup to download a copy of the receipt.</p>"
    }),
    new HelpArticle({
        tag: HelpTag.Authentication,
        order: 17,
        conditions: [HelpConditional.Users],
        bookmark: "forgot-password",
        title: "What do I do if I forgot my password?",
        body: "<p>If you have forgotten your password, click the <em>Forgot your password?</em> link on the Sign In page, enter the email address associated with your account, "+
              "and click the <b>Reset Password</b> button.</p> <p>Enter the verification code sent to your email address and create a new password for your account. " +
              "Confirm your password and click the <b>Submit</b> button to reset your password.</p>"
    }),
    new HelpArticle({
        tag: HelpTag.Authentication,
        order: 18,
        conditions: [HelpConditional.Users],
        bookmark: "verification-code-not-working",
        title: "Why is my verification code not working?",
        body: "<p>Verification codes are sent via text message or email automatically. In the case that you didn't receive a code, or the code is not working, you can click <em>Try again</em> (under the code field) after 60 seconds.</p>"+
              "<p>If the verification code still isn't working, confirm that you are using the most recent code that you have received.</p>"
    })
];
