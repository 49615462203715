// Angular Files
import { CommonModule } from '@angular/common';
import { Component, NgModule, Input, Output, EventEmitter, ViewChild, AfterContentInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

// Angular Material Files
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// Other External Files
import { NgxMaskModule } from 'ngx-mask';

// Teller Online Files
//TODO (PROD-156): Remove ref to Enum Dto 
import { ConfirmCodeRequestTypeEnumDto } from 'apps/public-portal/src/app/core/api/TellerOnlineIdentityApiClients';
import { AuthService, ResendEmailConfirmationRequestModel } from 'apps/public-portal/src/app/core/services';

// Teller Online Library Files
import { TellerOnlineIconsModule } from "teller-online-libraries/icons";
import { TellerOnlineAppService } from 'teller-online-libraries/core';
import { TellerOnlineSharedModule, TellerOnlineValidationService } from 'teller-online-libraries/shared';

@Component({
    selector: 'app-code-verification',
    templateUrl: './code-verification.component.html',
    styleUrls: ['./code-verification.component.scss'],
    host: {
        class: 'code-verification'
    }
})
export class CodeVerificationComponent implements AfterContentInit{
    @ViewChild('verificationCodeInput', { static: false }) verificationCodeInput: HTMLElement;

    // Declare @Input variables
    @Input() signUpToken: string;
    @Input() email: string;
    @Input() phone: string;
    @Input() authentication: string;
    @Input() codeSent: boolean;
    @Input() requestType: ConfirmCodeRequestTypeEnumDto;
    @Input() handlesSubmit: boolean;
    @Input() modal: boolean = false;
    @Input("aria-label") ariaLabel: string = "Verification Code";

    // Declare @Output variables
    @Output() verifyCode: EventEmitter<any> = new EventEmitter<any>();
    @Output() resendCode: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() submitCode: EventEmitter<any> = new EventEmitter<any>();

    // Public variables
    public resendCounter = 0;
    public ariaResendCounter = 0;
    public resendEnabled = true;
    public verificationCode: string = "";
    public verificationCodeAdapter = {
        getValue: () => {
            return this.verificationCode;
        }
    };
    public requestTypeEnum = ConfirmCodeRequestTypeEnumDto;
    public requestTypeText = "email"; //default to email

    // Private variables
    private _validatingCode = false;

    // Subscriptions

    constructor(
        private authService: AuthService,
        public appService: TellerOnlineAppService,
        public validationService: TellerOnlineValidationService
    ) {}

    ngAfterContentInit() {
        if(this.requestType == ConfirmCodeRequestTypeEnumDto.Phone) {
            this.requestTypeText = "phone"
            if (!this.codeSent) {
                this.authService.resendPhoneCode();
                this.codeSent = true;
            }
        } else {
            this.requestTypeText = "email";
            if (!this.codeSent) {
                let request = new ResendEmailConfirmationRequestModel();
                request.email = this.email;
                request.requestType = this.requestType;
                this.authService.resendEmailCode(request);
                this.codeSent = true;
            }
        }
        this._disableResend(this.codeSent);
    }

    // Only important when the submit button is actually clicked and the submission was invalid
    // (Submit will not be called)
    // Will trigger the validation messages to display
    onClick_validate = async (form) => {
        if (!this.validationService.runValidation(form)) {
            this.verificationCodeInput.focus();
            return false;
        }
    }

    onSubmit_verifyCode = async (form) => {
        // e.preventDefault();

        // In scenarios where the code component is the only input, allow it to
        // validate normally. When it is not the only component (i.e. password-reset),
        //  trigger the custom submitCode event
        if (this.handlesSubmit) {
            return await this._validateVerificationCode(form);
        } else {
            // Notify the parent component that the user has tried to submit
            this.submitCode.emit();
        }
    }

    onClick_resendCode = async () => {
        if (this.resendEnabled) {
            let request;
            try {
                if(this.requestType == ConfirmCodeRequestTypeEnumDto.Phone) {
                    await this.authService.resendPhoneCode();
                } else {
                    request = new ResendEmailConfirmationRequestModel();
                    request.email = this.email;
                    request.requestType = this.requestType;
                    await this.authService.resendEmailCode(request);
                }
            } finally {
                this.appService.finishPageLoading();
            }

            this.resendCode.emit(true);

            this._disableResend();
        }
    }

    onKeyup_setVerificationCode = async (e, form) => {
        this.verificationCode = e.target.value;

        if(this.handlesSubmit && this.verificationCode?.length == 6) {
            await this._validateVerificationCode(form);
        } else if(!this.handlesSubmit) {
            this.verifyCode.emit(this.verificationCode);
        }
    }

    public get ariaResendStatus() {
        if (this.resendCounter > 0){
            // If our resend count is a multiple of 10, we need to update our screen-reader-only count so listeners
            // are made aware of the countdown progress. 10 seconds is enough time for the entire line to be read-out 
            // while keeping within ARIA standards.
            if (this.resendCounter % 10 == 0) 
                this.ariaResendCounter = this.resendCounter;
            return this.ariaResendCounter + " seconds.";
        } else {
            return "Try again";
        }
    }

    private async _validateVerificationCode(form) {
        // Don't run this if we're already validating
        if(this._validatingCode)
            return;

        if(!this.validationService.runValidation(form)) {
            this.verificationCodeInput.focus();
            return false;
        }
        this._validatingCode = true;

        this.appService.triggerPageLoading();

        let verificationError = false;

        try
        {
            await this.authService.confirmCode(this.verificationCode, this.requestType, this.email, this.signUpToken, this.authentication);

            this.verifyCode.emit(true);
        } catch (e) { 
            // If we've encountered an exception, the user was unable to be verified
            verificationError = true;
            // Throw the exception we caught so we still get an error message to be displayed
            throw e;
        }
        finally
        {
            this._validatingCode = false;

            // If we encountered an error or are not trying to sign-in, finish loading the page
            if (verificationError || !this.appService.currentUrl.includes('sign-up'))
                this.appService.finishPageLoading();
        } 
    }

    private _disableResend(codeSent = true) {
        if(codeSent) {
            this.resendEnabled = false;
            this.resendCounter = 60;
            this.ariaResendCounter = this.resendCounter;

            const resendCodeInterval = setInterval(() => {
                this.resendCounter -= 1;
                
                if (this.resendCounter === 0) {
                    clearInterval(resendCodeInterval);
                    this.resendEnabled = true;
                }
            }, 1000);
        }
    }

}
@NgModule({
    imports: [
        CommonModule,
        TellerOnlineIconsModule,
        TellerOnlineSharedModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        FormsModule,
        NgxMaskModule
    ],
    declarations: [ CodeVerificationComponent ],
    exports: [ CodeVerificationComponent ]
})
export class CodeVerificationModule { }
