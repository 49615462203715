// Angular Files
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';

// Angular Material Files
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// Other External Files
import { EllipsisModule } from 'ngx-ellipsis';

// Teller Online Component Files
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { CartComponent } from './components/cart/cart.component';
import { HelpSidebarComponent } from './components/help-sidebar/help-sidebar.component';

// Teller Online Files
import { SharedModule } from 'apps/public-portal/src/app/shared/shared.module';
import { CartService } from './services/cart.service';
import { ReceiptService } from './services/receipt.service';

// Teller Online Library Files
import { TellerOnlineIconsModule } from 'teller-online-libraries/icons';
import { TellerOnlineSharedModule } from 'teller-online-libraries/shared';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        EllipsisModule,
        TellerOnlineSharedModule,
        TellerOnlineIconsModule,
        FormsModule,
        //Angular Material
        MatToolbarModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        MatListModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        A11yModule
    ],
    declarations: [
        HelpSidebarComponent,
        HeaderComponent, 
        FooterComponent, 
        MobileMenuComponent, 
        CartComponent
    ],
    exports: [
        HelpSidebarComponent,
        HeaderComponent, 
        FooterComponent, 
        MobileMenuComponent, 
        CartComponent
    ],
    providers: [
        CartService,
        ReceiptService
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }
 }
