// Angular Files
import { Injectable } from '@angular/core';

// Angular Material Files
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

// Teller Online Icons Library Files
import { TellerOnlineIconsService } from './service';

@Injectable()
export class TellerOnlineIconsRegistryService {
    constructor(
        matIconRegistry: MatIconRegistry, 
        iconService: TellerOnlineIconsService,
        domSanitizer: DomSanitizer
    ) {
        matIconRegistry.addSvgIconLiteral("error", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("error")));
        matIconRegistry.addSvgIconLiteral("payment-complete", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("payment-complete")));
        matIconRegistry.addSvgIconLiteral("success", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("payment-complete")));
        matIconRegistry.addSvgIconLiteral("info", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("info")));
        matIconRegistry.addSvgIconLiteral("remove", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("remove")));
        matIconRegistry.addSvgIconLiteral("user", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("user")));
        matIconRegistry.addSvgIconLiteral("menu", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("menu")));
        matIconRegistry.addSvgIconLiteral("cart", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("cart")));
        matIconRegistry.addSvgIconLiteral("home", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("home")));
        matIconRegistry.addSvgIconLiteral("search", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("search")));
        matIconRegistry.addSvgIconLiteral("user-dashboard", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("user-dashboard")));
        matIconRegistry.addSvgIconLiteral("locked", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("locked")));
        matIconRegistry.addSvgIconLiteral("preferences", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("preferences")));
        matIconRegistry.addSvgIconLiteral("sign-up", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("sign-up")));
        matIconRegistry.addSvgIconLiteral("logout", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("logout")));
        matIconRegistry.addSvgIconLiteral("login", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("login")));
        matIconRegistry.addSvgIconLiteral("view-bill-details", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("view-bill-details")));
        matIconRegistry.addSvgIconLiteral("envelope-tracking", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("envelope-tracking")));
        matIconRegistry.addSvgIconLiteral("view-bill", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("view-bill")));
        matIconRegistry.addSvgIconLiteral("download-receipt", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("download-receipt")));
        matIconRegistry.addSvgIconLiteral("credit-card", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("credit-card")));
        matIconRegistry.addSvgIconLiteral("add-to-cart", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("add-to-cart")));
        matIconRegistry.addSvgIconLiteral("subnav-arrow", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("subnav-arrow")));
        matIconRegistry.addSvgIconLiteral("right-arrow", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("right-arrow")));
        matIconRegistry.addSvgIconLiteral("plus", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("plus")));
        matIconRegistry.addSvgIconLiteral("minus", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("minus")));
        matIconRegistry.addSvgIconLiteral("expand", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("expand")));
        matIconRegistry.addSvgIconLiteral("collapse", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("collapse")));
        matIconRegistry.addSvgIconLiteral("echeck", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("echeck")));
        matIconRegistry.addSvgIconLiteral("close", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("close")));
        matIconRegistry.addSvgIconLiteral("select-payment", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("select-payment")));
        matIconRegistry.addSvgIconLiteral("cart-empty", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("cart-empty")));
        matIconRegistry.addSvgIconLiteral("attention", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("attention")));
        matIconRegistry.addSvgIconLiteral("merchant-logos", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("merchant-logos")));
        matIconRegistry.addSvgIconLiteral("help", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("help")));
        matIconRegistry.addSvgIconLiteral("cancel", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("cancel")));
        matIconRegistry.addSvgIconLiteral("email", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("email")));
        matIconRegistry.addSvgIconLiteral("mark-refunded", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("mark-refunded")));
        matIconRegistry.addSvgIconLiteral("sms", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("sms")));
        matIconRegistry.addSvgIconLiteral("sync-teller", domSanitizer.bypassSecurityTrustHtml(iconService.loadIconSvg("sync-teller")));
    }
}