// Angular Files
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Material Files
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';

// Other External Files
import { NgxMaskModule } from 'ngx-mask';

// Component Files
import { SelectPaymentComponent } from './select-payment.component';

// Teller Online Library Files
import { TellerOnlineSharedModule } from 'teller-online-libraries/shared';

@NgModule({
    declarations: [ SelectPaymentComponent ],
    imports: [
        TellerOnlineSharedModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatListModule,
        MatIconModule,
        MatInputModule,
        NgxMaskModule,
        MatFormFieldModule
    ],    
    providers: [
        { provide: MatBottomSheetRef, useValue: {} },
        { provide: MAT_BOTTOM_SHEET_DATA, useValue: MAT_BOTTOM_SHEET_DATA }
    ],
    exports: [ SelectPaymentComponent ]

})

export class SelectPaymentModule { }