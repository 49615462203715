// Angular Files
import { NgModule } from '@angular/core';

// Teller Online Directive Files
import { ClickOutsideDirective } from './click-outside.directive';
import { NumericDirective } from './numeric.directive';
import { RemoveWhitespaceDirective } from './remove-whitespace.directive';
import { RestrictFocusDirective } from './restrict-focus.directive';
import { ValidateExpirationDateDirective } from './validate-expiration-date.directive';
import { TrimWhitespaceDirective } from './trim-whitespace.directive';

@NgModule({
  imports: [],
  declarations: [RestrictFocusDirective, ClickOutsideDirective, NumericDirective, RemoveWhitespaceDirective, TrimWhitespaceDirective, ValidateExpirationDateDirective],
  exports: [RestrictFocusDirective, ClickOutsideDirective, NumericDirective, RemoveWhitespaceDirective, TrimWhitespaceDirective, ValidateExpirationDateDirective]
})
export class DirectivesModule { }
