// Angular Files
import { Injectable } from '@angular/core';

// Other External Files

// Teller Online Files
import { CoreModule } from 'apps/public-portal/src/app/core/core.module';
import {
    ItemDto,
    ItemPaymentOptionDto,
    SearchApiClient,
    SearchTypeDto,
    ItemDetailDto,
    SearchFieldDto,
    PageitemtypeApiClient,
    PageItemTypeDto,
    ItemFormInputDto,
    CartItemCustomValueDto,
    ItemFormInputGroupDto,
    ItemCalculatedFieldDto
} from 'apps/public-portal/src/app/core/api/PublicPortalApiClients';

@Injectable({
    // Depending how it's used with the app, you may not be able to provide it within the module, it may need to use the alternate version
    // For core services that are used in the APP_INITIALIZER or within the app.module itself will need to use 'root'
    providedIn: CoreModule
})
export class ItemService {
    constructor(
        private searchApiClient: SearchApiClient,
        private pageItemTypeApiClient: PageitemtypeApiClient
    ) {
    }

    public async searchForItems(pageId: number, cartGuid: string, searchValues: {[key:string]: string}): Promise<ItemModel[]> {
        return await this.searchApiClient.searchForItems(pageId, cartGuid, searchValues ).toPromise();
    }

    public async getPageItemTypeDetails(pageId: number, itemTypeNo: number): Promise<PageItemTypeModel> {
        return await this.pageItemTypeApiClient.getPageItemTypeDetails(pageId, itemTypeNo).toPromise();
    }

    /** Create a list of SearchResults that includes entries for headings provided by the item details */
    createItems(items: ItemModel[], pageItemIndexCutoff?: number): {searchResults: SearchResult[], index: number} {
        let results = [];
        let previousItem: ItemModel = null;
        let retrievedIndex = false;
        for (let i = 0; i < items?.length; i++) {
            this._compareHeading(items[i], previousItem, 1, results);
            // capture what the new index for the last item of the page is
            if(!retrievedIndex && pageItemIndexCutoff && i == pageItemIndexCutoff) {
                pageItemIndexCutoff = results?.length;
                retrievedIndex = true;
            }
            results.push(this._removeTags(items[i]));
            previousItem = items[i];
        }

        return { 
            searchResults: results, 
            index: pageItemIndexCutoff 
        };
    }
    

    /** Compare the headings of the current item (item) and previous item (prevItem) at a specified depth (depth) */
    private _compareHeading(item: ItemModel, prevItem: ItemModel, depth: number, results: SearchResult[], force: boolean = false) {
        let headingTag = "_heading" + depth;
        let prevHeadingVal = prevItem?.details?.find(d => d.description == headingTag)?.value;
        let currHeadingVal = item.details?.find(d => d.description == headingTag)?.value;

        // Stop recursion when no heading is found at that level
        if (!currHeadingVal) {
            return;
        }

        let headingMatch: boolean = currHeadingVal == prevHeadingVal;

        // Only display heading when it's a new heading (headingMatch == false) or the previous
        // level had a new heading (force == true)
        if (!headingMatch || force) {
            results.push(this._createHeading(headingTag, currHeadingVal, item));
        }

        // Recursive call drilling down to the next heading level
        this._compareHeading(item, prevItem, depth + 1, results, !headingMatch);
    }

    /** Create a SearchResult entry representing a heading for an item by parsing out details from the item containing the specified "_heading" tag */
    private _createHeading(headingTag: string, headingVal: string, item: ItemModel): SearchResult {
        // Determine the prefix for details to be displayed on this heading
        let headingDetailTag = headingTag + '_';
        let headingDetails = item.details?.filter(d => d.description.includes(headingDetailTag));
        let heading = new SearchResult({
            title: headingVal,
            template: 'heading'
        } as SearchResult);

        // Strip all heading tags from the detail descriptions
        heading.details = headingDetails.map(d => new ItemDetailModel({
            description: d.description.replace(headingDetailTag, ''), value: d.value
        } as ItemDetailModel));

        return heading;
    }

    /** Create a SearchResult based on a provided item with all tags that start with the "_heading" prefix removed */
    private _removeTags(item: ItemModel): SearchResult {
        // Exclude all heading details
        let details = item.details?.filter(d => !d.description.match(/_heading/));

        let searchResult = new SearchResult(item as SearchResult);
        searchResult.details = details;

        return searchResult;
    }
}

export class SearchTypeModel extends SearchTypeDto {}
export class SearchFieldModel extends SearchFieldDto {}
export class ItemDetailModel extends ItemDetailDto {}
export class ItemModel extends ItemDto {}
export class ItemFormInputModel extends ItemFormInputDto {}
export class ItemPaymentOptionModel extends ItemPaymentOptionDto {}
export class ItemCustomValueModel extends CartItemCustomValueDto {}
export class PageItemTypeModel extends PageItemTypeDto {}
export class ItemFormInputGroupModel extends ItemFormInputGroupDto {
    fields?: (ItemFormInputDto | ItemCalculatedFieldDto)[];
}

/** Contains item information and details, with added fields for display */
export class SearchResult extends ItemModel {
    /** Determines which template to load in the html: 'default' is regular item list,
     * 'loadMore' is a single "Load More" button, 'heading' renders just a header block with
     * optional details.
     */
    template: string = 'default';
    /**A list of selected payment options, will be greater than one if there are duplicate items for this BSI key */
    selectedPaymentOptions?: ItemPaymentOptionModel[];
    /** An optional list of duplicate items for this BSI key that are in the cart*/
    duplicates?: ItemModel[];

    constructor(data?: SearchResult) {
        super();
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}