// Angular Files
import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { InjectionToken, Injector, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

// Angular Material Files
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';

// Teller Online Core Files
import { 
    TellerOnlineLoadPanelComponent, 
    TellerOnlineNotificationBannerComponent, 
    TellerOnlineRailNavigationComponent 
} from './components';
import { PaymentsApiClient, UsersApiClient } from './api/CoreWebApi';

// Teller Online Shared Files
import { TellerOnlineSharedModule } from 'teller-online-libraries/shared';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');

@NgModule({
    declarations: [
        TellerOnlineLoadPanelComponent,
        TellerOnlineNotificationBannerComponent,
        TellerOnlineRailNavigationComponent
    ],
    imports: [
        CommonModule,
        RouterModule, 
        A11yModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        MatTooltipModule,
        TellerOnlineSharedModule
    ],
    exports: [
        TellerOnlineLoadPanelComponent,
        TellerOnlineNotificationBannerComponent,
        TellerOnlineRailNavigationComponent
    ]
})
export class TellerOnlineCoreModule { 
    constructor(injector: Injector, @Optional() @SkipSelf() parentModule?: TellerOnlineCoreModule) {
        if (parentModule) {
            throw new Error('TellerOnlineCoreModule is already loaded. Import it in the AppModule only');
        }
        // init all the clients to use the correct base url since injection tokens don't work in the apis
        PaymentsApiClient.init(injector.get(API_BASE_URL));
        UsersApiClient.init(injector.get(API_BASE_URL));
    }
}
