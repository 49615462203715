// Angular Files
import {
    Component,
    NgZone,
    OnInit,
    DoCheck,
    HostListener,
} from '@angular/core';
import { Location } from '@angular/common';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

// Angular Material Files
import { MatIconRegistry } from '@angular/material/icon';

// Other Files
import { Subscription } from 'rxjs';

// Payment Integration Files
import {
    BasePaymentProcessorComponent,
    PaymentMethodTypeEnum,
    PaymentProcessorProvider
} from 'apps/public-portal/src/app/payment-integrations/base';
import { DemoService } from 'apps/public-portal/src/app/payment-integrations/demo/service';

// Teller Online Files
import { IFramePaymentRequestDto } from 'apps/public-portal/src/app/core/api/PaymentIntegrationApiClients';
import { AuthService, CartService, InboundRedirectService } from 'apps/public-portal/src/app/core/services';
import { AmericanBankRoutingNumber, AMERICAN_BANK_ROUTING_NUMBERS } from 'apps/public-portal/src/app/shared/constants';

// Teller Online Library Files
import { TellerOnlineAppService, TellerOnlineSiteMetadataService } from 'teller-online-libraries/core';
import {
    TellerOnlineMessageService,
    TellerOnlineValidationService,
    TellerOnlineWindowService
} from 'teller-online-libraries/shared';


@Component({
    selector: 'app-demo',
    templateUrl: './demo.component.html',
    styleUrls: ['./demo.component.scss'],
    host: {
        class: 'demo'
    }
})

export class DemoComponent extends BasePaymentProcessorComponent implements OnInit, DoCheck {
    // Public variables
    public makeDemoPaymentRequest: IFramePaymentRequestDto;
    public bankRoutingNumber: AmericanBankRoutingNumber;
    public isMobilePhone = this.windowService.width <= 768;

    // Private variables
    private _routingNumbers: AmericanBankRoutingNumber[] = AMERICAN_BANK_ROUTING_NUMBERS;
    private _formChangeSubscription: Subscription;

    // #region BasePaymentProcessorComponent property overrides
    public set paymentMethodData(paymentMethodData) {
        if(this._formChangeSubscription) this._formChangeSubscription.unsubscribe();

        super.paymentMethodData = paymentMethodData;

        // dynamically add all of the cc fields to the controls for the form group
        if(paymentMethodData.type == PaymentMethodTypeEnum.CreditCard) {
            this.paymentDetailsForm.addControl(this.CC_FIELDS.ccname, new FormControl(paymentMethodData.billingInfo.fullName));
            this.paymentDetailsForm.addControl(this.CC_FIELDS.ccnumber, new FormControl(''));
            this.paymentDetailsForm.addControl(this.CC_FIELDS.ccexp, new FormControl(''));
            this.paymentDetailsForm.addControl(this.CC_FIELDS.cvv, new FormControl(''));
        }

        if(paymentMethodData) {
            this._formChangeSubscription = this.paymentDetailsForm.valueChanges.subscribe((value) => {
                if(paymentMethodData.type == PaymentMethodTypeEnum.CreditCard) {
                    paymentMethodData.billingInfo.fullName = value[this.CC_FIELDS.ccname];
                    paymentMethodData.cardNumber = value[this.CC_FIELDS.ccnumber];
                    paymentMethodData.cardExpiry = value[this.CC_FIELDS.ccexp];
                    paymentMethodData.CVV = value[this.CC_FIELDS.cvv];
                }

            });
        }

    }
    public get paymentMethodData() {
        return super.paymentMethodData;
    }
    // #endregion

    constructor(
        private demoPaymentService: DemoService,
        private windowService: TellerOnlineWindowService,
        appService: TellerOnlineAppService,
        siteMetadataService: TellerOnlineSiteMetadataService,
        location: Location,
        inboundRedirectService: InboundRedirectService,
        cartService: CartService,
        authService: AuthService,
        messageService: TellerOnlineMessageService,
        validationService: TellerOnlineValidationService,
        ngZone: NgZone,
        paymentProvider: PaymentProcessorProvider,
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer
    ) {
        super(appService, ngZone, location, siteMetadataService, inboundRedirectService, cartService, authService, messageService, validationService, paymentProvider, matIconRegistry, domSanitizer);
        this.makeDemoPaymentRequest = new IFramePaymentRequestDto();
    }

    // This is specific to demo because it's heavily controlled by dev extreme currently
    // other components don't require this as they are controlled via css
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isMobilePhone = this.windowService.width <= 768;
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngDoCheck(): void {
        super.ngDoCheck();
    }

    // #region Event Handlers

    onSubmit_validateAndSubmit = async () => {
        if(!this.validationService.runValidation(this.paymentDetailsForm))
            return false;

        this.appService.triggerPageLoading();

        if(this.forEdit) {
            await this.savePaymentMethod();
        } else {
            await this.payCart();
        }
    };

    //#endregion

    // #region BasePaymentPpocessorComponent implementation

    public override async savePaymentMethod() {
        try {
            var response = await this.demoPaymentService.savePaymentMethod({
                paymentMethodData: this.paymentMethodData,
                paymentMethodId: this.paymentMethodId
            });

            this.paymentMethodId = response.paymentMethodId;

            this.updateUrl();

            this.processingComplete.emit(response.last4);
        } catch(e) {
            this.processingError.emit(e);
        } finally {
            this.finishedDataEntry(true);
            this.appService.finishPageLoading();
        }
    }

    public override async payCart() {
        // Wait for the response.
        await this.cartService.updateCart({
            guestEmailAddress: this.paymentMethodData.billingInfo.email,
            rememberPaymentMethod: this.paymentMethodData.rememberPaymentMethod,
            paymentMethodId: null //unset any previously saved paymentMethodId incase a previous attempt to use a saved method was made
        });

        try {
            var postPaymentResponse = await this.demoPaymentService.payCart({
                cartId: this._cartId,
                paymentMethodData: this.paymentMethodData,
                inboundRedirectSourceId: this.inboundRedirectService.redirectSourceId
            });

            this.processingComplete.emit(postPaymentResponse);
        } catch(e) {
            this.processingError.emit(e);
        } finally {
            this.finishedDataEntry(true);
            this.appService.finishPageLoading();
        }
    }

    // Custom validation rule for ensuring the routing number entered into a field is valid against list of banks/routing numbers
    validateRoutingNumber = (field) => {
        this.bankRoutingNumber = this._routingNumbers.filter(bank => bank.routingNumber == parseInt(field.value))[0];
        return !!this.bankRoutingNumber;
    }

    //#endregion
}
