// Angular Files
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Payment Integration Files
import {
    PaymentMethodData,
    IFramePaymentResponse,
    IFrameSavePaymentMethodResponse,
    PaymentMethodTypeEnumConvertor
} from 'apps/public-portal/src/app/payment-integrations/base';
// For some reason if the PaymentProcessorService was included from just base, the unit tests would fail
import { PaymentProcessorService } from 'apps/public-portal/src/app/payment-integrations/base/interfaces';

// Teller Online Files
import {
    CyberSourceIntegrationApiClient,
    SharedPaymentIntegrationApiClient,
    IFramePaymentRequestDto,
    IFrameSavePaymentMethodRequestDto,
    IFrameSavePaymentMethodResponseDto,
    SessionTokenResponseDto
} from 'apps/public-portal/src/app/core/api/PaymentIntegrationApiClients';

@Injectable({
    providedIn: 'root'
})
export class CyberSourceService extends PaymentProcessorService {
    //#region PaymentProcessorService fields

    // The endpoint used to load Flex Microform
    public flexMicroformUrl = null;

    //#endregion

    constructor(
        private cyberSourceApi: CyberSourceIntegrationApiClient,
        private router: Router,
        sharedPaymentApi: SharedPaymentIntegrationApiClient
    ) {
        super(sharedPaymentApi);
    }

    //#region PaymentProcessorService

    public override async checkout(cartGuid: string) {
        this.router.navigate(['/checkout']);
    }

    public override async payCart(params): Promise<IFramePaymentResponse> {
        let request = new IFramePaymentRequestDto();
        let paymentMethodData: PaymentMethodData = params.paymentMethodData;
        request.cartId = params.cartId;
        request.paymentMethodType = PaymentMethodTypeEnumConvertor.toDto(params.paymentMethodType ?? paymentMethodData.type);
        request.inboundRedirectSourceId = params.inboundRedirectSourceId;

        if (!params.saved) {
            this.mapRequest(request, paymentMethodData, params.paymentToken);
        }

        // Make the sale (Validate cart, complete payment transaction, post to teller).
        let response = await this.sharedPaymentApi.makePayment(request).toPromise();

        return new IFramePaymentResponse({
            emailAddress: response.emailAddress,
            errorMessage: response.responseMessage,
            cartStatus: response.responseSuccess,
            processorTrackingId: response.processorTrackingId
        });
    }

    public override async savePaymentMethod(params): Promise<IFrameSavePaymentMethodResponse> {
        let request = new IFrameSavePaymentMethodRequestDto();
        let paymentMethodData: PaymentMethodData = params.paymentMethodData;

        this.mapRequest(request, paymentMethodData, params.paymentToken);
        let response: IFrameSavePaymentMethodResponseDto;
        if (params.paymentMethodId) {
            response = await this.sharedPaymentApi.updatePaymentMethod(params.paymentMethodId, request).toPromise();
        } else {
            response = await this.sharedPaymentApi.addPaymentMethod(request).toPromise();
        }

        return new IFrameSavePaymentMethodResponse({
            paymentMethodId: response.paymentMethodId,
            last4: request.last4
        });
    }

    //#endregion

    //#region helpers

    public async getSessionToken(): Promise<SessionTokenResponseDto> {
        try {
            return await this.cyberSourceApi.getSessionToken().toPromise();
        } catch (e) {
            this._errorEvent.next('Failed to generate session token');
        }
    }

    //#endregion
}
