// Angular Files
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Teller Online Icon Files
import { TellerOnlineIconRemove } from './components/remove.component';
import { TellerOnlineIconInfo } from './components/info.component';
import { TellerOnlineIconCart }  from './components/cart.component';
import { TellerOnlineIconHome } from './components/home.component';
import { TellerOnlineIconSearch }  from './components/search.component';
import { TellerOnlineIconMenu } from './components/menu.component';
import { TellerOnlineIconUserDashboard } from './components/user-dashboard.component';
import { TellerOnlineIconLocked } from './components/locked.component';
import { TellerOnlineIconPreferences } from './components/preferences.component';
import { TellerOnlineIconSignUp } from './components/sign-up.component';
import { TellerOnlineIconLogout } from './components/logout.component';
import { TellerOnlineIconLogin } from './components/login.component';
import { TellerOnlineIconViewBillDetails } from './components/view-bill-details.component';
import { TellerOnlineIconEnvelopeTracking } from './components/envelope-tracking.component';
import { TellerOnlineIconViewBill } from './components/view-bill.component';
import { TellerOnlineIconDownloadReceipt } from './components/download-receipt.component';
import { TellerOnlineIconCreditCard } from './components/credit-card.component';
import { TellerOnlineIconAddToCart } from './components/add-to-cart.component';
import { TellerOnlineIconSubnavArrow } from './components/subnav-arrow.component';
import { TellerOnlineIconRightArrow } from './components/right-arrow.component';
import { TellerOnlineIconPlus } from './components/plus.component';
import { TellerOnlineIconMinus } from './components/minus.component';
import { TellerOnlineIconExpand } from './components/expand.component';
import { TellerOnlineIconCollapse } from './components/collapse.component';
import { TellerOnlineIconError } from './components/error.component';
import { TellerOnlineIconPaymentComplete } from './components/payment-complete.component';
import { TellerOnlineIconECheck } from './components/echeck.component';
import { TellerOnlineIconClose } from './components/close.component';
import { TellerOnlineIconUser } from './components/user.component';
import { TellerOnlineIconSelectPayment } from './components/select-payment.component';
import { TellerOnlineIconCartEmpty } from './components/cart-empty.component';
import { TellerOnlineIconAttention } from './components/attention.component';
import { TellerOnlineIconMerchantLogos } from './components/merchant-logos.component';
import { TellerOnlineIconHelp } from './components/help.component';
import { TellerOnlineIconCancel } from './components/cancel.component';
import { TellerOnlineIconEmail } from './components/email.component';
import { TellerOnlineIconMarkRefunded } from './components/mark-refunded.component';
import { TellerOnlineIconSms } from './components/sms.component';
import { TellerOnlineIconSyncTeller } from './components/sync-teller.component';
import { TellerOnlineIconsRegistryService } from './registry.service';

@NgModule({
    declarations: [
        TellerOnlineIconMenu, 
        TellerOnlineIconRemove, 
        TellerOnlineIconInfo, 
        TellerOnlineIconCart, 
        TellerOnlineIconHome, 
        TellerOnlineIconSearch, 
        TellerOnlineIconUserDashboard, 
        TellerOnlineIconLocked, 
        TellerOnlineIconPreferences, 
        TellerOnlineIconSignUp, 
        TellerOnlineIconLogout, 
        TellerOnlineIconLogin, 
        TellerOnlineIconViewBillDetails, 
        TellerOnlineIconEnvelopeTracking, 
        TellerOnlineIconViewBill, 
        TellerOnlineIconDownloadReceipt, 
        TellerOnlineIconCreditCard, 
        TellerOnlineIconAddToCart, 
        TellerOnlineIconSubnavArrow, 
        TellerOnlineIconRightArrow, 
        TellerOnlineIconPlus, 
        TellerOnlineIconMinus, 
        TellerOnlineIconExpand, 
        TellerOnlineIconCollapse, 
        TellerOnlineIconError, 
        TellerOnlineIconPaymentComplete, 
        TellerOnlineIconECheck, 
        TellerOnlineIconClose, 
        TellerOnlineIconUser, 
        TellerOnlineIconSelectPayment, 
        TellerOnlineIconCartEmpty, 
        TellerOnlineIconAttention, 
        TellerOnlineIconMerchantLogos, 
        TellerOnlineIconHelp, 
        TellerOnlineIconCancel, 
        TellerOnlineIconEmail, 
        TellerOnlineIconMarkRefunded, 
        TellerOnlineIconSms, 
        TellerOnlineIconSyncTeller],
    imports: [CommonModule],
    providers: [TellerOnlineIconsRegistryService],
    exports: [
        TellerOnlineIconMenu, 
        TellerOnlineIconRemove, 
        TellerOnlineIconInfo, 
        TellerOnlineIconCart, 
        TellerOnlineIconHome, 
        TellerOnlineIconSearch, 
        TellerOnlineIconUserDashboard, 
        TellerOnlineIconLocked, 
        TellerOnlineIconPreferences, 
        TellerOnlineIconSignUp, 
        TellerOnlineIconLogout, 
        TellerOnlineIconLogin, 
        TellerOnlineIconViewBillDetails, 
        TellerOnlineIconEnvelopeTracking, 
        TellerOnlineIconViewBill, 
        TellerOnlineIconDownloadReceipt, 
        TellerOnlineIconCreditCard, 
        TellerOnlineIconAddToCart, 
        TellerOnlineIconSubnavArrow, 
        TellerOnlineIconRightArrow, 
        TellerOnlineIconPlus, 
        TellerOnlineIconMinus, 
        TellerOnlineIconExpand, 
        TellerOnlineIconCollapse, 
        TellerOnlineIconError, 
        TellerOnlineIconPaymentComplete, 
        TellerOnlineIconECheck, 
        TellerOnlineIconClose, 
        TellerOnlineIconUser, 
        TellerOnlineIconSelectPayment, 
        TellerOnlineIconCartEmpty, 
        TellerOnlineIconAttention, 
        TellerOnlineIconMerchantLogos, 
        TellerOnlineIconHelp, 
        TellerOnlineIconCancel, 
        TellerOnlineIconEmail, 
        TellerOnlineIconMarkRefunded, 
        TellerOnlineIconSms, 
        TellerOnlineIconSyncTeller]
})
export class TellerOnlineIconsModule { }