<footer class="layout-grid">
    <div class="footer__container">
        <ng-container *ngIf="!siteMetadata?.customization">
            <div class="footer__block">
                <div class="skeleton-anchor">&nbsp;</div>
                <span class="slash"></span>
            </div>
            <div class="footer__block">
                <div class="skeleton-anchor">&nbsp;</div>
                <span class="slash"></span>
            </div>
        </ng-container>
        <div class="footer__block" *ngFor="let item of siteMetadata.customization.footerLinksContent">
            <a href="{{ item.url }}" target="_blank">{{ item.title }}</a>
            <div class="slash"></div>
        </div>   
    </div>
</footer>