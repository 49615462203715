<form (ngSubmit)="onSubmit_validateAndSubmit()">
    <app-echeck
        *ngIf="paymentMethodData.type == PaymentMethodTypeEnum.ECheck"
        [allowSavePaymentMethod]="allowSavePaymentMethod"
        [formGroup]="paymentDetailsForm"
        [ECHECK_ACCOUNTTYPELIST]="ECHECK_ACCOUNTTYPELIST"
        [ECHECK_ACCOUNTOWNERTYPELIST]="ECHECK_ACCOUNTOWNERTYPELIST"
        [ECHECK_FIELDS]="ECHECK_FIELDS"
        [accountOwnerTypeEnabled]="true"
        [accountTypeEnabled]="true"
        [paymentMethodData]="paymentMethodData">
    </app-echeck>

    <h2>Billing Address</h2>

    <app-billing-info
        [forEdit]="forEdit"
        [billingInfo]="paymentMethodData.billingInfo"
        [formGroup]="paymentDetailsForm">
    </app-billing-info>

    <app-test-trigger
        [shouldDisplay]="true"
        [testTriggerData]="testTriggerData"
        [formGroup]="paymentDetailsForm"
        (testTriggerEvent)="selectTestTrigger($event)">
    </app-test-trigger>

    <!-- TODO (PROD-800) Make this shared code not specific to a single processor -->
    <div class="echeck__confirmation layout-grid" *ngIf="!forEdit && eCheckConfirmationMessage && paymentMethodData.type == PaymentMethodTypeEnum.ECheck">
        <mat-checkbox (change)="onCheck_agreeECheckConfirmation($event)">I Agree</mat-checkbox>
        <div class="echeck__confirmation__preview" ellipsis [ellipsis-content]="shortECheckConfirmationMessage" ellipsis-word-boundaries></div>

        <div class="echeck__confirmation__btn">
            <button mat-button type="button" color="accent" (click)="onClick_showECheckConfirmationMessage()" aria-controls="aria_disclaimer" aria-haspopup="dialog">
                <mat-icon svgIcon="info" class="teller-online-icon"></mat-icon>
                <span>View Full Terms & Conditions</span>
            </button>
        </div>
    </div>

    <div class="echeck__confirmation layout-grid" *ngIf="forEdit && eCheckDisclaimer && paymentMethodData.type == PaymentMethodTypeEnum.ECheck">
        <mat-checkbox (change)="onCheck_agreeECheckDisclaimer($event)">I Agree</mat-checkbox>
        <div class="echeck__confirmation__preview" ellipsis [ellipsis-content]="shortECheckDisclaimer" ellipsis-word-boundaries></div>

        <div class="echeck__confirmation__btn">
            <button mat-button type="button" color="accent" (click)="onClick_showECheckDisclaimer()" aria-controls="aria_disclaimer" aria-haspopup="dialog">
                <mat-icon svgIcon="info" class="teller-online-icon"></mat-icon>
                <span>View Disclaimer</span>
            </button>
        </div>
    </div>

    <button mat-flat-button
            color="accent"
            type="submit"
            [disabled]="(!forEdit && !eCheckMessageConfirmed && paymentMethodData.type == PaymentMethodTypeEnum.ECheck)
            || (forEdit && !eCheckDisclaimerConfirmed && paymentMethodData.type == PaymentMethodTypeEnum.ECheck)">
        {{forEdit ? 'Save' : 'Pay'}}
    </button>
</form>
