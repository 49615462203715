// Angular Files
import { Injectable } from '@angular/core';

// Payment Integration Files
import {
    RedirectPaymentProcessorResponse
} from 'apps/public-portal/src/app/payment-integrations/base';
// For some reason if the PaymentProcessorService was included from just base, the unit tests would fail
import { PaymentProcessorService } from 'apps/public-portal/src/app/payment-integrations/base/interfaces';

// Teller Online Files
import {
    NicIntegrationApiClient,
    SharedPaymentIntegrationApiClient,
    NicVerifyPaymentStatusRequestDto,
    NicVerifyPaymentStatusResponseDto
} from 'apps/public-portal/src/app/core/api/PaymentIntegrationApiClients';

// Teller Online Library Files
import { TellerOnlineAppService } from 'teller-online-libraries/core';
import { TellerOnlineWindowService } from 'teller-online-libraries/shared';

@Injectable({
    providedIn: 'root'
})
export class NicService extends PaymentProcessorService {
    //#region PaymentProcessorService fields

    public redirect = true;
    public systemPaymentIdentifierKey = "ref";

    //#endregion

    private _errorMessages = {
        duplicate: "Your payment was already processed.",
        failure: "There was an issue processing your payment. Your cart has not yet been paid.",
        cancelled: "The transaction has been cancelled. Your cart has not yet been paid."
    }

    constructor(
        private nicApi: NicIntegrationApiClient,
        private appService: TellerOnlineAppService,
        private windowService: TellerOnlineWindowService,
        sharedPaymentApi: SharedPaymentIntegrationApiClient,
    ) {
        super(sharedPaymentApi);
     }

    //#region PaymentProcessorService

    public override async checkout(cartGuid: string) {
        try {
            this.windowService.navigateExternalUrl(await this.nicApi.getRedirectUrl(cartGuid).toPromise());
        } catch(e) {
            this._errorEvent.next('Failed to generate redirect url');
        }
    }

    public override async handlePostbackResponse(params) {
        let verifyPaymentStatusRequest = new NicVerifyPaymentStatusRequestDto();
        verifyPaymentStatusRequest.paymentIdentifier = params[this.systemPaymentIdentifierKey];
        verifyPaymentStatusRequest.token = params.token;
        verifyPaymentStatusRequest.paymentStatus = params.result;

        let response: NicVerifyPaymentStatusResponseDto = await this.nicApi.nicVerifyPaymentStatus(verifyPaymentStatusRequest).toPromise();

        let state: { cartError?: string, emailAddress?: string } = {};
        let redirectUrl = '/';

        // Setup the redirectUrl and state param based on the paymentStatus from NIC
        switch(response.paymentStatus) {
            case 'failure':
                state.cartError = this._errorMessages.failure;
                break;
            case 'cancelled':
                state.cartError = this._errorMessages.cancelled;
                break;
            case 'duplicate':
                state.cartError = this._errorMessages.duplicate;
                break;
            case 'success':
                // The default is to go home, this is the only situation where we don't want to, so override the default
                redirectUrl = '/checkout/success/' + response.cartGuid;
                // The email is used for displaying a "sent email" message
                state.emailAddress = response.emailAddress;
                break;
        }

        this.appService.consoleLog(this, state);

        return new RedirectPaymentProcessorResponse({
            paymentIdentifier: params[this.systemPaymentIdentifierKey],
            cartGuid: response.cartGuid,
            status: response.paymentStatus,
            redirectUrl: redirectUrl,
            navigationExtras: { state: state }
        });
    }

    //#endregion
}
