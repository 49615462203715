// Angular Files
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Material Files
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

// Other External Files
import { NgxMaskModule } from 'ngx-mask';

// Payment Integration Files
import {
    BasePaymentProcessorIntegrationsModule
} from 'apps/public-portal/src/app/payment-integrations/base';

// Component Files
import { CyberSourceComponent } from "./component";

// Teller Online Files
import { DirectivesModule } from "libraries/shared/src/lib/directives/directives.module";
// Teller Online Library Files
import { TellerOnlineIconsModule } from 'teller-online-libraries/icons';

@NgModule({
    imports: [
        CommonModule,
        TellerOnlineIconsModule,
        BasePaymentProcessorIntegrationsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatSelectModule,
        NgxMaskModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule
    ],
    declarations: [CyberSourceComponent],
    exports: [CyberSourceComponent],
})
export class CyberSourceModule { }
