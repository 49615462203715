<form role="form" (ngSubmit)="onSubmit_validateAndSubmit()" [attr.aria-labelledby]="ariaLabelledBy">
    <section [formGroup]="paymentDetailsForm" 
        class="payment-method-details payment-method-details--credit layout-grid" 
        *ngIf="paymentMethodData.type == PaymentMethodTypeEnum.CreditCard">
        <mat-form-field appearance="standard" floatLabel="always" class="card-holder-name">
            <mat-label>{{CC_FIELDS.ccname}}</mat-label>
            <input matInput 
                [formControlName]="CC_FIELDS.ccname" 
                required 
                maxlength="50" 
                placeholder="Name as shown on card">
            <mat-error *ngIf="paymentDetailsForm.controls?.[CC_FIELDS.ccname]?.touched && paymentDetailsForm?.controls?.[CC_FIELDS.ccname]?.invalid">
                {{validationService.getFieldErrorMessage(CC_FIELDS.ccname, paymentDetailsForm.controls[CC_FIELDS.ccname])}}
            </mat-error>
        </mat-form-field>

        <div class="payment-method-details__card-info">
            <mat-form-field appearance="standard" 
                class="celero-iframe-field" 
                [ngClass]="{'celero-iframe-field--loaded': celeroFieldsAvailable}">
                <mat-label>{{CC_FIELDS.ccnumber}} *</mat-label>
                <app-payment-integration-form-field 
                    formControlName="ccnumber" 
                    [id]="'celero-ccnumber'"
                    [loaded]="celeroFieldsAvailable" 
                    field-min-size="15"></app-payment-integration-form-field>
                <mat-error>
                    {{getFieldError('ccnumber')}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard" 
                class="celero-iframe-field" 
                [ngClass]="{'celero-iframe-field--loaded': celeroFieldsAvailable}">
                <mat-label>{{CC_FIELDS.ccexp}} *</mat-label>
                <app-payment-integration-form-field 
                    formControlName="ccexp" 
                    [id]="'celero-ccexp'"
                    [loaded]="celeroFieldsAvailable" 
                    field-min-size="15"></app-payment-integration-form-field>
                <mat-error>
                    {{getFieldError('ccexp')}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard" 
                class="celero-iframe-field" 
                [ngClass]="{'celero-iframe-field--loaded': celeroFieldsAvailable}">
                <mat-label>{{CC_FIELDS.cvv}} *</mat-label>
                <app-payment-integration-form-field 
                    formControlName="cvv" 
                    [id]="'celero-cvv'"
                    [loaded]="celeroFieldsAvailable" 
                    field-min-size="15"></app-payment-integration-form-field>
                <mat-error>
                    {{getFieldError('cvv')}}
                </mat-error>
            </mat-form-field>
        </div>
        
        <div class="cvv-instructions cvv-instructions--short">
            <div class="cvv-instructions__note">
                <teller-online-icon-info></teller-online-icon-info>
                <span>Where do I find the CVV?</span>
            </div>                 
            <img class="cvv-instructions__sample" src="assets/images/CVV.png" alt="Sample credit card image showing where cvv is">
        </div>

        <ng-container *ngIf="allowSavePaymentMethod && paymentDetailsForm?.controls?.rememberPaymentMethod">
            <div class="remember-payment-method" formGroupName="rememberPaymentMethod">
                <mat-checkbox formControlName="checked">Save credit card information</mat-checkbox>
            </div>
        </ng-container>
    </section>
    
    <section [formGroup]="paymentDetailsForm" 
        class="payment-method-details payment-method-details--echeck layout-grid" 
        *ngIf="paymentMethodData.type == PaymentMethodTypeEnum.ECheck">
        <div class="payment-method-details__bank-info">
            <mat-form-field appearance="standard" 
                class="celero-iframe-field" 
                [ngClass]="{'celero-iframe-field--loaded': celeroFieldsAvailable}">
                <mat-label>{{ECHECK_FIELDS.checkname}} *</mat-label>
                <app-payment-integration-form-field 
                    formControlName="checkname" 
                    [id]="'celero-checkname'"
                    [loaded]="celeroFieldsAvailable" 
                    field-min-size="15"></app-payment-integration-form-field>
                <mat-error>
                    {{getFieldError('checkname')}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard" class="account-type" floatLabel="always">
                <mat-label>{{ECHECK_FIELDS.checktype}}</mat-label>
                <mat-select [formControlName]="ECHECK_FIELDS.checktype" required>
                    <mat-option selected value="">-- Select One --</mat-option>
                    <mat-option *ngFor="let accountType of ECHECK_ACCOUNTTYPELIST" [value]="accountType">{{accountType}}</mat-option>
                </mat-select>
                <mat-error *ngIf="paymentDetailsForm.controls?.[ECHECK_FIELDS.checktype]?.touched && paymentDetailsForm?.controls?.[ECHECK_FIELDS.checktype]?.invalid">
                    {{validationService.getFieldErrorMessage(ECHECK_FIELDS.checktype, paymentDetailsForm.controls[ECHECK_FIELDS.checktype])}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard" 
                class="celero-iframe-field routing-number" 
                [ngClass]="{'celero-iframe-field--loaded': celeroFieldsAvailable}">
                <mat-label>{{ECHECK_FIELDS.checkaba}} *</mat-label>
                <mat-icon matPrefix svgIcon="echeck-start"></mat-icon>
                <app-payment-integration-form-field 
                    formControlName="checkaba" 
                    [id]="'celero-checkaba'"
                    [loaded]="celeroFieldsAvailable" 
                    field-min-size="15"></app-payment-integration-form-field>
                <mat-error>
                    {{getFieldError('checkaba')}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard" 
                class="celero-iframe-field account-number" 
                [ngClass]="{'celero-iframe-field--loaded': celeroFieldsAvailable}">
                <mat-label>{{ECHECK_FIELDS.checkaccount}} *</mat-label>
                <mat-icon matPrefix svgIcon="echeck-start"></mat-icon>
                <app-payment-integration-form-field 
                    formControlName="checkaccount" 
                    [id]="'celero-checkaccount'"
                    [loaded]="celeroFieldsAvailable" 
                    field-min-size="15"></app-payment-integration-form-field>
                <mat-icon matSuffix svgIcon="echeck-end"></mat-icon>
                <mat-error>
                    {{getFieldError('checkaccount')}}
                </mat-error>
            </mat-form-field>   
        </div>

        <div class="echeck-instructions">
            <div class="echeck-instructions__note">
                <teller-online-icon-info></teller-online-icon-info>
                <span>Where do I find Routing and Account Numbers?</span>
            </div>

            <img class="echeck-instructions__sample" src="assets/images/echeck_latest.png" alt="Sample check image">
        </div>

        <ng-container *ngIf="allowSavePaymentMethod && paymentDetailsForm?.controls?.rememberPaymentMethod">
            <div class="checkbox remember-payment-method" formGroupName="rememberPaymentMethod">
                <mat-checkbox formControlName="checked">Save e-check information</mat-checkbox>
            </div>
        </ng-container>
    </section>

    <h2>Billing Address</h2>

    <app-billing-info 
        [forEdit]="forEdit"
        [billingInfo]="paymentMethodData.billingInfo"
        [formGroup]="paymentDetailsForm">
    </app-billing-info>

    <button
        mat-flat-button
        color="accent"
        type="submit"
        [disabled]="!celeroFieldsAvailable">
        {{forEdit ? 'Save' : 'Pay'}}
    </button>
</form>
