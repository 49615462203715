// Angular Files
import { NgModule } from '@angular/core';

// Teller Online Component Files
import { ChangePhoneModalModule } from './change-phone-modal/change-phone-modal.component';
import { EmailVerifyModule } from './email-verify/email-verify.component';
import { PhoneVerifyModule } from './phone-verify/phone-verify.component';
import { SelectPaymentModule } from './select-payment/select-payment.module';
import { CodeVerificationModule } from './code-verification/code-verification.component';
import { PasswordCaptionModule } from './password-caption/password-caption.component';
import { SearchResultModule } from './search-result/search-result.component';
import { PasswordChangeModule } from './password-change/password-change.component';

@NgModule({
    imports: [
        PasswordCaptionModule,
        CodeVerificationModule,
        EmailVerifyModule,
        ChangePhoneModalModule,
        PhoneVerifyModule,
        SelectPaymentModule,
        SearchResultModule,
        PasswordChangeModule
    ],
    exports: [
        PasswordCaptionModule,
        CodeVerificationModule,
        EmailVerifyModule,
        ChangePhoneModalModule,
        PhoneVerifyModule,
        SelectPaymentModule,
        SearchResultModule,
        PasswordChangeModule
    ]
})
export class ComponentsModule { }