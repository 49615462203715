// Angular Files
import { DOCUMENT, Location } from '@angular/common';
import { Component, NgZone, OnInit, DoCheck, Inject, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

// Angular Material Files
import { MatIconRegistry } from '@angular/material/icon';

// Other External Files
import { Subscription } from 'rxjs';

// Payment Integration Files
import { BasePaymentProcessorComponent, PaymentIntegrationFieldValue } from 'apps/public-portal/src/app/payment-integrations/base/components';
import { BridgePayService } from 'apps/public-portal/src/app/payment-integrations/bridge-pay/service';
import { PaymentMethodTypeEnum } from 'apps/public-portal/src/app/payment-integrations/base/models';
import { PaymentProcessorProvider } from 'apps/public-portal/src/app/payment-integrations/base';

// Teller Online Files
import {
    AuthService,
    CartService,
    InboundRedirectService
} from 'apps/public-portal/src/app/core/services';

// Teller Online Library Files
import {
    TellerOnlineAppService,
    TellerOnlineErrorHandlerService,
    TellerOnlineSiteMetadataService } from 'teller-online-libraries/core';
import {
    TellerOnlineMessageService,
    TellerOnlineValidationService
} from 'teller-online-libraries/shared';

// Represents the TokenPay object loaded from BridgePay
declare const TokenPay: {
    prototype: { constructor : any }
}

@Component({
    selector: 'app-bridge-pay',
    templateUrl: './bridge-pay.component.html',
    styleUrls: ['./bridge-pay.component.scss'],
    host: {
        class: 'bridge-pay'
    }
})
export class BridgePayComponent extends BasePaymentProcessorComponent implements OnInit, DoCheck, OnDestroy {
    // Public variables
    public bridgePayFieldsAvailable: boolean = false;

    // Private variables
    // Represents the TokenPay object used to create the token
    private Tokenizer: {
        initialize: any,
        createToken: any
    };

    // Constants
    private TOKENPAY_SCRIPT_ID = 'tokenpay';

    // #region BasePaymentProcessorComponent property overrides
    public set paymentMethodData(paymentMethodData) {
        if(this._formChangeSubscription) this._formChangeSubscription.unsubscribe();

        super.paymentMethodData = paymentMethodData;

        // dynamically add all of the cc fields to the controls for the form group
        if(paymentMethodData.type == PaymentMethodTypeEnum.CreditCard) {
            // These are used for tracking error states
            Object.keys(this.CC_FIELDS).filter(f => f != 'ccname').forEach(name => {
                this.paymentDetailsForm.addControl(name, new FormControl(new PaymentIntegrationFieldValue(null, '', name, this.CC_FIELDS[name])));
            });
            // This is the actual element that contains the bridgepay components
            this.paymentDetailsForm.addControl('ccfields', new FormControl(new PaymentIntegrationFieldValue(null, '', 'ccfields', this.CC_FIELDS['ccfields'])));

            this.paymentDetailsForm.addControl(this.CC_FIELDS.ccname, new FormControl(paymentMethodData.billingInfo.fullName));
        }

        if(paymentMethodData) {
            this._formChangeSubscription = this.paymentDetailsForm.valueChanges.subscribe((value) => {
                if(paymentMethodData.type == PaymentMethodTypeEnum.CreditCard)
                    paymentMethodData.billingInfo.fullName = value[this.CC_FIELDS.ccname];

            });
        }
    }
    public get paymentMethodData() {
        return super.paymentMethodData;
    }
    // #endregion

    // Subscriptions
    private _formChangeSubscription: Subscription;

    constructor(
        private bridgePayService: BridgePayService,
        private errorHandlerService: TellerOnlineErrorHandlerService,
        @Inject(DOCUMENT) private document: Document,
        ngZone: NgZone,
        location: Location,
        appService: TellerOnlineAppService,
        siteMetadataService: TellerOnlineSiteMetadataService,
        inboundRedirectService: InboundRedirectService,
        cartService: CartService,
        authService: AuthService,
        messageService: TellerOnlineMessageService,
        validationService: TellerOnlineValidationService,
        paymentProvider: PaymentProcessorProvider,
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer
    ) {
        super(appService, ngZone, location, siteMetadataService, inboundRedirectService, cartService, authService, messageService, validationService, paymentProvider, matIconRegistry, domSanitizer);
        this.loading = true;
    }

    //#region OnInit Implementation

    ngOnInit() {
        super.ngOnInit();

        this.paymentProvider.configLoaded$.subscribe(loaded => {
            if(loaded) this._initializeTokenPay();
        });
    }

    //#endregion

    //#region DoCheck Implementation

    ngDoCheck(): void {
        super.ngDoCheck();
    }

    //#endregion

    //#region OnDestroy Implementation

    ngOnDestroy() {
        super.ngOnDestroy();
        if(this._formChangeSubscription) this._formChangeSubscription.unsubscribe();

        try {
            // Need to override the callback to prevent it from firing when this component is not loaded.
            this.Tokenizer.createToken(null, null);
        } catch {
            // Ignore any resulting errors
        }
    }

    //#endregion


    //#region Event Handlers

    onSubmit_validateAndSubmit = () => {
        this.appService.triggerPageLoading('Validating information...');

        if (this.paymentMethodData.type == PaymentMethodTypeEnum.CreditCard) {
            // Attempt to create the token
            this.Tokenizer.createToken(
                response => {
                    this.ngZone.run(() => {
                        this._submit(response.token);
                    });
                },
                response => {
                    this.ngZone.run(() => {
                        this._handleCreateTokenError(response);
                    });
                });
        } else {
            this._submit(null);
        }
    };

    //#endregion

    //#region BasePaymentProcessorComponent Implementation

    public override async savePaymentMethod() {
        this.appService.triggerPageLoading('Saving information...');

        try {
            var response = await this.bridgePayService.savePaymentMethod({
                paymentMethodData: this.paymentMethodData,
                paymentToken: this._paymentToken,
                paymentMethodId: this.paymentMethodId
            });

            this.paymentMethodId = response.paymentMethodId;

            this.updateUrl();

            this.processingComplete.emit(response.last4);
        } catch(e) {
            this.processingError.emit(e);
        } finally {
            this.finishedDataEntry(true);
            this.appService.finishPageLoading();
        }
    }

    public override async payCart() {
        this.appService.triggerPageLoading('Processing payment...');

        // Wait for the response.
        await this.cartService.updateCart({
            guestEmailAddress: this.paymentMethodData.billingInfo.email,
            rememberPaymentMethod: this.paymentMethodData.rememberPaymentMethod,
            paymentMethodId: null //unset any previously saved paymentMethodId incase a previous attempt to use a saved method was made
        });

        var proceed = await this.cartService.refreshCart(this._cartGuid, this.paymentMethodData.type);

        if(proceed) {
            try {
                var postPaymentResponse = await this.bridgePayService.payCart({
                    cartId: this._cartId,
                    paymentMethodData: this.paymentMethodData,
                    paymentToken: this._paymentToken,
                    inboundRedirectSourceId: this.inboundRedirectService.redirectSourceId
                });

                if(postPaymentResponse.cartStatus) {
                    this.processingComplete.emit(postPaymentResponse);
                } else {
                    // Display the appropriate message for the current payment method type
                    let notChargedMessage;
                    switch(this.paymentMethodData.type) {
                        case PaymentMethodTypeEnum.ECheck:
                            notChargedMessage = "Your account has not been charged.";
                            break;
                        case PaymentMethodTypeEnum.CreditCard:
                        default:
                            notChargedMessage = "Your card has not been charged.";
                            break;
                    }
                    this.messageService.notification("Unable to process payment. " + notChargedMessage + " Reason: " +
                        postPaymentResponse.errorMessage, "error", 5000);
                }

            } catch(e) {
                this.processingError.emit(e);
            } finally {
                this.finishedDataEntry(true);
                this.appService.finishPageLoading();
            }
        } else {
            this.finishedDataEntry(true);
            this.appService.finishPageLoading();
        }
    }

    //#endregion

    //#region helpers

    private _initializeTokenPay() {
        // check if the script has already been created
        if (this.bridgePayService.tokenizationUrl) {
            let tokenPay = this.document.getElementById(this.TOKENPAY_SCRIPT_ID);
            if (!tokenPay) {
                const node = this.document.createElement('script');
                node.id = this.TOKENPAY_SCRIPT_ID;
                node.src = this.bridgePayService.tokenizationUrl;
                node.type = 'text/javascript';
                node.async = false;

                this.document.getElementsByTagName('head')[0].appendChild(node);

                let customStyles = this.document.createElement('style');
                customStyles.id = "customStyles";
                customStyles.hidden = true;
                customStyles.innerText = this._getCustomStyles();

                this.document.getElementsByTagName('head')[0].appendChild(customStyles);
            }

            this._waitForTokenPay();
        }
    }

    /** Every 100ms check if TokenPay is defined, if it is, configure it, otherwise, repeat */
    private _waitForTokenPay() {
        setTimeout(() => {
            if(typeof TokenPay != 'undefined' && this.paymentMethodData.type) {
                this._configureTokenPay();
            } else {
                this._waitForTokenPay();
            }
        }, 100);
    }

    private _configureTokenPay() {
        if (this.paymentMethodData?.type == PaymentMethodTypeEnum.CreditCard) {
            this.Tokenizer = TokenPay.prototype.constructor(this.bridgePayService.publicSecurityKey);
            this.Tokenizer.initialize({
                'dataElement': '#bridge-pay',
                'errorElement': '#bridge-pay-error',
                'useACH': false,
                'useStyles': true,
                'disableZip': true,
                'disableCvv': false
            });

            // Add an error listener for anytime a validation error comes in and there's an error
            // Technically this attempts to submit to BridgePay but the result is discarded on our end
            this.Tokenizer.createToken(null, this.handleTokenPayError);
        }

        this.loading = false;
        this.bridgePayFieldsAvailable = true;
    }

    private async _submit(token) {
        try {
            // Only attempt to pay the cart if the rest of the form validation has passed
            if (this.validationService.runValidation(this.paymentDetailsForm, null, false)) {
                // Token is required if this is credit card
                if (this.paymentMethodData?.type == PaymentMethodTypeEnum.CreditCard && !token) {
                    this.messageService.notification("Unable to process payment. Please refresh the page and try again.", "error", 5000);
                    this.errorHandlerService.handleError("Credit card payment could not be submitted because BridgePay token was not generated.");
                    return;
                } else if (this.paymentMethodData?.type != PaymentMethodTypeEnum.CreditCard) {
                    this._paymentToken = 'dummy';
                } else {
                    this._paymentToken = token;
                }

                if (this.forEdit) {
                    await this.savePaymentMethod();
                } else {
                    await this.payCart();
                }
            }
        } finally {
            this.appService.finishPageLoading();
        }
    }

    /** Handles anytime an error occurs during the TokenPay process
     * (both tabbing between fields and submitting the form) */
    public handleTokenPayError = response => {
        let msg = response.errorMessage + ".";
        // grab all the pseudo fields
        var ccnumberFC = this.paymentDetailsForm.controls['ccnumber'].value;
        var cvvFC = this.paymentDetailsForm.controls['cvv'].value;
        var ccexpFC = this.paymentDetailsForm.controls['ccexp'].value;

        // reset all the errors
        ccnumberFC.error = "";
        cvvFC.error = "";
        ccexpFC.error = "";

        // set the appropriate field to indicate an error
        if (msg?.toLowerCase().includes("card number")) {
            ccnumberFC.error = msg;
        } else if (msg?.toLowerCase().includes("cvc")) {
            cvvFC.error = msg;
        } else if (msg?.toLowerCase().includes("exp")) {
            ccexpFC.error = msg;
        }
        // update all the fields to indicate their error status
        this.paymentDetailsForm.controls['ccnumber'].setValue(ccnumberFC);
        this.paymentDetailsForm.controls['cvv'].setValue(cvvFC);
        this.paymentDetailsForm.controls['ccexp'].setValue(ccexpFC);

        // grab the field that holds the iframe
        var fieldControl = this.paymentDetailsForm.controls['ccfields'].value;
        // set the error for the field
        if(msg) {
            fieldControl.value = null;
            fieldControl.error = msg;
        } else {
            fieldControl.error = '';
        }
        // update the field to indicate the error
        this.paymentDetailsForm.controls['ccfields'].setValue(fieldControl);
    }

    private _handleCreateTokenError(response) {
        this.handleTokenPayError(response);

        let errors = this._generateAdditionalErrors();

        // run validation to display any error messages as a popup
        this.validationService.runValidation(this.paymentDetailsForm, null, false, errors);

        //remove the listeners for success and reset the error listener to default
        this.Tokenizer.createToken(null, this.handleTokenPayError);
        this.appService.finishPageLoading();
    }

    private _generateAdditionalErrors() {
        let additionalErrors: {[key: string]: string} = {};
        if (this.paymentDetailsForm.controls['ccfields'].value.error) additionalErrors.ccfields = this.paymentDetailsForm.controls['ccfields'].value.error;

        return additionalErrors;
    }

    private _getCustomStyles() {
        // TODO: Figure out how to move this into a css file because this is gross!
        return `
        .input-style, #cardmask, #accountmask, #routingmask {
            font-family: Noto Sans,sans-serif;
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            min-height: 2.75em;
            border-bottom: 1px solid rgba(0,0,0,.42);
            padding: 7px 0 6px !important;
        }
        #cardmask, #accountmask, #routingmask {
            margin-bottom: 0 !important;
            margin-top: 20px !important;
            line-height: 24px;
            width: 100%;
        }
        #cardmask:empty, #accountmask:empty, #routingmask:empty {
            border-bottom: none;
        }
        .input-style:hover:not(.invalid-class), .input-style:active:not(.invalid-class) {
            border-bottom-color: rgba(0,0,0,.87);
        }
        .input-style:focus {
            border-bottom-width: 2px;
        }
        /* "Labels", styled to match the positioning of our labels */
        .input-hint, .input-hint:after {
            font-family: Roboto Sans, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: rgba(0,0,0,0.6);
            display: flex;
            align-items: center;
            text-align: left !important;
            transform-origin: left;
        }
        .input-hint {
            border-top: none;
            position: absolute;
            padding: 16px 0 3px;
            top: -20px;
            transform: scale(0.75);
        }
        .input-hint:after {
            padding-top: 2px;
            height: 16px;
            display: inline-block;
            margin-left: 5px;
            content: "*";
            transform: scale(1.4);
        }
        /* Actual label elements -- styling them doesn't work well so we'll leave them hidden */
        .input-wrapper label {
            display: none;
        }

        /* All the input containers */
        .input-wrapper, .sub-wrapper, .ach-sub-wrapper {
            width: 100%;
            margin: 0;
        }
        .sub-wrapper, .form-wrapper, .ach-sub-wrapper {
            flex-direction: column !important;
        }
        /* All the input fields */
        #cardForm .input-wrapper .input-style, #achForm .input-wrapper .input-style {
            margin-bottom: 0;
            margin-top: 20px;
        }

        .input-wrapper {
            margin-bottom: 30px;
        }

        /* The form container */
        .form-wrapper {
            row-gap: 0;
            width: calc(100% + 16px);
            margin: -8px;
        }
        /* Credit Card Number */
        :not(.ach-sub-wrapper) > .input-wrapper.field-lg {
            margin-right: 10px;
            margin-left: 0;
            min-width: 220px;
            flex: 1.5;
        }
        /* Expiry Date and CVV */
        .sub-wrapper {
            flex: 1 1;
            width: 100%;
        }
        /* Expiry Date*/
        .sub-wrapper > .sm-flex {
            flex: 1 1;
            min-width: 75px;
            margin-left: 0;
        }
        /* Expiry month, year */
        .sub-wrapper > .sm-flex .input-style {
            width: calc(100% / 2 - 7px) !important;
            text-align: left;
        }
        .sub-wrapper > .sm-flex span {
            display: inline-block;
            width: 5px;
        }
        #expirationYear {
            padding-left: 15px !important;
        }
        /* CVV */
        .sub-wrapper .xs-flex {
            min-width: 75px;
            margin-right: 0px;
            flex: 0 0;
        }
        .sub-wrapper .xs-flex .input-style {
            text-align: left;
        }
        .invalid-class, #expirationMonth.invalid-class ~ #expirationYear {
            border-bottom-color: #e83434;
            border-width: 2px;
        }
        .invalid-class + .input-hint, .invalid-class + .input-hint:after, #expirationMonth.invalid-class ~.input-hint, #expirationMonth.invalid-class ~.input-hint:after {
            color: #e83434;
        }

        /* Desktop styles, but only if they support container-type query (excludes firefox) */
        @supports(container-type: inline-size) {
            @media screen and (min-width: 420px) {
                .form-wrapper {
                    flex-direction: row !important;
                    margin-top: 20px;
                }
                #cardmask, #accountmask, #routingmask {
                    margin-top: -10px !important;
                    /*width: 100%;*/
                }
                .sub-wrapper > .sm-flex {
                    margin-left: 10px;
                }
                .input-hint {
                    top: -50px;
                }

                /* reapply the default */
                #cardForm .input-wrapper .input-style, #achForm .input-wrapper .input-style {
                    margin-bottom: -20px;
                    margin-top: -20px;
                }
                .input-wrapper {
                    margin: 10px;
                }
                .sub-wrapper {
                    flex-direction: row !important;
                }
            }
        }

        `
    }

    //#endregion
}
