// Angular Files
import { KeyValue, Location } from '@angular/common';
import { NgZone, Input, Output, EventEmitter, OnInit, DoCheck, Directive, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

// Angular Material Files
import { MatIconRegistry } from '@angular/material/icon';

// Other External Files
import { isEqual, cloneDeep } from 'lodash'
import { Subscription } from 'rxjs';

// Payment Integration Files
import {
    PaymentMethodData,
    IFramePaymentResponse,
    PaymentMethodTypeEnum,
    ECheckAccountTypeEnum,
    ECheckAccountOwnerTypeEnum
} from 'apps/public-portal/src/app/payment-integrations/base/models';

// Teller Online Files
import {
    AuthService,
    CartService,
    InboundRedirectService
} from 'apps/public-portal/src/app/core/services';
import { DomSanitizer } from '@angular/platform-browser';
import { StateOrProvince, Country } from 'apps/public-portal/src/app/shared/constants';

// Teller Online Library Files
import { TellerOnlineSiteMetadataService, TellerOnlineAppService } from 'teller-online-libraries/core';
import {
    TellerOnlineMessageService,
    TellerOnlineValidationService
} from 'teller-online-libraries/shared';
import { PaymentProcessorProvider } from 'apps/public-portal/src/app/payment-integrations';

@Directive()
export abstract class BasePaymentProcessorComponent implements OnInit, DoCheck, OnDestroy {
    // Declare @Input variables
    @Input() public allowSavePaymentMethod: boolean = false;
    @Input('aria-labelledby') public ariaLabelledBy: string;
    /** Whether or not this view is being used to edit a method; false if used to make a payment */
    @Input() public forEdit: boolean = false;
    @Input() public paymentMethodId: number = null;
    @Input() public set paymentMethodData(value) {
        this._paymentMethodData = value;

        const countryName = this._paymentMethodData.billingInfo.addressCountry?.name ?? this.siteMetadataService.appConfiguration.defaultCountry;
        this._paymentMethodData.billingInfo.addressCountry = Country.GetByName(countryName);

        if (Country.HasRegionList(this._paymentMethodData.billingInfo.addressCountry?.code)) {
            const stateName = this._paymentMethodData.billingInfo.addressState?.name ?? this.siteMetadataService.appConfiguration.defaultState;
            this._paymentMethodData.billingInfo.addressState = StateOrProvince.GetByName(stateName);
        }

        if (this.authService.isSignedIn) {
            if (!this._paymentMethodData.billingInfo.fullName) {
                this._paymentMethodData.billingInfo.fullName = this.authService.authDetails.name;
            }

            if (!this._paymentMethodData.billingInfo.email) {
                this._paymentMethodData.billingInfo.email = this.authService.authDetails.email;
            }
        } else if (this.inboundRedirectService.isInboundRedirect) {
            let fullName = this.inboundRedirectService.userFullName;
            if (!this._paymentMethodData.billingInfo.fullName && fullName) {
                this._paymentMethodData.billingInfo.fullName = fullName;
            }

            let email = this.inboundRedirectService.userEmail;
            if (!this._paymentMethodData.billingInfo.email && email) {
                this._paymentMethodData.billingInfo.email = email;
            }
        }

        this._setupDefaultObject(value);

        // Add the remember payment method checkbox, billing info will be added in the billingInfoComponent
        this.paymentDetailsForm.addControl('rememberPaymentMethod', new FormGroup({'checked': new FormControl(false)}));
        this._rememberPaymentMethodSubscription = this.paymentDetailsForm.controls.rememberPaymentMethod.valueChanges.subscribe((value) => {
            this._paymentMethodData.rememberPaymentMethod = value.checked;
        });
    };

    get paymentMethodData() {
        return this._paymentMethodData;
    }

    get testTriggerData() {
        if (!this._testTriggerData) {
            let testTriggerConfig = this.paymentProvider.getTestTriggerData(this.paymentMethodData?.type);
            if (testTriggerConfig) {
                this._testTriggerData = Object.keys(testTriggerConfig).map((key) => ({
                    key: key,
                    value: testTriggerConfig[key],
                }));
            }
        }

        return this._testTriggerData;
    }

    protected _paymentMethodData: PaymentMethodData = new PaymentMethodData();
    protected _testTriggerData: {key: string, value: string}[];

    // Declare @Output variables
    @Output() public processingComplete = new EventEmitter<IFramePaymentResponse|string>();
    @Output() public processingError = new EventEmitter<any>();
    @Output() public dataEntryStarted = new EventEmitter<boolean>();

    // Public variables
    public loading: boolean = false;
    public PaymentMethodTypeEnum = PaymentMethodTypeEnum;
    public ECHECK_ACCOUNTTYPELIST: ECheckAccountTypeEnum[] = [
        ECheckAccountTypeEnum.Checking,
        ECheckAccountTypeEnum.Savings
    ]
    public ECHECK_ACCOUNTOWNERTYPELIST: ECheckAccountOwnerTypeEnum[] = [
        ECheckAccountOwnerTypeEnum.Personal,
        ECheckAccountOwnerTypeEnum.Business
    ];
    public paymentDetailsForm: FormGroup = new FormGroup({});

    public eCheckConfirmationMessage: string = this.paymentProvider.defaultConfig?.eCheckConfirmationMessage;
    /** A version of the disclaimer that can be used for the short preview text shown */
    public shortECheckConfirmationMessage: string = this.eCheckConfirmationMessage?.replace(/<[^>]*>/g, '');
    public eCheckMessageConfirmed: boolean = !this.eCheckConfirmationMessage;

    public eCheckDisclaimer: string = this.paymentProvider.defaultConfig?.eCheckDisclaimer;
    /** A version of the disclaimer that can be used for the short preview text shown */
    public shortECheckDisclaimer: string = this.eCheckDisclaimer?.replace(/<[^>]*>/g, '');
    public eCheckDisclaimerConfirmed: boolean = !this.eCheckDisclaimer;

    // Constants
    // Field names and their respective values to be used in messages
    public CC_FIELDS = {
        "ccname": "Card Holder's Name",
        "ccnumber": "Card Number",
        "ccexp": "Valid Till",
        "cvv" : "CVV"
    }

    public ECHECK_FIELDS = {
        "checkname": "Account Holder's Name",
        "checktype": "Account Type",
        "checkowner": "Account Owner Type",
        "checkaba": "Routing Number",
        "checkaccount": "Account Number"
    }

    // Private variables, only used in this file
    private _dataEntryStarted: boolean = false;
    //defaults used for checking if data has been modified
    private _default_paymentMethodData: {
        data: PaymentMethodData,
        starting: string,
        details: string,
        detailsCountry: string
    } = {
        data: new PaymentMethodData(),
        starting: null,
        details: null,
        detailsCountry: null
    };

    // Protected variables - private within any extended components
    protected _paymentToken: string;
    protected _cartId: number;
    protected _cartGuid: string;
    protected _cartVersion: number = 0;
    protected _processingPayment: boolean = false;
    protected _selectedTestTrigger: any = "";

    // Subscriptions
    private _rememberPaymentMethodSubscription: Subscription;

    constructor(
        protected appService: TellerOnlineAppService,
        protected ngZone: NgZone,
        private location: Location,
        private siteMetadataService: TellerOnlineSiteMetadataService,
        public inboundRedirectService: InboundRedirectService,
        public cartService: CartService,
        public authService: AuthService,
        public messageService: TellerOnlineMessageService,
        public validationService: TellerOnlineValidationService,
        public paymentProvider: PaymentProcessorProvider,
        public matIconRegistry?: MatIconRegistry,
        public domSanitizer?: DomSanitizer,
    ) {
        if(matIconRegistry) {
            // url path must be relative to this file
            matIconRegistry.addSvgIcon("echeck-start", domSanitizer.bypassSecurityTrustResourceUrl("../../../../assets/icons/echeck-icon.svg"));
            matIconRegistry.addSvgIcon("echeck-end", domSanitizer.bypassSecurityTrustResourceUrl("../../../../assets/icons/echeck-icon-s.svg"));
        }
    }

    ngOnInit() {
        this.cartService.cart$.subscribe(cart => {
            //If this is an older version of the cart, we don't want it
            if (cart?.version && cart.version < this._cartVersion)
                return;

            // Update the cart version
            this._cartVersion = cart?.version;

            // Ensure the cart has finished loading before doing anything
            if (!this.cartService.loadingCart) {
                this._cartId = cart.cartId;
                this._cartGuid = cart.cartGuid;
            }
        });
    }

    ngDoCheck(): void {
        // If something changed, we need to track that for use in a "are there unsaved changes" detection
        // But we don't want to do it if we're currently processing the payment because a redirect will happen after
        //  and if we throw this error it will be a bad user experience
        if(!this._dataEntryStarted && !this._processingPayment)
        {
            // If our current data is different from our starting values
            // and doesn't match any of the default data, data entry has started
            if(!isEqual(this.paymentMethodData,this._default_paymentMethodData.starting) &&
               !isEqual(this.paymentMethodData,this._default_paymentMethodData.details))
            {
                this.startedDataEntry();
            }
        }
    }

    ngOnDestroy(): void {
        if(this._rememberPaymentMethodSubscription) this._rememberPaymentMethodSubscription.unsubscribe();
    }

    /** For use in an ngFor on an object's fields in order to not sort the fields */
    originalOrder = (a: KeyValue<string,string>, b: KeyValue<string,string>): number => {
        return 0;
    }

    selectTestTrigger(selectedTestTrigger: string) {
        this._selectedTestTrigger = selectedTestTrigger;
    }

    public abstract payCart();

    public abstract savePaymentMethod();

    // This method isn't necessary, but given how long the property is it makes it easier to read
    public getFieldError(fieldName) {
        return this.paymentDetailsForm.controls[fieldName]?.value?.error ?? "";
    }

    protected startedDataEntry() {
        this._dataEntryStarted = true;
        this.dataEntryStarted.emit(true);
    }

    protected finishedDataEntry(processingPayment = false) {
        if(processingPayment) this._processingPayment = true;
        this._dataEntryStarted = false;
        this.dataEntryStarted.emit(false);
    }

    protected updateUrl() {
        let url = `/profile/payment-methods/${this.paymentMethodId}`;
        this.appService.currentUrl = url;
        this.location.replaceState(url);
    }

    onCheck_agreeECheckConfirmation(event) {
        this.eCheckMessageConfirmed = event.checked;
    }

    onCheck_agreeECheckDisclaimer(event) {
        this.eCheckDisclaimerConfirmed = event.checked;
    }

    onClick_showECheckConfirmationMessage() {
        this.messageService.alert(this.eCheckConfirmationMessage, "Terms & Conditions", "cart-disclaimer-modal");
    }

    onClick_showECheckDisclaimer() {
        this.messageService.alert(this.eCheckDisclaimer, "Disclaimer", "cart-disclaimer-modal");
    }

    /** Return the appropriate error message for an integration field field using a lookup and appending
     * appropriate error
     */
    protected getIntegrationFieldErrorMessage(field: string, error: 'required'|'invalid' = 'required') {
        const emptyMessage: string = " is required.";
        const invalidMessage: string = " is invalid.";

        let message = (this.CC_FIELDS[field] ?? this.ECHECK_FIELDS[field]);
        // Ensure we have some sort of name as a fall back, though we should never see this
        if(!message) message = "Field";

        switch(error) {
            case 'required':
                message += emptyMessage;
                break;
            case 'invalid':
                message += invalidMessage;
                break;
            // ensure there is a fallback, though we should never see this
            default:
                message += ' has an error.';
        }
        return message;
    }

    // Setup the default variables used for checking equality when changes are made to form data
    private _setupDefaultObject(paymentMethodData?: PaymentMethodData)
    {
        if(!paymentMethodData) paymentMethodData = this.paymentMethodData;

        // Ensure that the default data is a copy of whatever paymentMethodData is right now
        this._default_paymentMethodData.data = cloneDeep(paymentMethodData);
        // "starting" is considered whatever the value is right now before modification
        this._default_paymentMethodData.starting = cloneDeep(this._default_paymentMethodData.data);

        // Add the default user details if the user is signed in (and we don't have a fullName yet)
        if(this.authService.isSignedIn && !this._default_paymentMethodData.data.billingInfo.fullName) {
            this._default_paymentMethodData.data.billingInfo.fullName = this.authService.authDetails.name;
            this._default_paymentMethodData.data.billingInfo.email = this.authService.authDetails.email;
        }
        this._default_paymentMethodData.details = cloneDeep(this._default_paymentMethodData.data);
    }
}
