// Angular Files
import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, NgForm, NgModel } from '@angular/forms';

// Teller Online Library Files
import { TellerOnlineMessageService } from './message.service';
import { TellerOnlineSiteMetadataService } from 'teller-online-libraries/core';

@Injectable()
export class TellerOnlineValidationService {
    /** Format: a@b.c, allows "+" in the "a" portion */
    public emailRegex: any = '^[-a-zA-Z0-9_.+]+@[-a-zA-Z0-9]+\\.[-a-zA-Z0-9.]+$';
    /** Format: A1B2C3 */
    public postalCodeRegex: any = '^[a-zA-Z][0-9][a-zA-Z][0-9][a-zA-Z][0-9]$';
    /** Mask to be used with ngx mask on an input field, to indicate a north american number format */
    public northAmericaPhoneMask: string = "(000) 000-0000";
    /** Mask to be used with ngx mask on an input field, to indicate a NON north american number format */
    public otherPhoneMask: string = "999999999999999";

    public errorTypes = {
        required: " is required.",
        minlength: " must be at least [#] characters long.",
        maxlength: " cannot exceed [#] characters.",
        min: " must be greater than [#].",
        max: " must be less than [#].",
        pattern: null
    }

    public fieldErrors = {
        'Email': 'Please enter a real email address.',
        'Confirm Password': 'Password and Confirm Password do not match.',
        'Password': 'Password does not meet requirements.',
        'Zip Code': 'Please enter a valid zip code.',
        'Postal Code': 'Please enter a valid postal code.'
    }

    public captchaCode: string;

    /** Special events that will be allowed */
    public specialEventList: any = [
        "ArrowLeft",
        "ArrowRight",
        "Home",
        "End",
        "Backspace",
        "Enter",
        "Tab",
        "Delete",
        "Del",
        "Insert",
        "Ins",
        "PageUp",
        "PageDown",
        "PgUp",
        "PgDn"
    ];

    constructor(
        private messageService: TellerOnlineMessageService,
        private siteMetadataService: TellerOnlineSiteMetadataService
    ) {
        this.captchaCode = this.siteMetadataService.appConfiguration.captchaClientSideKey ?? this.captchaCode
    }

    public runValidation(form: NgForm | FormGroup, captchaValue:boolean = null, quickCheck:boolean = false, additionalErrors: {[key: string]: string} = {}):boolean {
        let success: boolean = true;
        let captchaMessage: string = "Please fill out the reCaptcha to confirm you are human.";
        let captchaError: boolean = captchaValue != null && !captchaValue;
        let errors: string[] = [];

        if(form) {
            if(form instanceof FormGroup) {
                let group = form as FormGroup;
                group.markAllAsTouched();
            }

            this.generatePatternErrors(form, additionalErrors);
            this._retrieveErrors(form.controls, errors, additionalErrors);
        }

        success = errors?.length == 0;
        let additionalErrorCount = additionalErrors ? Object.keys(additionalErrors).length : 0;

        // there's additional errors or the captcha was left blank
        if ((additionalErrors && additionalErrorCount > 0) || (captchaValue != null && !captchaValue)) {
            success = false;
        }

        // Display feedback to the user to do something
        if (!success && !quickCheck) {
            let message = '';
            if(errors?.length < 1 && captchaError) {
                message = captchaMessage;
            } else if(errors?.length < 1 && additionalErrorCount == 1) {
                success = false;
                message = Object.values(additionalErrors)[0];
            } else if(errors?.length == 1 && (!additionalErrors || additionalErrorCount == 0) && !captchaError) {
                message = errors[0];
            } else {
                let totalErrors = errors?.length + (captchaError ? 1 : 0) + (additionalErrorCount ?? 0);

                message = `There were ${totalErrors} errors in your submission. Please review the fields for errors and try again.`
            }
            let notificationDuration = 3000;

            if(message.length > 60) notificationDuration = 6000;
            if(message.length > 120) notificationDuration = 12000;

            this.messageService.notification(message, 'error', notificationDuration);
        }

        return success;
    }

    public generatePatternErrors(form: NgForm | FormGroup, additionalErrors: {[key: string]: string} = {}) {
        for(let control in form.controls) {
            for(let error in form.controls[control].errors) {
                if (error === 'pattern' || error === "mask") {
                    additionalErrors[control] = this.fieldErrors[control];
                }
            }
        }
        return additionalErrors;
    }

    public checkPhoneNumberNorthAmerica(dialCode) {
        let otherCountry = false;

        if (dialCode.startsWith("1") || dialCode === "1") {
            otherCountry = false;
        } else {
            otherCountry = true;
        }
        return otherCountry;
    }

    public getPhoneNumberMask(otherCountry: boolean) {
        return otherCountry ? this.otherPhoneMask : this.northAmericaPhoneMask;
    }

    public getFieldErrorMessage(label, fieldModel: AbstractControl | NgModel, patternError = "Field must match the provided pattern.") {
        let error = "";
        for(let errorType in fieldModel?.errors) {
            error = this._mapErrorTypeToMessage(errorType, label, fieldModel, patternError).error;
        }
        return error;
    }

    /** Check whether key event is an approved special event */
    public isSpecialEventCharacter(event: KeyboardEvent): boolean {
        if (this.specialEventList.includes(event.key) ||
            // Allow: Ctrl+A  1234668
            (event.key === "a" && (event.ctrlKey || event.metaKey)) ||
            // Allow: Ctrl+C
            (event.key === "c" && (event.ctrlKey || event.metaKey)) ||
            // Allow: Ctrl+V
            (event.key === "v" && (event.ctrlKey || event.metaKey)) ||
            // Allow: Ctrl+X
            (event.key === "x" && (event.ctrlKey || event.metaKey)) ||
            // Allow: Ctrl+Z
            (event.key === "z" && (event.ctrlKey || event.metaKey)) ||
            // Allow: Ctrl+Y
            (event.key === "y" && (event.ctrlKey || event.metaKey))) {
            return true;
        }

        return false;
    }

    private _retrieveErrors(controls: {[key: string] : AbstractControl}, errors, additionalErrors: {[key: string] : string} = {})
    {
        for(let key in controls) {
            let control = controls[key];
            // If this element is a group, and not a control, recurse
            if(control instanceof FormGroup) {
                let group = control as FormGroup;
                this._retrieveErrors(group.controls, errors, additionalErrors);
            // if this element is a control, grab it's errors
            } else {
                for(let errorType in control.errors) {
                    let result = this._mapErrorTypeToMessage(errorType, key, control, additionalErrors[key]);
                    errors.push(result.error);
                    if(result.pattern) delete additionalErrors[key];
                }
            }
        }
    }

    private _mapErrorTypeToMessage(errorType: string, label: string, control: AbstractControl | NgModel, patternMessage?: string): {error: string, pattern: boolean} {
        let errorMessage = label + this.errorTypes[errorType];
        let pattern = false;

        if(errorType == "minlength" || errorType == "maxlength") {
            errorMessage = errorMessage.replace("[#]", control.errors[errorType].requiredLength);
        }
        // assume all min/max validation is currency, will need an update if otherwise
        else if (errorType == "max" || errorType == "min") {
            let limit: number;
            if (errorType == "max") limit = control.errors[errorType].max;
            if (errorType == "min") limit = control.errors[errorType].min;

            errorMessage = errorMessage.replace("[#]", "$"+limit.toFixed(2));
        }
        else if(errorType == "pattern" || errorType == "mask") {
            errorMessage = patternMessage;
            pattern = true;
        }
        return {error: errorMessage, pattern: pattern};
    }
}
