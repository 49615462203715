// Angular Files
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

// Other External Files
import { Subscription } from 'rxjs';

// Teller Online Files
import {
    InboundRedirectApiClient,
    InboundRedirectPaymentCompletionRequestDto,
    InboundRedirectPaymentCompletionResponseDto,
    InboundRedirectRequestResponseDto,
    InboundRedirectReturnCodeEnumDto,
    ParameterFormatEnumDto,
    PaymentMethodTypeEnumDto
} from 'apps/public-portal/src/app/core/api/PublicPortalApiClients';
import { CartService } from './cart.service';
import { AuthService } from './auth.service';

// Teller Online Library Files
import { TellerOnlineMessageService, TellerOnlineWindowService } from 'teller-online-libraries/shared';
import {
    TellerOnlineAppService,
    TellerOnlineErrorHandlerService,
    TellerOnlineSiteMetadataService
} from 'teller-online-libraries/core';

@Injectable({
    providedIn: 'root'
})
export class InboundRedirectService implements OnDestroy {
    // Constants
    private LOCAL_STORE_REDIRECT_SOURCE = "inboundRedirectSource";
    private LOCAL_STORE_QUERY_PARAMS = "queryStringParamsFromRedirect";
    private LOCAL_STORE_CONTEXT_UPDATING = "inboundRedirectContextUpdate";
    private LOCAL_STORE_COMMAND = "inboundRedirectCommand";
    private COMMAND_NAVIGATE = "navigate";
    private COMMAND_FINISH = "finish";

    // Public variables
    public userFullName: string;
    public userEmail: string;
    public paymentAmount: number;
    public searchId: string;
    public redirectUrl: string;
    public redirectSource: string;
    public redirectSourceId: number;
    public redirectSourceFriendlyName: string;
    public processorTrackingId: string;
    public paymentMethodType: PaymentMethodTypeEnumDto;
    public showErrorDialog: boolean;
    public redirectInProgress: boolean = false;
    public requestTriggered: boolean = false;

    // Subscriptions
    private _queryParamsSubscription: Subscription;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private inboundRedirectApiClient: InboundRedirectApiClient,
                private windowService: TellerOnlineWindowService,
                private messageService: TellerOnlineMessageService,
                private cartService: CartService,
                private authService: AuthService,
                private siteMetadataService: TellerOnlineSiteMetadataService,
                private appService: TellerOnlineAppService,
                private errorHandlerService: TellerOnlineErrorHandlerService) {
        windowService.addEventListener('storage', this.onWindow_storage);
    }

    ngOnDestroy() {
        if (this._queryParamsSubscription) this._queryParamsSubscription.unsubscribe();
    }

    // A redirect was successfully verified, and the page was switched to the TOL context
    public get isInboundRedirect(): boolean {
        return this.siteMetadataService.tellerOnlineLiteEnabled &&
            !!(this.windowService.getLocalStorageItem(this.LOCAL_STORE_REDIRECT_SOURCE) || this.redirectSource);
    }

    // Redirect verification is in progress
    public get isInboundRedirectContextVerification(): boolean {
        return !!this.windowService.getLocalStorageItem(this.LOCAL_STORE_CONTEXT_UPDATING);
    }

    // Redirect verification is in progress, OR the page was switched to the TOL context.
    // In both cases, we need to show UI as it is in the TOL context
    public get isInboundRedirectUIContext(): boolean {
        return this.isInboundRedirect || this.isInboundRedirectContextVerification || this.windowService.isTOL;
    }

    public async verifyAndSearch(redirectSource: string) {
        if (this.requestTriggered) {
            return;
        }

        try {
            this.requestTriggered = true;
            this.appService.triggerLoading("Verifying request...");

            let queryStringParams = this.route.snapshot.queryParams;
            let queryString = JSON.stringify(queryStringParams);
            this.windowService.setLocalStorageItem(this.LOCAL_STORE_CONTEXT_UPDATING, "true");

            // make sure the user doesn't have any lingering params
            await this.removeRedirectParamsFromLocalStorage();

            if (!redirectSource) {
                await this.showErrorAndRedirectToHome("Redirect source could not be determined.");
            }

            let response = await this.verifyRedirectParameters(redirectSource, queryStringParams);
            if (response?.isValid) {
                // make sure the user is signed out
                await this.authService.signOut();

                // add querystring params to local store for later use
                await this.saveRedirectParams(response, queryString);

                this.windowService.removeLocalStorageItem(this.LOCAL_STORE_CONTEXT_UPDATING);
                // navigate to search page to automatically search based on received searchId
                await this.router.navigate(
                    ['/search/' + response.pageId],
                    {
                        queryParams: {
                            searchType: response.searchTypeName,
                            searchValue: response.searchId,
                            // TODO (PROD-778): This needs to be configurable and optional!
                            additionalCriteriaKey: "TransactionAmount",
                            additionalCriteriaValue: response.paymentAmount,
                            paymentOptionType: response.paymentOptionType
                        },
                        skipLocationChange: true
                    }
                );
            } else {
                this.windowService.removeLocalStorageItem(this.LOCAL_STORE_CONTEXT_UPDATING);
                await this.showErrorAndRedirectToHome(response.userErrorMessage);
            }
        } catch (e) {
            this.windowService.removeLocalStorageItem(this.LOCAL_STORE_CONTEXT_UPDATING);
            this.errorHandlerService.handleError(e, false);
            await this.showErrorAndRedirectToHome();
        }
    }

    public async reinitializeRequest() {
        this.appService.triggerPageLoading();

        let redirectSource = this.windowService.getLocalStorageItem(this.LOCAL_STORE_REDIRECT_SOURCE);
        let queryStringJson = this.windowService.getLocalStorageItem(this.LOCAL_STORE_QUERY_PARAMS);

        const queryStringObj = JSON.parse(queryStringJson);
        const queryParams: Params = {};
        for (const key in queryStringObj) {
            if (queryStringObj.hasOwnProperty(key)) {
                queryParams[key] = queryStringObj[key];
            }
        }

        this.router.navigate(
            ['/TOL/' + redirectSource],
            {queryParams: queryParams}
        );
    }

    public async removeRedirectParamsFromLocalStorage() {
        this.windowService.removeLocalStorageItem(this.LOCAL_STORE_REDIRECT_SOURCE);
        this.windowService.removeLocalStorageItem(this.LOCAL_STORE_QUERY_PARAMS);
    }

    public async promptForRedirectToSource() {
        let result = await this.messageService.prompt(`You are about to be redirected to external website<br/>${this.redirectUrl}<br/><br/>` +
            `Would you like to proceed?`, 'Redirecting to External Website');

        if (result) {
            this.completePaymentAndRedirect();
            this.notify(this.COMMAND_FINISH);
        }
    }

    public async showErrorAndRedirect(additionalDetails: string = undefined) {
        // Indicate redirect is currently in progress to allow us to override default behavior.
        // Set this before cleaning up to ensure proper handling of cart reset
        this.redirectInProgress = true;
        // Clean up first so if the user leaves the page without going back to ACA, the request is still considered complete.
        await this.cleanUpRequest();

        let response = await this.buildResponse(InboundRedirectReturnCodeEnumDto.Error, additionalDetails);

        if (this.showErrorDialog) {
            this.showError(additionalDetails, `<br/><br/>Click \`OK\` to return to ${this.redirectSourceFriendlyName}.`)
                .then(async () => {
                    await this.navigateToRedirectUrl(response);
                });
        } else {
            await this.navigateToRedirectUrl(response);
        }
    }

    public async cancelPaymentAndRedirect() {
        let response = await this.buildResponse(InboundRedirectReturnCodeEnumDto.Cancel, 'Payment was cancelled.');

        await this.navigateToRedirectUrl(response);

        this.notify(this.COMMAND_FINISH);
    }

    public finalizePayment(processorTrackingId: string, successRoute: string) {
        this.processorTrackingId = processorTrackingId;
        this.notify(this.COMMAND_NAVIGATE, successRoute);
    }

    //
    // Event Handlers
    //
    private onWindow_storage = async (e: StorageEvent) => {
        // To maintain TOL context between multiple browser tabs.
        // When a user completes a payment on one tab and is redirected to the receipt page,
        // we want him to be redirected to the receipt page on all the other opened tabs
        if (e.key === this.LOCAL_STORE_COMMAND && e.newValue) {
            const command = JSON.parse(e.newValue);
            switch(command.command) {
                case this.COMMAND_NAVIGATE:
                    this.router.navigate([command.arg]);
                    break;
                case this.COMMAND_FINISH:
                    this.redirectSource = null;
                    this.cleanUpRequest();
                    // When finishing a TOL payment, if the checkout page is opened in multiple tabs,
                    // navigate away to the main page to prevent showing the checkout page with an empty cart
                    if (this.router.url.startsWith("/checkout")) {
                        this.router.navigate(['/']);
                    }
                    break;
            }
            this.windowService.removeLocalStorageItem(this.LOCAL_STORE_COMMAND);
        }
    }

    //
    // Private Methods
    //
    private async completePaymentAndRedirect() {
        let response = await this.buildResponse(InboundRedirectReturnCodeEnumDto.Success, 'Payment was successful.');
        await this.navigateToRedirectUrl(response);
    }

    private async showErrorAndRedirectToHome(additionalDetails: string = undefined) {
        // Clean up first so if the user leaves the page without being redirected
        // back to the source, the request is still considered complete.
        await this.cleanUpRequest();

        let message: string = "";
        if (additionalDetails) {
            message = "<br/><br/>";
        }
        message += "Clicking `OK` will send you to Teller Online where you can search for your fees manually.";

        this.showError(additionalDetails, message)
            .then(async () => {
                // Replacing the url when navigating to the home page
                // to ensure that when a user clicks the browser's back button,
                // they are redirected back to a portal that triggered a redirect
                this.router.navigate(['/'], { replaceUrl: true });
            });
    }

    private async navigateToRedirectUrl(response: InboundRedirectPaymentCompletionResponseDto) {
        // Indicate redirect is currently in progress to allow us to override default behavior.
        this.redirectInProgress = true;

        await this.cleanUpRequest();

        // Show loader so the user can't attempt any action while being redirected
        this.appService.triggerPageLoading("Redirecting...");

        this.notify(this.COMMAND_FINISH);

        if (response) {
            if (response.redirectUrl) {
                switch (response.parameterFormat) {
                    case ParameterFormatEnumDto.None:
                        this.windowService.navigateExternalUrl(response.redirectUrl);
                        break;
                    case ParameterFormatEnumDto.QueryString:
                        this.windowService.navigateExternalUrl(response.redirectUrl + "?" + response.parameterString);
                        break;
                    case ParameterFormatEnumDto.Json:
                    // Support when needed
                    case ParameterFormatEnumDto.Xml:
                    // Support when needed
                    default:
                        throw new Error("Unsupported Parameter Format");
                }
            } else {
                this.router.navigate(['/']);
                // Reset the flag if we stay on TO page
                this.redirectInProgress = false;
            }
        }
    }

    private async cleanUpRequest() {
        await this.removeRedirectParamsFromLocalStorage();
        this.cartService.reset();
    }

    private async saveRedirectParams(response: InboundRedirectRequestResponseDto, queryString: string) {
        if (response) {
            this.userFullName = response.fullName;
            this.userEmail = response.userEmail;
            this.paymentAmount = response.paymentAmount;
            this.searchId = response.searchId;
            this.paymentMethodType = response.paymentMethodType;
            this.showErrorDialog = response.showErrorDialog;
            this.redirectUrl = response.redirectUrl;
            this.redirectSourceFriendlyName = response.friendlyName;
            this.redirectSourceId = response.inboundRedirectSourceId;
            this.redirectSource = response.redirectSource;

            if (response.redirectSource) {
                // Also keep in local store in case we need to reinitialize the request
                this.windowService.setLocalStorageItem(this.LOCAL_STORE_REDIRECT_SOURCE, response.redirectSource);
            }

            if (queryString) {
                // Keep original querystring in local store in case we need to reinitialize the request
                this.windowService.setLocalStorageItem(this.LOCAL_STORE_QUERY_PARAMS, queryString);
            }
        }
    }

    private showError(additionalDetails: string = undefined, buttonActionDescription: string = undefined) {
        let additionalDetailMessage = additionalDetails
            ? "<b>Additional error details:</b><br/>" + additionalDetails
            : "";

        let message = "An error occurred while processing your request.<br/><br/>" + additionalDetailMessage + buttonActionDescription;

        return this.messageService.alert(message, `Error Processing Request`);
    }

    private async verifyRedirectParameters(redirectSource: string, requestData): Promise<InboundRedirectRequestResponseDto> {
        return await this.inboundRedirectApiClient.verifyRedirectParameters(redirectSource, requestData).toPromise();
    }

    private async buildResponse(
        returnCode: InboundRedirectReturnCodeEnumDto,
        customMessage: string
    ): Promise<InboundRedirectPaymentCompletionResponseDto> {
        let requestDto = new InboundRedirectPaymentCompletionRequestDto();

        requestDto.searchId = this.searchId;
        requestDto.redirectSource = this.redirectSource;
        requestDto.returnCode = returnCode;
        requestDto.userMessage = customMessage;
        requestDto.processorTransactionId = this.processorTrackingId;
        requestDto.redirectUrl = this.redirectUrl;

        try {
            return await this.inboundRedirectApiClient.buildResponse(requestDto).toPromise();
        } catch (e) {
            this.errorHandlerService.handleError(e, false);
            await this.showErrorAndRedirectToHome();
        }
    }

    // Send a notification to other tabs
    private notify(command: string, arg?: string) {
        this.windowService.setLocalStorageItem(this.LOCAL_STORE_COMMAND, JSON.stringify({command: command, arg: arg}));
    }
}

