<form (ngSubmit)="onSubmit_validateAndSubmit()">
    <section [formGroup]="paymentDetailsForm"
             class="payment-method-details payment-method-details--credit layout-grid"
             *ngIf="paymentMethodData.type == PaymentMethodTypeEnum.CreditCard">
        <mat-form-field appearance="standard" floatLabel="always" class="card-holder-name">
            <mat-label>{{CC_FIELDS.ccname}}</mat-label>
            <input matInput [formControlName]="CC_FIELDS.ccname" required maxlength="50" placeholder="Name as shown on card">
            <mat-error *ngIf="paymentDetailsForm.controls?.[CC_FIELDS.ccname]?.touched && paymentDetailsForm?.controls?.[CC_FIELDS.ccname]?.invalid">
                {{validationService.getFieldErrorMessage(CC_FIELDS.ccname, paymentDetailsForm.controls[CC_FIELDS.ccname])}}
            </mat-error>
        </mat-form-field>

        <div class="payment-method-details__card-info">
            <mat-form-field appearance="standard"
                            class="datacap-iframe-field"
                            [ngClass]="{
                    'datacap-iframe-field--loaded': datacapFieldsAvailable,
                    'datacap-error--card': paymentDetailsForm?.controls?.ccnumber?.value.error,
                    'datacap-error--cvv': paymentDetailsForm?.controls?.cvv?.value.error,
                    'datacap-error--expiry': paymentDetailsForm?.controls?.ccexp?.value.error
                }">
                <app-payment-integration-form-field formControlName="ccfields"
                                                    [id]="'datacap'"
                                                    [iframe]="true"
                                                    [loaded]="datacapFieldsAvailable"
                                                    field-min-size="25"></app-payment-integration-form-field>
                <mat-error>{{paymentDetailsForm?.controls?.ccfields?.value.error}}</mat-error>
            </mat-form-field>
        </div>

        <div class="cvv-instructions">
            <div class="cvv-instructions__note">
                <teller-online-icon-info></teller-online-icon-info>
                <span>Where do I find the CVV?</span>
            </div>
            <img class="cvv-instructions__sample" src="assets/images/CVV.png" alt="Sample credit card image showing where cvv is">
        </div>

        <ng-container *ngIf="allowSavePaymentMethod && paymentDetailsForm?.controls?.rememberPaymentMethod">
            <div class="remember-payment-method" formGroupName="rememberPaymentMethod">
                <mat-checkbox formControlName="checked">Save credit card information</mat-checkbox>
            </div>
        </ng-container>
    </section>

    <app-echeck *ngIf="paymentMethodData.type == PaymentMethodTypeEnum.ECheck"
                [allowSavePaymentMethod]="allowSavePaymentMethod"
                [formGroup]="paymentDetailsForm"
                [ECHECK_ACCOUNTTYPELIST]="ECHECK_ACCOUNTTYPELIST"
                [ECHECK_ACCOUNTOWNERTYPELIST]="ECHECK_ACCOUNTOWNERTYPELIST"
                [ECHECK_FIELDS]="ECHECK_FIELDS"
                [accountOwnerTypeEnabled]="true"
                [accountTypeEnabled]="true"
                [paymentMethodData]="paymentMethodData">
    </app-echeck>

    <h2>Billing Address</h2>

    <app-billing-info [forEdit]="forEdit"
                      [billingInfo]="paymentMethodData.billingInfo"
                      [formGroup]="paymentDetailsForm">
    </app-billing-info>

    <!-- TODO (PROD-800) Make this shared code not specific to a single processor -->
    <div class="echeck__confirmation layout-grid" *ngIf="!forEdit && eCheckConfirmationMessage && paymentMethodData.type == PaymentMethodTypeEnum.ECheck">
        <mat-checkbox (change)="onCheck_agreeECheckConfirmation($event)">I Agree</mat-checkbox>
        <div class="echeck__confirmation__preview" ellipsis [ellipsis-content]="shortECheckConfirmationMessage" ellipsis-word-boundaries></div>

        <div class="echeck__confirmation__btn">
            <button mat-button type="button" color="accent" (click)="onClick_showECheckConfirmationMessage()" aria-controls="aria_disclaimer" aria-haspopup="dialog">
                <mat-icon svgIcon="info" class="teller-online-icon"></mat-icon>
                <span>View Full Terms & Conditions</span>
            </button>
        </div>
    </div>

    <div class="echeck__confirmation layout-grid" *ngIf="forEdit && eCheckDisclaimer && paymentMethodData.type == PaymentMethodTypeEnum.ECheck">
        <mat-checkbox (change)="onCheck_agreeECheckDisclaimer($event)">I Agree</mat-checkbox>
        <div class="echeck__confirmation__preview" ellipsis [ellipsis-content]="shortECheckDisclaimer" ellipsis-word-boundaries></div>

        <div class="echeck__confirmation__btn">
            <button mat-button type="button" color="accent" (click)="onClick_showECheckDisclaimer()" aria-controls="aria_disclaimer" aria-haspopup="dialog">
                <mat-icon svgIcon="info" class="teller-online-icon"></mat-icon>
                <span>View Disclaimer</span>
            </button>
        </div>
    </div>

    <button mat-flat-button
            color="accent"
            type="submit"
            [disabled]="!datacapFieldsAvailable || (!forEdit && !eCheckMessageConfirmed && paymentMethodData.type == PaymentMethodTypeEnum.ECheck)
            || (forEdit && !eCheckDisclaimerConfirmed && paymentMethodData.type == PaymentMethodTypeEnum.ECheck)">
        {{forEdit ? 'Save' : 'Pay'}}
    </button>
</form>
