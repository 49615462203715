// Angular Files
import { CommonModule } from '@angular/common';
import { Component, NgModule, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

// Angular Material Files
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select'

// Other External Files
import { NgxMaskModule } from 'ngx-mask';
import { Subscription } from 'rxjs';

// Payment Integration Files
import { BillingDetails } from 'apps/public-portal/src/app/payment-integrations/base/models';

// Teller Online Files
import { StateOrProvince, Country, COUNTRIES } from 'apps/public-portal/src/app/shared/constants';

// Teller Online Library Files
import { TellerOnlineIconsModule } from "teller-online-libraries/icons";
import { TellerOnlineAppService } from 'teller-online-libraries/core';
import { TellerOnlineValidationService } from 'teller-online-libraries/shared';

@Component({
    selector: 'app-billing-info',
    templateUrl: './billing-info.component.html',
    styleUrls: ['./billing-info.component.scss'],
    host: {
        class: 'billing-info'
    }
})
export class BillingInfoComponent implements AfterViewInit {
    // Declare @Input variables
    @Input() public set billingInfo(value: BillingDetails) {
        this._billingInfo = value;
        this.cdr.detectChanges();
    }
    public get billingInfo() {
        return this._billingInfo;
    }
    private _billingInfo: BillingDetails;

    @Input() public requiredFields: BillingInfoFields[] = [];
    @Input() public forEdit: boolean = false;

    @Input() public set formGroup(value: FormGroup) {
        if(this._formChangeSubscription) this._formChangeSubscription.unsubscribe();

        this._formGroup = value;

        if(!this._billingInfo) {
            if(this.appService.debug) throw "Billing info binding must be loaded before formGroup binding";
        }

        this.stateOrProvinceList = StateOrProvince.GetByCountryCode(this.billingInfo.addressCountry?.code);

        // Add the billing info fields to the form, defaulted to the billing info values
        this._formGroup.addControl(this.BILLING_INFO_FIELDS.email, new FormControl(this.billingInfo.email));
        this._formGroup.addControl(this.BILLING_INFO_FIELDS.phone, new FormControl(this.billingInfo.phone));
        this._formGroup.addControl(this.BILLING_INFO_FIELDS.addressLine1, new FormControl(this.billingInfo.addressLine1));
        this._formGroup.addControl(this.BILLING_INFO_FIELDS.addressLine2, new FormControl(this.billingInfo.addressLine2));
        this._formGroup.addControl(this.BILLING_INFO_FIELDS.addressCity, new FormControl(this.billingInfo.addressCity));
        this._formGroup.addControl(this.BILLING_INFO_FIELDS.addressState, new FormControl(this.billingInfo.addressState));
        this._formGroup.addControl(this.BILLING_INFO_FIELDS.addressRegion, new FormControl(this.billingInfo.addressRegion));
        this._formGroup.addControl(this.BILLING_INFO_FIELDS.addressCountry, new FormControl(this.billingInfo.addressCountry));
        this._formGroup.addControl(this.BILLING_INFO_FIELDS.addressZip, new FormControl(this.billingInfo.addressZip));

        // Anytime one of the form controls changes, update the billing info
        this._formChangeSubscription = this._formGroup.valueChanges.subscribe((value) => {
            this.billingInfo.email = value[this.BILLING_INFO_FIELDS.email];
            this.billingInfo.phone = value[this.BILLING_INFO_FIELDS.phone];
            this.billingInfo.addressCity = value[this.BILLING_INFO_FIELDS.addressCity];
            this.billingInfo.addressLine1 = value[this.BILLING_INFO_FIELDS.addressLine1];
            this.billingInfo.addressLine2 = value[this.BILLING_INFO_FIELDS.addressLine2];
            this.billingInfo.addressZip = value[this.BILLING_INFO_FIELDS.addressZip];
            this.billingInfo.addressRegion = value[this.BILLING_INFO_FIELDS.addressRegion];

            // Unset the state if the country was changed
            if (this.billingInfo.addressCountry != value[this.BILLING_INFO_FIELDS.addressCountry]) {
                this.billingInfo.addressCountry = value[this.BILLING_INFO_FIELDS.addressCountry];

                // Toggle validation rules on fields that are switching visibility
                if (Country.HasRegionList(this.billingInfo.addressCountry?.code)) {
                    // Country has a list of state/provinces so enable the validator for the state dropdown field
                    if (this.requiredFields.includes(BillingInfoFields.addressState)) {
                        this._formGroup.controls[this.BILLING_INFO_FIELDS.addressState].setValidators(Validators.required);
                    }

                    // Country has a list of state/provinces so disable the validator for the region field since it will be hidden.
                    if (this.requiredFields.includes(BillingInfoFields.addressRegion)) {
                        this._formGroup.controls[this.BILLING_INFO_FIELDS.addressRegion].clearValidators();
                    }
                } else {
                    // Country does not have a list of state/provinces so disable the validator for the state dropdown since it will be hidden.
                    if (this.requiredFields.includes(BillingInfoFields.addressState)) {
                        this._formGroup.controls[this.BILLING_INFO_FIELDS.addressState].clearValidators();
                    }

                    // Country does not have a list of state/provinces so enable the validator for the region field.
                    if (this.requiredFields.includes(BillingInfoFields.addressRegion)) {
                        this._formGroup.controls[this.BILLING_INFO_FIELDS.addressRegion].setValidators(Validators.required);
                    }
                }

                // Force the form fields to update. This will trigger the subscription event which will update the backing fields.
                this._formGroup.controls[this.BILLING_INFO_FIELDS.addressState].setValue(undefined);
                this._formGroup.controls[this.BILLING_INFO_FIELDS.addressRegion].setValue(undefined);
            } else {
                this.billingInfo.addressState = value[this.BILLING_INFO_FIELDS.addressState];
                this.billingInfo.addressRegion = value[this.BILLING_INFO_FIELDS.addressRegion];
            }

            this.stateOrProvinceList = StateOrProvince.GetByCountryCode(this.billingInfo.addressCountry?.code);
        });

        this.cdr.detectChanges();
    }
    public get formGroup() {
        return this._formGroup;
    }
    private _formGroup: FormGroup;

    // Declare @Output variables

    // Public variables
    public countryList: Country[] = COUNTRIES;
    public stateOrProvinceList: StateOrProvince[];
    public BILLING_INFO_FIELDS = {
        "email": "Email",
        "phone": "Phone Number",
        "addressCity": "City",
        "addressLine1": "Address Line 1",
        "addressLine2": "Address Line 2",
        "addressState": "State",
        "addressRegion": "Region",
        "addressCountry": "Country",
        "addressZip": "Zip Code"
    }
    public BillingInfoFields = BillingInfoFields;

    public get stateLabel() {
        return this.billingInfo.addressCountry?.code == 'US'
            ? 'State'
            : this.billingInfo.addressCountry?.code == 'CA'
                ? 'Province'
                : 'Region';
    }

    public get zipLabel() {
        return this.billingInfo.addressCountry?.code == 'US'
            ? 'Zip Code'
            : 'Postal Code';
    }

    public get zipMask() {
        return this.billingInfo.addressCountry?.code == 'US'
            ? "00000"
            : this.billingInfo.addressCountry?.code == 'CA'
                ? 'S0S 0S0'
                : '';
    }
    public get zipInputMode() {
        return this.billingInfo.addressCountry?.code == 'US' ? 'numeric' : '';
    }

    public get phoneMask() {
        return this.validationService.getPhoneNumberMask(!
            (this.billingInfo.addressCountry?.code === 'US' || this.billingInfo.addressCountry?.code === 'CA'));
    }

    // Private variables

    // Subscriptions
    private _formChangeSubscription: Subscription;

    constructor(
        public validationService: TellerOnlineValidationService,
        private appService: TellerOnlineAppService,
        private cdr: ChangeDetectorRef
    ) {
    }

    ngAfterViewInit() {
    }

    public countryHasRegionList(countryCode: string) {
        return Country.HasRegionList(countryCode);
    }

}
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        TellerOnlineIconsModule,
        NgxMaskModule
    ],
    declarations: [ BillingInfoComponent ],
    exports: [ BillingInfoComponent ]
})
export class BillingInfoModule { }

/** List of required fields that can be customized --
 * any not in this list (e.g. email and zip) always are required
 * The values of these must map to the BILLING_INFO_FIELDS above*/
export enum BillingInfoFields {
    phone = "phone",
    addressCity = "addressCity",
    addressState = "addressState",
    addressRegion = "addressRegion",
    addressCountry = "addressCountry",
    addressLine1 = "addressLine1",
    addressLine2 = "addressLine2"
}
