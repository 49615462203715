<form role="form" (ngSubmit)="onSubmit_validateAndSubmit()" [attr.aria-labelledby]="ariaLabelledBy">
    <section [formGroup]="paymentDetailsForm" class="payment-method-details payment-method-details--credit layout-grid" *ngIf="paymentMethodData.type == PaymentMethodTypeEnum.CreditCard">
        <mat-form-field class="card-holder-name" appearance="standard" floatLabel="always">
            <mat-label>{{CC_FIELDS.ccname}}</mat-label>
            <input matInput 
                [formControlName]="CC_FIELDS.ccname"
                placeholder="Name as shown on card" 
                required>
            <mat-error>{{validationService.getFieldErrorMessage(CC_FIELDS.ccname, paymentDetailsForm?.controls?.[CC_FIELDS.ccname])}}</mat-error>
        </mat-form-field>

        <div class="payment-method-details__card-info">
            <mat-form-field appearance="standard" floatLabel="always">
                <mat-label>{{CC_FIELDS.ccnumber}}</mat-label>
                <input matInput 
                    mask="0000 0000 0000 0000 999" 
                    placeholder="0000 0000 0000 0000" 
                    inputmode="numeric"
                    [formControlName]="CC_FIELDS.ccnumber"
                    required>
                <mat-error>{{ validationService.getFieldErrorMessage(CC_FIELDS.ccnumber, paymentDetailsForm?.controls?.[CC_FIELDS.ccnumber]) }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard" floatLabel="always">
                <mat-label>{{CC_FIELDS.ccexp}}</mat-label>
                <input matInput 
                    mask="00/00" 
                    placeholder="MM/YY" 
                    inputmode="numeric"
                    [formControlName]="CC_FIELDS.ccexp" 
                    required>
                <mat-error>{{ validationService.getFieldErrorMessage(CC_FIELDS.ccexp, paymentDetailsForm?.controls?.[CC_FIELDS.ccexp]) }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard" floatLabel="always">
                <mat-label>{{CC_FIELDS.cvv}}</mat-label>
                <input matInput 
                    mask="000" 
                    placeholder="***" 
                    inputmode="numeric"
                    [formControlName]="CC_FIELDS.cvv" 
                    required>
                <mat-error>{{ validationService.getFieldErrorMessage(CC_FIELDS.cvv, paymentDetailsForm?.controls?.[CC_FIELDS.cvv]) }}</mat-error>
            </mat-form-field>
        </div>
        

        <div class="cvv-instructions">
            <div class="cvv-instructions__note">
                <teller-online-icon-info></teller-online-icon-info>
                <span>Where do I find the CVV?</span>
            </div>
            <img class="cvv-instructions__sample" src="assets/images/CVV.png" alt="Sample credit card image showing where cvv is">
        </div>

        <ng-container *ngIf="allowSavePaymentMethod && paymentDetailsForm?.controls?.rememberPaymentMethod">
            <div class="remember-payment-method" formGroupName="rememberPaymentMethod">
                <mat-checkbox formControlName="checked">Save credit card information</mat-checkbox>
            </div>
        </ng-container>
    </section>

    <app-echeck
        *ngIf="paymentMethodData.type == PaymentMethodTypeEnum.ECheck"
        [allowSavePaymentMethod]="allowSavePaymentMethod"
        [formGroup]="paymentDetailsForm"
        [ECHECK_ACCOUNTTYPELIST]="ECHECK_ACCOUNTTYPELIST"
        [ECHECK_ACCOUNTOWNERTYPELIST]="ECHECK_ACCOUNTOWNERTYPELIST"
        [ECHECK_FIELDS]="ECHECK_FIELDS"
        [paymentMethodData]="paymentMethodData">
    </app-echeck>

    <h2>Billing Address</h2>

    <app-billing-info
        [forEdit]="forEdit"
        [billingInfo]="paymentMethodData.billingInfo"
        [formGroup]="paymentDetailsForm">
    </app-billing-info>

    <button
        mat-flat-button
        color="accent"
        type="submit">
        {{forEdit ? 'Save' : 'Pay'}}
    </button>
</form>
