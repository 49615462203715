// Angular Files
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, NgModule} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Material Files
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';

// Other Files
import { NgxMaskModule } from 'ngx-mask';
import { Subscription } from 'rxjs';

// Payment Integration Files

// Teller Online Files
import { ECheckAccountOwnerTypeEnum, ECheckAccountTypeEnum, PaymentMethodData } from 'apps/public-portal/src/app/payment-integrations';

// Teller Online Library Files
import { TellerOnlineIconsModule } from 'teller-online-libraries/icons';
import { TellerOnlineAppService } from 'teller-online-libraries/core';
import { TellerOnlineValidationService } from 'teller-online-libraries/shared';

@Component({
    selector: 'app-echeck',
    templateUrl: './echeck.component.html',
    styleUrls: ['./echeck.component.scss'],
    host: {
        class: 'echeck payment-method-details payment-method-details--echeck'
    }
})
export class ECheckComponent {

    @Input() public allowSavePaymentMethod: boolean;
    @Input() public accountOwnerTypeEnabled: boolean = false;
    @Input() public accountTypeEnabled: boolean = true;

    // constants, passed from base processor component to here
    @Input() public ECHECK_ACCOUNTTYPELIST: ECheckAccountTypeEnum[]
    @Input() public ECHECK_ACCOUNTOWNERTYPELIST: ECheckAccountOwnerTypeEnum[];
    @Input() public ECHECK_FIELDS: {
        checkname: string,
        checkaba: string,
        checkaccount: string,
        checktype: string,
        checkowner: string
    };

    // Declare @Input variables
    @Input() public set paymentMethodData(value) {
        this._paymentMethodData = value;

        if(!this.ECHECK_FIELDS) {
            if(this.appService.debug) throw "ECHECK_FIELDS binding must be loaded before paymentMethodData binding";
        }

        // Add the echeck fields to the form, default name to the name from the parent component
        this._formGroup.addControl(this.ECHECK_FIELDS.checkname, new FormControl(value.billingInfo.fullName));
        this._formGroup.addControl(this.ECHECK_FIELDS.checkaccount, new FormControl(''));
        this._formGroup.addControl(this.ECHECK_FIELDS.checkaba, new FormControl(''));
        if (this.accountOwnerTypeEnabled) {
            this.formGroup.addControl(this.ECHECK_FIELDS.checkowner, new FormControl(''));
        }
        if(this.accountTypeEnabled) {
            this._formGroup.addControl(this.ECHECK_FIELDS.checktype, new FormControl(''));
        }

        this._formChangeSubscription = this._formGroup.valueChanges.subscribe(value => {
            this.paymentMethodData.echeckAccountNumber = value[this.ECHECK_FIELDS.checkaccount];
            this.paymentMethodData.echeckRoutingNumber = value[this.ECHECK_FIELDS.checkaba];
            this.paymentMethodData.billingInfo.fullName = value[this.ECHECK_FIELDS.checkname];
            if (this.accountOwnerTypeEnabled) {
                this.paymentMethodData.echeckAccountOwnerType = value[this.ECHECK_FIELDS.checkowner];
            }
            if(this.accountTypeEnabled) {
                this.paymentMethodData.echeckAccountType = value[this.ECHECK_FIELDS.checktype];
            }
        });

        this.cdr.detectChanges();
    }

    public get paymentMethodData() {
        return this._paymentMethodData;
    }

    private _paymentMethodData: PaymentMethodData;


    @Input() public set formGroup(value: FormGroup) {
        if (this._formChangeSubscription) this._formChangeSubscription.unsubscribe();

        this._formGroup = value;
    }
    public get formGroup() {
        return this._formGroup;
    }
    private _formGroup: FormGroup;

    private _formChangeSubscription: Subscription;

    constructor (
        public validationService: TellerOnlineValidationService,
        private cdr: ChangeDetectorRef,
        private appService: TellerOnlineAppService,

    ) { }

}
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatIconModule,
        NgxMaskModule,
        TellerOnlineIconsModule
    ],
    declarations: [ ECheckComponent ],
    exports: [ ECheckComponent ]
})
export class ECheckModule { }
