// Angular Files
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

// Other External Files

// Teller Online Files
import { IErrorDto, PaymentIntegrationEnumDto } from 'apps/public-portal/src/app/core/api/PublicPortalApiClients';

// Teller Online Library Files
import { TellerOnlineSiteMetadataService } from 'teller-online-libraries/core';

// Payment Integration Files
import { PaymentProcessorService } from 'apps/public-portal/src/app/payment-integrations/base/interfaces';
import { PaymentProcessorConfig } from 'apps/public-portal/src/app/payment-integrations/base/models';
import { ConvenienceFeeResponseDto, SharedPaymentIntegrationApiClient } from 'apps/public-portal/src/app/core/api/PaymentIntegrationApiClients';
import {
    PaymentMethodTypeEnum,
    PaymentMethodTypeEnumConvertor,
    PaymentMethodData
} from 'apps/public-portal/src/app/payment-integrations/base/models';
import { DatacapService } from 'apps/public-portal/src/app/payment-integrations/datacap';
import { NicService } from 'apps/public-portal/src/app/payment-integrations/nic';
import { DemoService } from 'apps/public-portal/src/app/payment-integrations/demo';
import { CeleroService } from 'apps/public-portal/src/app/payment-integrations/celero';
import { BridgePayService } from 'apps/public-portal/src/app/payment-integrations/bridge-pay';
import { ElavonService } from 'apps/public-portal/src/app/payment-integrations/elavon';
import { PointAndPayService } from 'apps/public-portal/src/app/payment-integrations/point-and-pay';
import { CyberSourceService } from 'apps/public-portal/src/app/payment-integrations/cyber-source';
import { WellsFargoService } from 'apps/public-portal/src/app/payment-integrations/wells-fargo';

@Injectable({
    providedIn: 'root'
})
export class PaymentProcessorProvider {
    public defaultConfig: PaymentProcessorConfig;

    public configOverrides: { [key: string]: PaymentProcessorConfig } = {};

    /** If the configuration was loaded correctly with valid configuration. */
    public validConfig: boolean = true;

    public activeCreditProcessor: PaymentProcessorService;
    public activeECheckProcessor: PaymentProcessorService;
    public creditProcessorName: PaymentIntegrationEnumDto;
    public eCheckProcessorName: PaymentIntegrationEnumDto;

    protected _configLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public configLoaded$: Observable<boolean> = this._configLoaded.asObservable();

    constructor(
        private siteMetadataService: TellerOnlineSiteMetadataService,
        private sharedPaymentApi: SharedPaymentIntegrationApiClient,
        public nicService: NicService,
        public demoPaymentService: DemoService,
        public celeroService: CeleroService,
        public bridgePayService: BridgePayService,
        public elavonService: ElavonService,
        public pointAndPayService: PointAndPayService,
        public cyberSourceService: CyberSourceService,
        public datacapService: DatacapService,
        public wellsFargoService: WellsFargoService
    ) {
        this.creditProcessorName = this.siteMetadataService.appConfiguration.creditProcessor;
        this.eCheckProcessorName = this.siteMetadataService.appConfiguration.eCheckProcessor;

        // Resolve which processor is being used for each
        this.activeCreditProcessor = this._determinePaymentProcessor(this.creditProcessorName);
        this.activeECheckProcessor = this._determinePaymentProcessor(this.eCheckProcessorName);

        this.setPaymentConfig();
    }

    /**
     * Get parameters that are specific to a payment integration by name and payment method type.
     */
    public getProcessorSpecificConfigParam(paramName: string, paymentMethodType: PaymentMethodTypeEnum) {

        switch (paymentMethodType) {
            case PaymentMethodTypeEnum.CreditCard:
                return this.defaultConfig.creditAdditionalData?.[paramName];
            case PaymentMethodTypeEnum.ECheck:
                return this.defaultConfig.eCheckAdditionalData?.[paramName];
        }
    }

    /**
     * Assign properties to the configuration.
     */
    public setPaymentConfig() {
        this.defaultConfig = new PaymentProcessorConfig();

        this.sharedPaymentApi.getConfig().toPromise()
            .then(response => {
                // Assign all top level (processor agnostic) config properties
                this.defaultConfig.setupConfig(response.defaultConfig);

                this.configOverrides = {};
                response.configOverrides.forEach(o => {
                    let configOverride = new PaymentProcessorConfig();
                    configOverride.setupConfig(o)
                    this.configOverrides[o.configKey] = configOverride;
                });

                // TODO (PROD-290): Address the multiple config problem here
                // Assign processor specific config properties (these should also be defined in the service itself).
                // `assignAdditionalProps` can be overridden in the derived services if necessary.
                this.activeCreditProcessor.assignAdditionalProps(this.defaultConfig.creditAdditionalData, PaymentMethodTypeEnum.CreditCard);
                this.activeECheckProcessor.assignAdditionalProps(this.defaultConfig.eCheckAdditionalData, PaymentMethodTypeEnum.ECheck);

                this.activeCreditProcessor.supportsTokenization = response.defaultConfig.creditConfig.supportsTokenization;
                this.activeECheckProcessor.supportsTokenization = response.defaultConfig.eCheckConfig.supportsTokenization;

                this._configLoaded.next(true);
            })
            .catch(error => this.handleConfigError(error));
    }

    public useConvenienceFee(paymentMethodType: PaymentMethodTypeEnum) {
        this.defaultConfig.useConvenienceFee(paymentMethodType);
        Object.values(this.configOverrides).forEach(o => o.useConvenienceFee(paymentMethodType));
    }

    /** Get all of the configurations in one object. */
    public get configs() {
        let configs = {};
        configs["default"] = this.defaultConfig;
        
        Object.keys(this.configOverrides).forEach(key => configs[key] = this.configOverrides[key]);

        return configs;
    }

    get canSavePaymentMethod(): boolean {
        return (
            // TODO (PROD-276): Re-evaluate if we can no longer assume Credit & E-Check use the same processor.
            (this.defaultConfig.isCreditEnabled && this.activeCreditProcessor.supportsTokenization) ||
            (this.defaultConfig.isECheckEnabled && this.activeECheckProcessor.supportsTokenization)
        );
    }

    /**
     * Provides the appropriate processor based on payment method type.
     * @param paymentMethodType Payment type of the target processor.
     * @returns The approprirate active processor.
     */
    public getProcessorFromMethodType(paymentMethodType: PaymentMethodTypeEnum): PaymentProcessorService {
        switch (paymentMethodType) {
            case PaymentMethodTypeEnum.CreditCard:
                return this.activeCreditProcessor;
            case PaymentMethodTypeEnum.ECheck:
                return this.activeECheckProcessor
        }
    }

    /**
     * Get the name of the active processor based on the payment method.
     * @param paymentMethod Payment method to be used.
     * @returns Name of the active processor.
     */
    public getActiveProcessorName(paymentMethod: PaymentMethodData) {
        switch (paymentMethod.type) {
            case PaymentMethodTypeEnum.CreditCard:
                return this.creditProcessorName;
            case PaymentMethodTypeEnum.ECheck:
                return this.eCheckProcessorName;
        }
    }

    public getConvenienceFeeLabel(paymentMethodType?: PaymentMethodTypeEnum, configKey?: string) {
        // Grab the correct configuration, presence of configKey signifies an override.
        const config = configKey ? this.configOverrides[configKey] : this.defaultConfig;

        let label;

        // Grab tender specific label
        switch (paymentMethodType) {
            case PaymentMethodTypeEnum.CreditCard:
                label = config.creditConvenienceFee.label;
                break;
            case PaymentMethodTypeEnum.ECheck:
                label = config.eCheckConvenienceFee.label;
                break;
            default:
                break;
        }

        // Default label is always "Convenience Fee"
        return label ?? "Convenience Fee";
    }

    public getTestTriggerData(paymentMethodType: PaymentMethodTypeEnum) {
        switch (paymentMethodType) {
            case PaymentMethodTypeEnum.CreditCard:
                return this.defaultConfig.creditTestTriggerData;
            case PaymentMethodTypeEnum.ECheck:
                return this.defaultConfig.eCheckTestTriggerData;
        }
    }

    /**
     * Calculate the conveninence fee.
     * @param cartGuid Identitifier for the target cart.
     * @param paymentMethodType Optionally specify payment method type. If not specified,
     *                          use the type set from `processorConfig.useConvenienceFee().`
     * @returns
     */
    public async calculateConvenienceFee(cartGuid: string, paymentMethodType?: PaymentMethodTypeEnum): Promise<ConvenienceFeeResponseDto> {
        paymentMethodType ??= this.defaultConfig.usingConvenienceFeeType ?? PaymentMethodTypeEnum.Undetermined;

        return await this.sharedPaymentApi.calculateConvenienceFee(cartGuid, PaymentMethodTypeEnumConvertor.toDto(paymentMethodType)).toPromise();
    }

    /**
     * Resolve which payment processor service is to be used and return it.
     * @param processor Enum valued with the paymentIntegration to be used
     * @returns The corresponding processor service
     */
    private _determinePaymentProcessor(processor: PaymentIntegrationEnumDto): PaymentProcessorService {
        switch (processor) {
            case PaymentIntegrationEnumDto.Datacap:
                return this.datacapService;
            case PaymentIntegrationEnumDto.Nic:
                return this.nicService;
            case PaymentIntegrationEnumDto.Demo:
                return this.demoPaymentService;
            case PaymentIntegrationEnumDto.Celero:
                return this.celeroService;
            case PaymentIntegrationEnumDto.BridgePay:
                return this.bridgePayService;
            case PaymentIntegrationEnumDto.Elavon:
                return this.elavonService;
            case PaymentIntegrationEnumDto.PointAndPay:
                return this.pointAndPayService;
            case PaymentIntegrationEnumDto.CyberSource:
                return this.cyberSourceService;
            case PaymentIntegrationEnumDto.WellsFargo:
                return this.wellsFargoService;
            default:
                // TODO: How big of an issue is it if we don't have an active processor? Should we break the app (throw an error, etc)?
                console.error("No active payment processor.");
                break;
        }
    }

    /**
     * To be used to generate an error callback for subscription for `getConfig`.
     * Note: This needs to be invoked, and the function returned is the callback.
     * @returns Error callback with reference to the payment service (`this`) via closure.
     */
    private handleConfigError(error: IErrorDto) {
        this.validConfig = false; // This flag disables checkout.

        if (error?.errorDef == "ConfigurationRequired") {
            error.errorMessage = "An internal error occurred. Checkout has been disabled.";
        }

        throw error;
    }
}
