<mat-card class="payment-method" [attr.aria-label]="paymentMethod.paymentMethodType + ' ending in ' + paymentMethod.last4Digits" role="region">
    <mat-card-content>
        <div class="payment-method__credit" *ngIf="paymentMethod.paymentMethodType === 'CreditCard'">
            <span class="payment-method__default" *ngIf="isDefault && !readOnly">
                <span>Default</span>
            </span>


            <mat-icon mat-list-icon 
                [svgIcon]="paymentMethodsService.getPaymentIcon(paymentMethod)" 
                class="teller-online-icon payment-method__credit__card-type" 
                [ngClass]="[
                    paymentMethodsService.getCardClass(paymentMethod.cardType), 
                    paymentMethodsService.getPaymentIcon(paymentMethod) == 'credit-card' ? 'payment-method__credit__card-type--default' : ''
                ]">
            </mat-icon>

            <div class="payment-method__credit__number">
                ****
                {{paymentMethod.last4Digits}}
            </div>

            <div class="payment-method__credit__name">
                {{paymentMethod.fullName}}
            </div>

            <div class="payment-method__credit__expiry">
                {{paymentMethod.cardExpiry | parseExpiry}}
            </div>
        </div>

        <div class="payment-method__echeck" *ngIf="paymentMethod.paymentMethodType === 'ECheck'">
            
            <span class="payment-method__default" *ngIf="isDefault">
                <span>Default</span>
            </span>

            <div class="payment-method__echeck__title">
                <h3>E-Check</h3>
                <h4>{{paymentMethod.fullName}}</h4>
            </div>

            <div class="payment-method__echeck__fields">
                <div class="payment-method__echeck__field payment-method__echeck__field--routing">
                    <span>
                        Routing&nbsp;number
                    </span>

                    <span>
                        {{paymentMethod.eCheckRoutingNumber}}
                    </span>
                </div>

                <div class="payment-method__echeck__field payment-method__echeck__field--account">
                    <span>
                        Account&nbsp;number
                    </span>

                    <span>
                        *****{{paymentMethod.last4Digits}}
                    </span>
                </div>
            </div>
        </div>
        <div class="payment-method__billing">
            <h3>
                Billing address
            </h3>

            <div class="payment-method__billing__details">
                <span>
                    {{paymentMethod.addressLine1}}<br
                        *ngIf="paymentMethod.addressLine1 && paymentMethod.addressLine2">{{paymentMethod.addressLine2}}
                </span>

                <span>
                    {{paymentMethod.addressCity}}<span
                        *ngIf="paymentMethod.addressCity && (paymentMethod.addressState || paymentMethod.addressCountry)">,</span>
                    {{paymentMethod.addressState}}<span
                        *ngIf="paymentMethod.addressState && paymentMethod.addressCountry">,</span>
                    {{paymentMethod.addressCountry}}
                </span>

                <span>
                    {{paymentMethod.addressZip}}
                </span>

                <span class="payment-method__billing__phone">
                    {{paymentMethod.phone}}
                </span>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions *ngIf="!readOnly">
        <a href="#" [routerLink]="['/profile/payment-methods/'+paymentMethod.paymentMethodId]"
            mat-button 
            color="accent"
            *ngIf="emailIsVerified">
            <span>Edit</span>

            <mat-icon svgIcon="select-payment" class="teller-online-icon"></mat-icon>
        </a>

        <button type="button"
            *ngIf="!isDefault && emailIsVerified"
            mat-button
            (click)="onChange_setDefault(paymentMethod)">
            Make Default
        </button>

        <button type="button" 
            *ngIf="emailIsVerified"
            mat-button
            class="delete-button"
            (click)="onClick_removeMethod($event, paymentMethod)" >
            <span>Remove</span>
            <mat-icon svgIcon="remove" class="teller-online-icon"></mat-icon>
        </button>
    </mat-card-actions>
</mat-card>