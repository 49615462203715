<ng-container *ngIf="item.template != 'loadMore'">
    <mat-toolbar class="search-result__title" [matTooltip]="item.title.length > 100 ? item.title : ''">
        <h2>{{item.title}}</h2>
    </mat-toolbar>

    <mat-list class="search-result__details" role="list" [attr.aria-label]="item.title + ' details'">
        <mat-list-item *ngFor="let detail of item.details" class="search-result__detail">
            <!-- 2 lines if narrow -->
            <span class="label" mat-line *ngIf="appService.isPhone">{{detail.description}}</span>
            <span mat-line *ngIf="appService.isPhone">{{detail.value}}</span>

            <!-- 1 line if wide -->
            <div mat-line *ngIf="!appService.isPhone" class="search-result__single-line">
                <span class="label">{{detail.description}}</span>
                <span>{{detail.value}}</span>
            </div>
        </mat-list-item>
    </mat-list>
    <ng-container *ngIf="item.template != 'heading'">
        <ng-container *ngIf="item.amountInCart && (item.selectedPaymentOption || item.selectedPaymentOptions?.length)">
            <div class="search-result__change-payment-option" [ngClass]="{'search-result__change-payment-option--invalid': !itemHasSinglePaymentOptionSelected}">
                <span class="search-result__change-payment-option__name">
                    Payment Total {{itemHasSinglePaymentOptionSelected ? '(' + item.selectedPaymentOption?.label + ')' : ''}}
                </span>

                <div class="search-result__change-payment-option__section">
                    <span *ngIf="itemHasSinglePaymentOptionSelected" class="search-result__change-payment-option__amount">{{item.selectedPaymentOption.amount | currency: "USD"}}</span>

                    <button type="button" mat-flat-button color="accent" class="search-result__change-payment-option__choose"
                        *ngIf="item.selectedPaymentOptions?.length > 1"               
                        aria-controls="aria_cart"
                        (click)="!checkPaymentOptionNeedsPrompt(item?.paymentOptions) ? on_addToCart([item, item?.paymentOptions[0]]) : openSelectPayment()">
                        <mat-icon svgIcon="select-payment" class="teller-online-icon"></mat-icon>
                        <i aria-hidden="true" [matMenuTriggerFor]="selectpayment"></i>
                        <span>Choose Payment Option</span>
                    </button>

                    <button type="button" mat-button color="accent" class="search-result__change-payment-option__change"
                        *ngIf="itemHasSinglePaymentOptionSelected && checkPaymentOptionNeedsPrompt(item.paymentOptions)"
                        (click)="!checkPaymentOptionNeedsPrompt(item?.paymentOptions) ? on_addToCart([item, item?.paymentOptions[0]]) : openSelectPayment()">
                        <i aria-hidden="true" [matMenuTriggerFor]="selectpayment"></i>
                        <span>Change</span>
                    </button>
                </div>
            </div>                
        </ng-container>

        <button mat-button 
            color="accent"
            type="button" 
            class="search-result__remove-btn"
            *ngIf="item.amountInCart" 
            (click)="onClick_removeFromCart(item)"
            aria-controls="aria_cart">
            <mat-icon svgIcon="remove" class="teller-online-icon"></mat-icon>
            <span>Remove From Cart</span>
        </button>

        <button mat-flat-button 
            color="accent" 
            type="button" 
            class="search-result__add-btn"
            *ngIf="!item.amountInCart"
            [disabled]="!itemIsPayable"
            [matTooltip]="!itemIsPayable ? 'Negative amounts cannot be paid online.' : ''"
            matTooltipPosition="after"
            (click)="!checkPaymentOptionNeedsPrompt(item?.paymentOptions) ? on_addToCart([item, item?.paymentOptions[0]]) : openSelectPayment()"
            aria-controls="aria_cart">
            <mat-icon svgIcon="add-to-cart" class="teller-online-icon"></mat-icon>
            <i aria-hidden="true" [matMenuTriggerFor]="selectpayment"></i>
            
            <span>Add to Cart</span>
        </button>

        <mat-menu class="search-result__select-payment" #selectpayment="matMenu">
            <app-select-payment 
                *ngIf="appService.isWide && selectPaymentMenuOpen"
                [item]="item" 
                (closePopup)="trigger?.closeMenu()" 
                [conflictingOptions]="conflictingOptions ?? (item.selectedPaymentOptions?.length > 1 ? item.selectedPaymentOptions : null)" 
                (addToCart)="on_addToCart($event)" 
                (updateCartItem)="on_updateCartItem($event)">
            </app-select-payment>
        </mat-menu>
    </ng-container>
</ng-container>

<ng-container *ngIf="item.template === 'loadMore'">
    <button class="search-result__load-more" mat-flat-button type="button" color="accent" (click)="onClick_loadMore()">
        Show More Results
    </button>
</ng-container>