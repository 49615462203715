// Angular Files
import { Injectable } from '@angular/core';

// Angular Material Files
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

// Teller Online Library Files
import { TellerOnlineNotificationComponent, TellerOnlineDialogComponent } from '../components';

@Injectable()
export class TellerOnlineMessageService {
    constructor(
        private snackbar: MatSnackBar,
        private dialog: MatDialog
    ) {}

    /**
     * /** Display a temporary notification message near the top of the screen. 
     * Will automaticlaly disappear after a set amount of time.
     * If the displayTime is > 3s, a dismiss button will be made available to manually dismiss the notification sooner.
     * @param message - the message to be displayed
     * @param type - what type of message this is - controls color and icon that is displayed, options are 'error', 'success', or 'info'
     * @param displayTime - the amount of time before the notification is auto-dismissed
     * @param position - the position relative to the screen (top, center, bottom, left, center, right)
     */
    public notification(message: string, type?: 'error'|'success'|'info'|'attention', displayTime?: number, position: any = { horizontal: 'center', vertical: 'top' }) {
        let options: MatSnackBarConfig = {
            horizontalPosition: position.horizontal,
            verticalPosition: position.vertical,
            duration: displayTime,
            data: null
        }
        options.data = {type: type, message: message};
        if(displayTime > 3000)
            options.data.hasDismiss = true;
        this.snackbar.openFromComponent(TellerOnlineNotificationComponent, options);
    }

    /**
     * A popup dialog that needs to be addressed before it will go away. 
     * Similar to the browser built in "confirm" dialog, 
     * prompts the user for "yes" or "no", closing in any other way is considered no.
     * @param html - the body of the dialog (exclusive of buttons), can be simple text or complex html
     * @param title - the title of the dialog
     * @returns a Promise that resolves to true if the "yes" button was clicked, false otherwise
     */
    public prompt(html: string, title: string) {
        return this.custom({
            content: html,
            title: title,
            actions: [
                new TellerOnlineDialogAction({
                    text: "Yes",
                    close: true
                }),
                new TellerOnlineDialogAction({
                    text: "No",
                    close: false
                }),
            ]
        });
    }

    /**
     * A popup dialog that needs to be acknowledged before it will go away.
     * Similar to the browser built in "alert" dialog, 
     * clicking okay, or closing it any other way, is treated the same
     * @param html - the body of the dialog (exclusive of buttons), can be simple text or complex html
     * @param title - the title of the dialog
     * @param dialogClass - An optional class to apply to the alert dialog for custom styling
     * @returns A promise that resolves to false once the dialog has been closed
     */
    public async alert(html: string, title: string, dialogClass?: string) {
        return this.custom({
            content: html,
            title: title,
            actions: [
                new TellerOnlineDialogAction({
                    text: "OK",
                    close: false
                })
            ]
        }, dialogClass);
    }
    
    /**
     * A custom dialog that can contain any number of buttons (including 0), a title, and a body.
     * @param options - the options used to generate the dialog, including title, body (content), and buttons (actions)
     * @param dialogClass - An optional class to apply to the dialog for styling
     * @returns A promise that resolves when the dialog has been closed. Value is dependent on the actions (false, unless an action specifies close: true)
     */
    public custom(options: TellerOnlineDialogOptions, dialogClass?: string): Promise<any> {
        let dialog = this.dialog.open(TellerOnlineDialogComponent, {data: options, panelClass: dialogClass, ariaLabelledBy: "aria_dialog_title"});

        return dialog.afterClosed().toPromise();
    }
}

export class TellerOnlineDialogOptions {
    content: string;
    title: string;
    actions?: TellerOnlineDialogAction[];
    form?: TellerOnlineDialogForm;
}

export class TellerOnlineDialogAction {
    text: string;
    /** The Angular Material color to apply */
    color?: "accent"|"warn";
    /** Determines if this button will be "flat" style with a background (true) or regular style with background only on hover (false), defaults to background only on hover */
    solidBackground?: boolean = false;
    /** close == true means this dialog will return true upon closing, 
     * close == false means it will return false, 
     * not passing close means this does not close the dialog.
     * Either close or click must be defined. */
    close?: boolean = null;
    /** An action to perform when clicking this button, other than closing it.
     * Either close or click must be defined.*/
    click?: Function;

    constructor(data?: TellerOnlineDialogAction) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}

class TellerOnlineDialogForm {
    fields: TellerOnlineDialogFormField[];
}

class TellerOnlineDialogFormField {
    type: string;
    label: string;
    name: string;
    required?: boolean = false;
    placeholder?: string;
}