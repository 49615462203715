// Angular Files
import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

// Other External Files
import { Observable, Subject } from 'rxjs';

// Teller Online Files
import { CoreModule } from 'apps/public-portal/src/app/core/core.module';
import {
    SignInRequestDto,
    AuthApiClient,
    SignInResponseDto,
    AuthDetailsRequestDto,
    AuthDetailsResponseDto,
    InitiateSignUpRequestDto,
    ConfirmSignUpRequestDto,
    ResendEmailConfirmationRequestDto,
    ConfirmPasswordResetRequestDto,
    InitiatePasswordResetRequestDto,
    ConfirmCodeRequestTypeEnumDto,
    ConfirmEmailCodeDto,
    ConfirmPhoneCodeRequestDto,
    ChangeUserPasswordDto
} from 'apps/public-portal/src/app/core/api/TellerOnlineIdentityApiClients';
import { UserService } from "apps/public-portal/src/app/core/services/user.service";
import { CartService } from 'apps/public-portal/src/app/core/services/cart.service';

// Teller Online Library Files
import { 
    TellerOnlineHistoryService, 
    TellerOnlineNotificationBannerService, 
    TellerOnlineSiteMetadataService, 
    TellerOnlineXsrfService 
} from 'teller-online-libraries/core';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    authDetails: AuthDetailsResponseDto;
    authDetailsChange: Subject<AuthDetailsResponseDto> = new Subject<AuthDetailsResponseDto>();

    constructor(
        private router: Router,
        private authApiClient: AuthApiClient,
        private siteMetadata: TellerOnlineSiteMetadataService,
        private xsrfService: TellerOnlineXsrfService,
        private historyService: TellerOnlineHistoryService,
        private userService: UserService,
        private cartService: CartService,
        private notificationBannerService: TellerOnlineNotificationBannerService
    ) {}

    public get isSignedIn() {
        return this.siteMetadata.authenticationEnabled && this.authDetails && this.authDetails.isSignedIn;
    }

    public async signIn(signInRequest: SignInRequestDto): Promise<SignInResponseDto> {
        const response = await this.authApiClient.signIn(signInRequest).toPromise();

        if (response.succeeded) {
            await this._setupSignedInUser();
        }

        return response;
    }

    public async signOut(route?: string, extras?: NavigationExtras) {
        // Refresh the token to make sure we are allowed to sign the user out.
        await this.xsrfService.loadXsrfToken();

        await this.authApiClient.signOut().toPromise();

        // remove all notifications that were tied to this sign in
        this.notificationBannerService.dismissAuthenticationBanners();

        sessionStorage.removeItem('sessionExpiryTime');
        localStorage.removeItem('xsrfToken');

        await this.xsrfService.loadXsrfToken();

        this.authDetails = null;
        this.authDetailsChange.next(this.authDetails);

        this.userService.clearUserProfile();
        this.cartService.reset();

        if (route) {
            this.historyService.signedOut = true;
            await this.router.navigate([route], extras);
        }
    }

    public async confirmCode(confirmationCode: string, requestType: ConfirmCodeRequestTypeEnumDto,
        identityProviderUserId: string, signUpToken: string, password: string): Promise<any> {
        let request;

        switch (requestType) {
            case ConfirmCodeRequestTypeEnumDto.SignUp:
                request = new ConfirmSignUpRequestDto();
                request.confirmationCode = confirmationCode;
                request.identityProviderUserId = identityProviderUserId;
                request.signUpToken = signUpToken;
                request.password = password;

                await this.authApiClient.confirmSignUp(request).toPromise();

                // Signing up logs the user in, so update aspects of the site to reflect this
                this._setupSignedInUser();

                break;
            case ConfirmCodeRequestTypeEnumDto.Email:
            case ConfirmCodeRequestTypeEnumDto.ForgotPassword:
                request = new ConfirmEmailCodeDto();
                request.confirmationCode = confirmationCode;
                await this.authApiClient.confirmEmailCode(request).toPromise();
                break;
            case ConfirmCodeRequestTypeEnumDto.Phone:
                request = new ConfirmPhoneCodeRequestDto();
                request.confirmationCode = confirmationCode;
                await this.authApiClient.confirmPhoneCode(request).toPromise();
                break;
        }
    }

    public async initiateSignUp(signUpRequest: InitiateSignUpRequestDto): Promise<string> {
        const response = await this.authApiClient.initiateSignUp(signUpRequest).toPromise();

        if (response) {
            // Get a new XSRF token, as it changes based on the identity.
            await this.xsrfService.loadXsrfToken();
            await this.refreshAuthDetails();
        }

        return response.identityProviderId;
    }

    public async initiatePasswordReset(resetPasswordRequest: InitiatePasswordResetRequestDto): Promise<void> {
        await this.authApiClient.initiatePasswordReset(resetPasswordRequest).toPromise();
    }

    public async confirmPasswordReset(confirmPasswordReset: ConfirmPasswordResetRequestDto): Promise<void> {
        await this.authApiClient.confirmPasswordReset(confirmPasswordReset).toPromise();

        // Resetting password logs the user in, so update aspects of the site to reflect this
        await this._setupSignedInUser();
    }

    public async resendEmailCode(resendEmailCode: ResendEmailConfirmationRequestDto): Promise<void> {
        await this.authApiClient.resendEmailConfirmationCode(resendEmailCode).toPromise();
    }

    public async resendPhoneCode(): Promise<void> {
        await this.authApiClient.resendPhoneConfirmationCode().toPromise();
    }

    public async changePassword(changePasswordRequest: ChangeUserPasswordDto): Promise<any> {
        const response = await this.authApiClient.changeUserPassword(changePasswordRequest).toPromise();

        // refresh all user details
        await this._setupSignedInUser()
    }

    public getAuthDetails(): Observable<AuthDetailsResponseDto> {
        var request = new AuthDetailsRequestDto({refreshAuthToken: true});

        return this.authApiClient.getAuthDetails(request);
    }

    public async refreshAuthDetails() {
        var authDetails = await this.getAuthDetails().toPromise();
        await this.updateAuthDetails(authDetails);
    }

    public async updateAuthDetails(authDetails: AuthDetailsResponseDto) {
        this.authDetails = authDetails;
        this.authDetailsChange.next(this.authDetails);

        // Put this in sessionStorage so that multiple Teller Online tabs stay synchronized.
        sessionStorage.sessionExpiryTime = this.authDetails.sessionExpiry;
    }

    public async checkSessionExpiration() {
        // Check again in 30 seconds.
        setTimeout(() => this.checkSessionExpiration(), 30000);

        if (!this.isSignedIn)
            return;

        let currentTime = new Date();
        let currentTimeUtc = Date.UTC(currentTime.getUTCFullYear(), currentTime.getUTCMonth(), currentTime.getUTCDate(), currentTime.getUTCHours(), currentTime.getUTCMinutes(), currentTime.getUTCSeconds());

        const secondsDifference = (sessionStorage.sessionExpiryTime - currentTimeUtc) / 1000;
        if (secondsDifference < -30) {
            var request = new AuthDetailsRequestDto({refreshAuthToken: false});

            await this.xsrfService.loadXsrfToken();

            // Session appears to be expired - check with server if session has been extended
            let authDetails = await this.authApiClient.getAuthDetails(request).toPromise();

            if (!authDetails.isSignedIn) {
                await this.signOut("/sign-in", {
                    state: {
                        sessionTimedOut: true,
                    }
                });
            }

            await this.updateAuthDetails(authDetails);
        }
    }

    /** Fetch the xsrf token, auth details, user profile, and user cart 
     * for a user after they are signed in.
     */
    private async _setupSignedInUser() {
        await this.xsrfService.loadXsrfToken();
        this.refreshAuthDetails();
        this.userService.getUserProfile();
        await this.cartService.retrieveUserCart();
    }
}

export class AuthDetailsResponseModel extends AuthDetailsResponseDto {}
export class ResendEmailConfirmationRequestModel extends ResendEmailConfirmationRequestDto {}

