// Angular Files
import {
    Component,
    Inject,
    NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';

// Angular Material Files
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Teller Online Files
//TODO (PROD-156): Remove ref to Dto 
import { ConfirmCodeRequestTypeEnumDto } from 'apps/public-portal/src/app/core/api/TellerOnlineIdentityApiClients';
import { CodeVerificationModule } from 'apps/public-portal/src/app/shared/components/code-verification/code-verification.component';

// TellerOnlineLibrary files
import { TellerOnlineMessageService, TellerOnlineSharedModule } from 'teller-online-libraries/shared';

@Component({
    selector: 'app-phone-verify',
    templateUrl: './phone-verify.component.html',
    styleUrls: ['./phone-verify.component.scss'],
    host: {
        class: "phone-verify"
    }
})
export class PhoneVerifyComponent{
    public requestType: ConfirmCodeRequestTypeEnumDto = ConfirmCodeRequestTypeEnumDto.Phone;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: PhoneVerifyOptions,
        public dialogRef: MatDialogRef<PhoneVerifyComponent>,
        private messageService: TellerOnlineMessageService
    ) {}

    async onVerifyCode_closeModal(){
        this.messageService.notification("Your phone has been confirmed.", "success", 1500);
        this.dialogRef.close(true);
    }
}

@NgModule({
    imports: [
        TellerOnlineSharedModule,
        CommonModule,
        MatDialogModule,
        CodeVerificationModule
    ],
    declarations: [PhoneVerifyComponent],
    exports: [PhoneVerifyComponent]
})
export class PhoneVerifyModule { }

class PhoneVerifyOptions {
    phone: string;
    otherCountry: boolean;
    codeSent: boolean;
}
