// Angular Files
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'usaPhone'
})
export class USAPhonePipe implements PipeTransform {

    transform(rawNum: string) {
        // const countryCodeStr = rawNum.slice(0,2);
        const areaCodeStr = rawNum.slice(2,5);
        const midSectionStr = rawNum.slice(5,8);
        const lastSectionStr = rawNum.slice(8);

        return `(${areaCodeStr}) ${midSectionStr}-${lastSectionStr}`;
    }

}
