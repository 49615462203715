<form role="form" class="layout-grid" #passwordForm="ngForm" (ngSubmit)="onSubmit_changePassword(passwordForm)" aria-labelledby="aria_page_header"> 
    <mat-form-field appearance="standard">
        <mat-label>Current Password</mat-label>
        <input matInput 
            id="main-content-focus"
            type="password" 
            autocomplete="current-password" 
            [(ngModel)]="passwordChange.oldPassword" 
            #currentPasswordModel="ngModel"
            name="Current Password" 
            required>
        <mat-error *ngIf="currentPasswordModel.invalid">{{validationService.getFieldErrorMessage(currentPasswordModel.name, currentPasswordModel)}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard" class="password">
        <mat-label>New Password</mat-label>
        <input matInput 
            type="password" 
            autocomplete="new-password" 
            [(ngModel)]="passwordChange.newPassword" 
            #newPasswordModel="ngModel"
            name="Password" 
            required 
            [pattern]="passwordService.passwordPattern">
        <mat-hint>
            <app-password-caption [password]="passwordChange.newPassword" [error]="passwordError">
            </app-password-caption>
        </mat-hint>
    </mat-form-field>

    <mat-form-field appearance="standard">
        <mat-label>Confirm Password</mat-label>
        <input matInput 
            type="password" 
            autocomplete="new-password" 
            [(ngModel)]="passwordChange.confirmPassword"
            #confirmPasswordModel="ngModel" 
            name="Confirm Password" 
            required 
            [pattern]="passwordChange.newPassword">
        <mat-error *ngIf="confirmPasswordModel.invalid">{{validationService.getFieldErrorMessage(confirmPasswordModel.name, confirmPasswordModel, validationService.fieldErrors[confirmPasswordModel.name])}}</mat-error>
    </mat-form-field>

    <button type="submit" mat-flat-button color="accent">
        Change Password
    </button>
</form>